export const FUEL_VIEW = 'fuel';
export const SPEED_VIEW = 'speed';
export const PRINT_VIEW = 'print';
export const COVERAGE_VIEW = 'coverage';
export const ALARM_VIEW = 'alarm';
export const CUSTOM_REPORT_VIEW = 'custom-report';
export const USER_VIEW = 'user';

export const getMenuMap = (options) => {
  const list = [];
  list.push({ view: FUEL_VIEW, text: 'Справка Гориво' });
  list.push({ view: SPEED_VIEW, text: 'Справка Скорост' });
  list.push({ view: PRINT_VIEW, text: 'Пътен Лист' });
  if(options && options.hasCoverageReport){
    list.push({ view: COVERAGE_VIEW, text: 'Справка Площ' });
  }
  list.push({ view: ALARM_VIEW, text: 'Аларми' });
  list.push({ view: CUSTOM_REPORT_VIEW, text: 'Други Справки' });
  list.push({ view: USER_VIEW, text: 'Настройки' });
  return list;
};

// eslint-disable-next-line no-extend-native
// export const importStringFormatMethod = () => {
//   String.prototype.format = function() {
//     let a = this;
//     for (let k in arguments) {
//       a = a.replace('{' + k + '}', arguments[k]);
//     }
//     return a;
//   };
// };

export const importArrayFillMethod = () => {
  if (!Array.prototype.fill) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'fill', {
      value: function(value) {
        // Steps 1-2.
        if (this == null) {
          throw new TypeError('this is null or not defined');
        }

        var O = Object(this);

        // Steps 3-5.
        var len = O.length >>> 0;

        // Steps 6-7.
        var start = arguments[1];
        var relativeStart = start >> 0;

        // Step 8.
        var k =
          relativeStart < 0
            ? Math.max(len + relativeStart, 0)
            : Math.min(relativeStart, len);

        // Steps 9-10.
        var end = arguments[2];
        var relativeEnd = end === undefined ? len : end >> 0;

        // Step 11.
        var finalValue =
          relativeEnd < 0
            ? Math.max(len + relativeEnd, 0)
            : Math.min(relativeEnd, len);

        // Step 12.
        while (k < finalValue) {
          O[k] = value;
          k++;
        }

        // Step 13.
        return O;
      },
    });
  }
};

export const debounce = (func, wait) => {
  var timeout;
  return function() {
    //console.log('call debounce:'+arguments[0])
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const preloadImage = url => {
  var img = new Image();
  img.src = url;
};

const numPadding = number => {
  return number > 9 ? number : '0' + number;
};

export const formatTime = datetime => {
  return (
    numPadding(datetime.getHours()) +
    ':' +
    numPadding(datetime.getMinutes()) +
    ':' +
    numPadding(datetime.getSeconds())
  );
};

export const formatDate = datetime => {
  return (
    numPadding(datetime.getDate()) +
    '-' +
    numPadding(datetime.getMonth() + 1) +
    '-' +
    datetime.getFullYear()
  );
};

export const formatDayStartMinutes = minutes => {
  return numPadding(Math.floor(minutes / 60)) + ':' + numPadding(minutes % 60);
};

export const formatTimeInterval = intervalMs => {
  const interval = intervalMs / 1000;
  const seconds = interval % 60;
  const difference = (interval - seconds) / 60;
  const minutes = difference % 60;
  const hours = (difference - minutes) / 60;

  return (
    numPadding(hours) +
    ':' +
    numPadding(minutes) +
    ':' +
    numPadding(Math.round(seconds))
  );
};

export const parseTime = timeString => {
  if (!timeString) {
    return timeString;
  }
  const list = timeString.split(':');
  if (list.length !== 2) {
    throw new Error('wrong argument string format!');
  }
  const hour = parseInt(list[0]);
  const min = parseInt(list[1]);
  return (hour * 3600 + min * 60) * 1000;
};
