import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import DialogPeriodBase from './DialogPeriodBase';
//import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  objectName: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  objectsList: {
    width: '100%',
  },
  objectsListContainer: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

const ObjectListCustomReportDialog = ({
  open,
  reportList,
  targets,
  onClose,
  state,
  onChange,
  type,
}) => {
  const classes = useStyles();
  const { reportStart, reportEnd } = state;
  const [selectedIndex, setSelectedIndex] = React.useState('');

  const selectedObjectIndex = React.useMemo(() => {
    if (state.reportObject === null) {
      return '';
    }
    return targets.findIndex(item => item.id === state.reportObject);
  }, [state.reportObject, targets]);

  const elementList = React.useMemo(() => {
    const componentsList = reportList.map((item, index) => {
      return (
        <MenuItem key={index} value={index}>
          {item}
        </MenuItem>
      );
    });
    return componentsList;
  }, [reportList]);

  const carList = React.useMemo(() => {
    const componentsList = targets.map((item, index) => {
      return (
        <MenuItem key={item.id} value={index}>
          {item.name}
        </MenuItem>
      );
    });
    return componentsList;
  }, [targets]);

  const title = <div>{'Справка'}</div>;

  const handleStartChange = date => {
    onChange({ ...state, reportStart: date });
  };

  const handleEndChange = date => {
    onChange({ ...state, reportEnd: date });
  };

  const handleSelectObject = React.useCallback(
    ev => {
      const index = ev.target.value;
      const object = targets[index];
      onChange({ ...state, reportObject: object.id });
    },
    [onChange, state, targets],
  );

  const handleSelectReport = React.useCallback(ev => {
    const index = ev.target.value;
    setSelectedIndex(index);
  }, []);

  // React.useEffect(() => {
  //   setSelectedIndex('');
  // }, [reportList]);

  const handleClose = data => {
    if (data) {
      const target = targets[selectedObjectIndex];
      onClose(
        Object.assign(
          {
            objectId: target.id,
            name: target.name,
            reportIndex: selectedIndex,
            type: type,
          },
          data,
        ),
      );
    } else {
      onClose();
    }
  };

  return (
    <DialogPeriodBase
      open={open}
      title={title}
      disableApply={selectedIndex === '' || selectedObjectIndex === ''}
      onClose={handleClose}
      startDate={reportStart}
      endDate={reportEnd}
      onStartChange={handleStartChange}
      onEndChange={handleEndChange}
    >
      <InputLabel id='object-select-label'>
        Изберете обект на справка:
      </InputLabel>
      <Select
        className={classes.objectsList}
        labelId='object-select-label'
        id='object-select'
        onChange={handleSelectObject}
        value={selectedObjectIndex}
      >
        {carList}
      </Select>

      <InputLabel id='object-list-label'>
        Изберете параметър за справка:
      </InputLabel>
      <Select
        className={classes.objectsList}
        labelId='report-list-label'
        id='report-list'
        onChange={handleSelectReport}
        value={selectedIndex}
      >
        {elementList}
      </Select>
    </DialogPeriodBase>
  );
};

ObjectListCustomReportDialog.propTypes = {
  open: PropTypes.bool,
  reportList: PropTypes.array.isRequired,
  targets: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  state: PropTypes.object,
  type: PropTypes.string,
};

export default ObjectListCustomReportDialog;
