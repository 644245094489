import React from 'react';
import PropTypes from 'prop-types';

const useUpdate = (onUpdated, deps) => {
  const callback = React.useCallback(onUpdated, deps);
  React.useEffect(() => {
    return callback();
  }, [callback]);
};

useUpdate.propTypes = {
  onUpdated: PropTypes.func,
  deps: PropTypes.array,
};

export default useUpdate;
