import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '40px',
    maxHeight: '80px',
    minWidth: '100%',
    maxWidth: '100%',
  },
});

const AlarmDescription = ({
  defaultValue,
  disabled,
  placeholder,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <textarea
      className={classes.root}
      placeholder={disabled ? '' : placeholder}
      disabled={disabled}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

AlarmDescription.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default AlarmDescription;
