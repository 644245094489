/* eslint react/prop-types: 0 */
import React from 'react';
import ObjectList from './ObjectList';
import ObjectReportDialog from './ObjectReportDialog';
import CustomReportDialog from './CustomReportDialog';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {
  filterData,
  showReportDialog,
  selectObject,
  showView,
  filterCheckAll,
  filterCheckMulti,
  filterNameAsc,
  filterDateAsc,
  filterText,
  userExpand,
  userSelected,
  userObject,
  objectSettings,
} from '../actions/index';
import ObjectFilter from './ObjectList/ObjectFilter';
import { 
  getMenuMap,
  FUEL_VIEW,
  ALARM_VIEW,
  CUSTOM_REPORT_VIEW,
  USER_VIEW
} from './../common.js';
import { fuelReportTypes } from '../reducers/report';
import { ExpandedSectionEnum, ObjectSettingsStateEnum } from '../reducers/user';

import server from './../restApi';

const byTimeAsc = (item1, item2) => {
  return item1.properties.updated - item2.properties.updated;
};

const byTimeDesc = (item1, item2) => {
  return item2.properties.updated - item1.properties.updated;
};

const byNameAsc = (item1, item2) => {
  return item1.properties.name.localeCompare(item2.properties.name);
};

const byNameDesc = (item1, item2) => {
  return item2.properties.name.localeCompare(item1.properties.name);
};

const sortObjects = (list, order) => {
  switch (order) {
    case 'timeasc':
      list.sort(byTimeAsc);
      break;
    case 'timedesc':
      list.sort(byTimeDesc);
      break;
    case 'nameasc':
      list.sort(byNameAsc);
      break;
    case 'namedesc':
      list.sort(byNameDesc);
      break;
    default:
      throw new Error('Unknown order!');
  }
};

const ListWithFilter = props => {
  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = React.useState('timedesc');
  const filterReport = useSelector(state => state.filter);
  const onFilter = value => {
    dispatch(filterText(value));
  };
  const filter = React.useMemo(() => {
    const value = filterReport.text;
    if (value.length === 0) {
      return props.listData.map((item, index) => index);
    }
    const objectNames = props.listData
      ? props.listData.map(item => item.properties.name.toLowerCase())
      : [];
    const text = value.toLowerCase();
    const filtered = objectNames
      .map((item, index) => {
        return item.indexOf(text) >= 0 ? index : -1;
      })
      .filter(item => {
        return item > -1;
      });
    return filtered;
  }, [filterReport, props.listData]);

  const listData = React.useMemo(() => {
    if (filter) {
      const list = filter.map(item => {
        return props.listData[item];
      });
      sortObjects(list, sortOrder);
      return list;
    } else {
      const list = props.listData.slice();
      sortObjects(list, sortOrder);
      return list;
    }
  }, [filter, props.listData, sortOrder]);

  const [checkAll, setCheckAll] = React.useState(() => {
    const found = listData.findIndex(item => {
      return !item.checked;
    });
    //is there an unchecked object?
    return found === -1;
  });

  const onMenu = React.useCallback(
    (id, menuIndex) => {
      props.onMenu(id, menuIndex);
    },
    [props],
  );

  const onSelect = React.useCallback(
    id => {
      props.onSelect(id);
    },
    [props],
  );

  const onCheckbox = React.useCallback(
    (id, value) => {
      props.onCheckbox(id, value);
      if (!value) {
        setCheckAll(false);
      }
    },
    [props],
  );

  const onCheckboxAll = React.useCallback(
    checkAll => {
      //If there is no filter applied
      const list = listData.map(item => {
        return item.properties.id;
      });
      props.onCheckboxAll(list.length ? list : null, checkAll);
      setCheckAll(checkAll);
    },
    [listData, props],
  );

  const onDateOrder = React.useCallback(
    asc => {
      dispatch(filterDateAsc(asc));
      if (asc) {
        setSortOrder('timeasc');
      } else {
        setSortOrder('timedesc');
      }
    },
    [dispatch],
  );

  const onNameOrder = React.useCallback(
    asc => {
      dispatch(filterNameAsc(asc));
      if (asc) {
        setSortOrder('nameasc');
      } else {
        setSortOrder('namedesc');
      }
    },
    [dispatch],
  );

  return (
    <React.Fragment>
      <ObjectFilter
        onFilter={onFilter}
        onCheckbox={onCheckboxAll}
        checkAll={checkAll}
        onDateOrder={onDateOrder}
        onNameOrder={onNameOrder}
        order={filterReport}
        filterText={filterReport.text}
      />
      <ObjectList
        loading={props.loading}
        listData={listData}
        onMenu={onMenu}
        onCheckbox={onCheckbox}
        onSelect={onSelect}
        selectedItem={props.selectedItem}
        menuOptions={props.menuMap}
      />
    </React.Fragment>
  );
};

const ObjectsMenu = ({
  onSelectCustomReport,
  onSelectReport,
  onSelectObjectAlarms,
  customReportTypeList,
}) => {
  const dispatch = useDispatch();
  const listDataReducer = useSelector(state => state.data.listData);
  const userReducer = useSelector(state => state.user);
  const selectedItem = useSelector(state => state.data.selectedObject);
  const loading = useSelector(state => state.data.loading);
  const checkedList = useSelector(state => state.data.filter);

  const { addToast } = useToasts();
  const menuMap = React.useMemo(() => getMenuMap({hasCoverageReport: userReducer.hasCoverageReport}), [userReducer]);

  const onCheckbox = React.useCallback(
    (id, value) => {
      const index = listDataReducer.findIndex(
        item => item.properties.id === id,
      );
      if (index < 0) {
        throw new Error('No object with such id!');
      }
      dispatch(filterData(index, value));
    },
    [dispatch, listDataReducer],
  );

  const onCheckboxAll = React.useCallback(
    (filter, checkAll) => {
      if (filter) {
        dispatch(filterCheckMulti(filter, checkAll));
      } else {
        dispatch(filterCheckAll(checkAll));
      }
    },
    [dispatch],
  );

  const onMenu = React.useCallback(
    (id, menuIndex) => {
      if(menuIndex === -1){
        return;
      }
      const view = menuMap[menuIndex].view;
      if (view === ALARM_VIEW) {
        onSelectObjectAlarms(id);
      } else if (menuIndex >= 0) {
        const object = listDataReducer.find(item => item.properties.id === id);
        if (!object) {
          throw new Error('No object with such id!');
        }

        if (view === FUEL_VIEW) {
          const reportList = fuelReportTypes.map(item => {
            return item.label;
          });
          const dialogData = {
            target: { name: object.properties.name, id: object.properties.id },
            reportList: reportList,
            type: view,
            onClose: onSelectReport,
          };
          dispatch(showReportDialog(dialogData, CustomReportDialog));
        } else if (view === CUSTOM_REPORT_VIEW) {
          const dialogData = {
            target: { name: object.properties.name, id: object.properties.id },
            reportList: customReportTypeList,
            onClose: onSelectCustomReport,
          };
          dispatch(showReportDialog(dialogData, CustomReportDialog));
        } else if (view === USER_VIEW){
          server.getObjectSettingsAsyn(object.properties.id).then(data => {
            dispatch(userExpand(ExpandedSectionEnum.Object));
            dispatch(objectSettings(ObjectSettingsStateEnum.Show));
            dispatch(userSelected(object.properties));
            dispatch(userObject(data.data));
            dispatch(showView(view));
          }).catch(()=> {
            addToast('Заявката не може да бъде изпълнена.', {
              appearance: 'error',
            });
          });
        }else {
          const dialogData = {
            target: { name: object.properties.name, id: object.properties.id },
            type: view,
            onClose: onSelectReport,
          };
          dispatch(showReportDialog(dialogData, ObjectReportDialog));
        }
      }
    },
    [
      customReportTypeList,
      dispatch,
      listDataReducer,
      onSelectCustomReport,
      onSelectObjectAlarms,
      onSelectReport,
      addToast,
      menuMap
    ],
  );

  const onSelect = React.useCallback(
    id => {
      dispatch(showView('objects'));
      dispatch(selectObject(id));
    },
    [dispatch],
  );

  const listData = React.useMemo(() => {
    return listDataReducer.map((item, index) => {
      return Object.assign({}, item, { checked: checkedList[index] });
    });
  }, [checkedList, listDataReducer]);

  return (
    <ListWithFilter
      loading={loading}
      listData={listData}
      onMenu={onMenu}
      onCheckbox={onCheckbox}
      onCheckboxAll={onCheckboxAll}
      onSelect={onSelect}
      selectedItem={selectedItem}
      menuMap={menuMap}
    />
  );
};

export default ObjectsMenu;
