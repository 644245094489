import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  notification: {
    display: 'flex',
    textAlign: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(2),
    color: 'white',
    backgroundColor: '#f50057',
    fontSize: '0.9em',
    '& span': { flexGrow: 1, lineHeight: '20px' },
  },
}));

const NotificationLabeled = ({ label, notificationsCount }) => {
  const classes = useStyles();
  const hasIcon = notificationsCount > 0;
  return (
    <div className={classes.root}>
      <div>
        <span>{label}</span>
      </div>
      {hasIcon && (
        <div className={classes.notification}>
          <span className={classes.span}>{notificationsCount}</span>
        </div>
      )}
    </div>
  );
};

export default NotificationLabeled;

NotificationLabeled.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  notificationsCount: PropTypes.number,
};
