import { parseTime } from '../common.js';

export const addView = name => {
  return {
    type: 'viewStack.add',
    data: name,
  };
};

export const showView = view => {
  return {
    type: 'viewStack.index',
    data: view,
  };
};

//export const showReportDialog = (data, reportType) => {
export const showReportDialog = (data, dialogType) => {
  // const targetObject = Array.isArray(target)
  //   ? target
  //   : { name: target.name, id: target.id };
  // const data = {
  //   target: targetObject,
  //   reportType: reportType,
  // };

  return {
    type: 'dialog.show',
    data: { dialogData: data, dialogType: dialogType },
  };
};

export const hideReportDialog = () => {
  return {
    type: 'dialog.hide',
  };
};

export const reopenReportDialog = report => {
  return {
    type: 'dialog.reopen',
    report: report,
  };
};

export const updateReportDialog = ({ start, end, object }) => {
  return {
    type: 'dialog.update.report',
    data: {
      start: start,
      end: end,
      object: object,
    },
  };
};

export const filterNameAsc = boolean => {
  return {
    type: 'filter.nameAsc',
    data: !!boolean,
  };
};

export const filterDateAsc = boolean => {
  return {
    type: 'filter.dateAsc',
    data: !!boolean,
  };
};

export const filterText = text => {
  return {
    type: 'filter.text',
    data: text,
  };
};

export const updateData = dataList => {
  return {
    type: 'data.update',
    data: dataList,
  };
};

export const updateNameData = (id, name) => {
  return {
    type: 'data.update.name',
    data: { id, name },
  };
};

export const initData = (objectList = []) => {
  return {
    type: 'data.init',
    objectList: objectList,
  };
};

export const deinitData = () => {
  return {
    type: 'data.deinit',
  };
};

export const filterData = (index, checked) => {
  return {
    type: 'data.filter',
    index: index,
    checked: checked,
  };
};

export const filterCheckAll = checked => {
  return {
    type: 'data.filter.all',
    checked: checked,
  };
};

export const filterCheckMulti = (list, checked) => {
  return {
    type: 'data.filter.multi',
    list: list,
    checked: checked,
  };
};

export const switchWordBalloon = () => {
  return {
    type: 'data.balloon.switch',
  };
};

export const locateMap = position => {
  return {
    type: 'map.locate',
    data: position,
  };
};

export const selectObject = objectId => {
  return {
    type: 'object.select',
    data: objectId,
  };
};

export const deselectObject = () => {
  return {
    type: 'object.deselect',
  };
};

export const connectionLost = () => {
  return {
    type: 'connection.lost',
  };
};

export const connectionOk = () => {
  return {
    type: 'connection.ok',
  };
};

export const reportOptionsUpdate = data => {
  const { workStart, workEnd } = data;

  return {
    type: 'report.optionsUpdate',
    data: {
      ...data,
      workStart: parseTime(workStart),
      workEnd: parseTime(workEnd),
    },
  };
};

export const reportUpdate = (report, geojson, queryParams) => {
  return {
    type: 'report.update',
    data: geojson,
    queryParams: queryParams,
    report: report,
  };
};

export const reportNodata = (report, queryParams) => {
  return {
    type: 'report.nodata',
    queryParams: queryParams,
    report: report,
  };
};

export const reportFailed = (report, data) => {
  return {
    type: 'report.failure',
    data: {
      objectName: data.name,
      start: data.start,
      end: data.end,
      type: 'error',
    },
    report: report,
  };
};

export const reportReset = report => {
  return {
    type: 'report.reset',
    report: report,
  };
};

export const userInit = data => {
  return {
    type: 'user.init',
    data: data,
  };
};

export const initAlarm = (object, list) => {
  return {
    type: 'alarm.init',
    data: { object, list },
  };
};

export const deinitAlarm = () => {
  return {
    type: 'alarm.deinit',
  };
};

export const updateAlarm = list => {
  return {
    type: 'alarm.update',
    data: list,
  };
};

export const updateNotification = (list, countNew) => {
  return {
    type: 'notification.update',
    data: { list: list, countNew: countNew },
  };
};

// export const notificationFailed = message => {
//   return {
//     type: 'notification.failed',
//     data: message,
//   };
// };

export const deleteNotification = id => {
  return {
    type: 'notification.delete',
    data: id,
  };
};

export const markNotification = list => {
  return {
    type: 'notification.mark',
    data: list,
  };
};

export const userEditMode = options => {
  const {user, settings} = options;
  return {
    type: 'user.editMode',
    data: { user, settings },
  };
};

export const loginJwt = token => {
  const mainTokenString = token.split('.')[1];
  const mainTokenData = JSON.parse(window.atob(mainTokenString));
  const exp = mainTokenData.exp * 1000;
  const loggedIn =
    exp > new Date().getTime() && (typeof token).toLowerCase() === 'string';

  return {
    type: 'login.jwt',
    data: {
      jwt: token,
      exp: exp,
      loggedIn: loggedIn,
    },
  };
};

export const userExpand = section => {

  return {
    type: 'user.expandSection',
    data: section,
  };
};

export const userObject = settings => {

  return {
    type: 'user.objectSettings',
    data: settings,
  };
};

export const userSelected = object => {

  return {
    type: 'user.selectedObject',
    data: object,
  };
};

export const objectSettings = state => {

  return {
    type: 'user.objectSettingsState',
    data: state,
  };
};

export const selectCoverageDay = ({from, to, day}) => {
  return {
    type: 'report.coverage.selectedDay',
    data: { from, to, day }
  }
}

export const enableCoverageReport = () => {
  return {
    type: 'report.coverage.enable',
  }
}

export const changeCoverageDay = ({date, startIndex, endIndex}) => {
  return {
    type: 'report.coverage.changeDay',
    data: { date, startIndex, endIndex }
  }
}
