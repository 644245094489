import React from 'react';
import PropTypes from 'prop-types';

const useRefreshLogin = (onTryRefresh, onFailedRefresh, expiresMs, deps) => {
  const [tryRefresh, setTryRefresh] = React.useState(false);
  const startRefreshingTimeout = 10 * 60 * 1000;
  const refreshInterval = 5 * 1 * 1000;

  React.useEffect(() => {
    setTryRefresh(false);
  }, [...deps]);

  React.useEffect(() => {
    let timer;
    if (expiresMs) {
      clearTimeout(timer);
      //console.debug('init refreshing: ', new Date());
      const now = new Date().getTime();
      const timeout = expiresMs - now;
      const startTimeout =
        timeout < startRefreshingTimeout ? 0 : timeout - startRefreshingTimeout;
      timer = setTimeout(() => {
        setTryRefresh(true);
        //console.debug('start refreshing: ', new Date());
      }, startTimeout);
      //console.debug('startTimeout', (startTimeout / 1000) / 60, startTimeout / 1000)
    }
    return () => {
      clearTimeout(timer);
    };
  }, deps);

  React.useEffect(() => {
    let interval;
    clearInterval(interval);
    if (tryRefresh) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        if (expiresMs < now) {
          clearInterval(interval);
          onFailedRefresh();
        } else {
          onTryRefresh();
        }
      }, refreshInterval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [...deps, tryRefresh]);
};

useRefreshLogin.propTypes = {
  onTryRefresh: PropTypes.func,
  onFailedRefresh: PropTypes.func,
  expiresMs: PropTypes.number,
  deps: PropTypes.array,
};

export default useRefreshLogin;
