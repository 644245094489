import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import IconKeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import IconKeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const useStylesBase = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'grey',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    zIndex: 401,
  },
}));

const SideDrawerButton = ({ state = 'down', onClick, ...other }) => {
  const classesBase = useStylesBase();
  let className = classesBase.root;
  if (other.className) {
    className = clsx(other.className, classesBase.root);
  }
  other.className = className;

  return (
    <div {...other} onClick={onClick}>
      <Grid container justify='center'>
        {state === 'up' && <IconKeyboardArrowUp />}
        {state === 'down' && <IconKeyboardArrowDown />}
      </Grid>
    </div>
  );
};

SideDrawerButton.propTypes = {
  onClick: PropTypes.func,
  state: PropTypes.string,
};

export default SideDrawerButton;
