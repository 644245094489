import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  skeletonRoot: {
    height: '0.875rem',
  },
  skeletonText: {
    marginTop: '0.35em',
    marginBottom: '0.35em',
  },
}));

const SkeletonListItem = () => {
  const classesList = useStyles();
  const classes = {
    root: classesList.skeletonRoot,
    text: classesList.skeletonText,
  };
  return (
    <ListItem>
      <Grid container wrap='nowrap'>
        <Box width={50} display='flex' alignItems='center'>
          <Skeleton variant='rect' height={24} width={24} />
        </Box>
        <Grid container direction='column'>
          <Grid item>
            <Skeleton variant='text' width='60%' classes={classes} />
          </Grid>
          <Grid item>
            <Skeleton variant='text' width='40%' classes={classes} />
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default SkeletonListItem;
