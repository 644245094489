import { getLngLat } from '../components/Map';
import configuration from '../configuration';

const objectFocusInZoom = 19;

export const BalloonType = {
  INFO: 0,
  COORDS: 1,
};

const initState = {
  listData: [],
  autoupdate: true,
  isInit: false,
  loading: true,
  objects: [],
  filter: [],
  objectMap: {},
  viewport: undefined,
  bounds: null,
  user: {
    position: null,
  },
  selectedObject: null,
  wordBalloonType: 0,
  wordBalloonTypeCount: Object.keys(BalloonType).length,
};

const isUpdated = (prevObject, object) => {
  return prevObject.properties.updated < object.properties.updated;
};

const findObject = (objectList, id) => {
  return objectList.find(item => item.properties.id === id);
};

const areCoordinatesSame = (left, right) => {
  return left[0] == right[0] && left[1] == right[1];
};

const updateGeometry = (geometry, updateGeometry, pointsCount = 10) => {
  if (
    areCoordinatesSame(
      geometry.coordinates.slice(-1)[0],
      updateGeometry.coordinates,
    )
  ) {
    return geometry;
  } else {
    const coordinates = geometry.coordinates.concat([
      updateGeometry.coordinates,
    ]);

    return {
      ...geometry,
      coordinates: coordinates.slice(
        Math.max(coordinates.length - pointsCount, 0),
        coordinates.length,
      ),
    };
  }
};

export const initData = objectList => {
  if (objectList) {
    return [...objectList].map(item => {
      return {
        ...item,
        geometry: {
          type: 'LineString',
          coordinates: [item.geometry.coordinates],
        },
      };
    });
  }
  return [];
};

export const updateData = (objectList, updateList, pointsCount = 10) => {
  if (objectList) {
    if (updateList) {
      return objectList.map(item => {
        const object = findObject(updateList, item.properties.id);
        if (object && isUpdated(item, object)) {
          return {
            ...item,
            properties: {
              ...object.properties,
            },
            geometry: updateGeometry(
              item.geometry,
              object.geometry,
              pointsCount,
            ),
          };
        }
        return item;
      });
    }
    return objectList;
  }
};

const prepareListData = listData => {
  return listData.filter(data => {
    if (!data.geometry) {
      return false;
    }
    let coords = data.geometry.coordinates;
    if (!coords || coords.length < 2) {
      return false;
    }
    return true;
  });
};

const updateListData = (listData, objectMap, updateList) => {
  const list = prepareListData(updateList);
  updateData(listData, list).forEach(object => {
    const index = objectMap[object.properties.id];
    listData[index] = object;
  });
  return listData.slice();
};

const getLatestPosition = (listData, object) => {
  let coords = object.geometry.coordinates;
  return getLngLat(coords[coords.length - 1]);
};

const getObjectViewport = (listData, newObjectId, oldObjectId) => {
  if (newObjectId != null) {
    const object = listData.find(item => item.properties.id === newObjectId);
    const position = getLatestPosition(listData, object);
    if (newObjectId === oldObjectId) {
      return {
        center: { lat: position.lat, lng: position.lng },
        zoom: objectFocusInZoom,
      };
    } else {
      return {
        center: { lat: position.lat, lng: position.lng },
      };
    }
  } else {
    throw new Error('Index must be specified!');
  }
};

const initDataMap = objectList => {
  return prepareListData(objectList).reduce((objectMap, item, index) => {
    objectMap[item.properties.id] = index;
    return objectMap;
  }, {});
};

const calcBounds = listData => {
  const lastPositions = listData.map(item => {
    switch (item.geometry.type) {
      case 'Point':
        return item.geometry.coordinates;
      case 'LineString':
        return item.geometry.coordinates[item.geometry.coordinates.length - 1];
      default:
        return undefined;
    }
  });
  if(lastPositions.length > 0){
    const lngList = lastPositions.map(item => item[0]);
    const latList = lastPositions.map(item => item[1]);
    const north = Math.max.apply(Math, latList);
    const south = Math.min.apply(Math, latList);
    const east = Math.max.apply(Math, lngList);
    const west = Math.min.apply(Math, lngList);
    return [
      [south, west],
      [north, east],
    ];
  }else{
    const south = configuration().map.defaultFrame.southWest.lat;
    const west = configuration().map.defaultFrame.southWest.lng;
    const north = configuration().map.defaultFrame.northEast.lat;
    const east = configuration().map.defaultFrame.northEast.lng;
    return [
      [south, west],
      [north, east],
    ];
  }
};

export const dataReducer = (state = initState, action) => {
  let type = action.type;
  switch (type) {
    case 'data.update':
      state.listData = updateListData(
        state.listData,
        state.objectMap,
        action.data,
      );
      //state.objects = updateObjects(state.objects, action.tracked);///
      state.viewport =
        state.selectedObject !== null
          ? (state.viewport = getObjectViewport(
              state.listData,
              state.selectedObject,
            ))
          : state.viewport;
      break;
    case 'data.init':
      state.objectMap = initDataMap(action.objectList);
      state.listData = initData(action.objectList);
      // state.listData = updateListData(
      //   state.listData,
      //   state.objectMap,
      //   action.objectList,
      // );
      state.bounds = calcBounds(state.listData);
      //state.objects = action.tracked;
      //state.filter = action.tracked.map(item => item.checked);
      state.filter = state.listData.map(() => true);
      state.isInit = true;
      state.loading = false;
      break;
    case 'data.deinit':
      state.listData = [];
      state.objectMap = {};
      //state.objects = [];
      state.filter = [];
      state.isInit = false;
      state.autoupdate = true;
      state.loading = true;
      break;
    case 'data.update.name':
      state.listData = state.listData.map(
        item => item.properties.id == action.data.id ? 
        {...item, properties: {...item.properties, name: action.data.name}} : 
        item
      );
      break;
    case 'data.filter':
      state.filter[action.index] = action.checked;
      state.filter = state.filter.slice();
      break;
    case 'data.filter.all':
      state.filter = state.filter.map(() => action.checked);
      break;
    case 'data.filter.multi':
      action.list.forEach(id => {
        const index = state.listData.findIndex(
          item => item.properties.id === id,
        );
        if (0 <= index) {
          state.filter[index] = action.checked;
        }
      });
      state.filter = state.filter.slice();
      break;
    case 'viewStack.index':
      state.autoupdate = action.data === 'objects';
      state.viewport = action.data === 'objects' ? state.viewport : undefined;
      break;
    case 'map.locate':
      state.user = {
        position: action.data,
      };
      state.viewport = {
        center: action.data,
        zoom: 13,
      };
      break;
    case 'object.select':
      state.viewport = getObjectViewport(
        state.listData,
        action.data,
        state.selectedObject,
      );
      state.selectedObject = action.data;
      break;
    case 'object.deselect':
      if (state.selectedObject !== null) {
        state.selectedObject = null;
      }
      break;
    case 'dialog.reopen':
      state.autoupdate = true;
      break;
    case 'data.balloon.switch':
      state.wordBalloonType =
        (state.wordBalloonType + 1) % state.wordBalloonTypeCount;
      break;
    default:
      return state;
  }
  return state;
};
