const tabIdsMap = {
  user: 7,
  objects: 0,
  speed: 1,
  fuel: 2,
  print: 3,
  alarm: 6,
  coverage: 4,
  'custom-report': 5,
};

export const getTabIdsMap = () => Object.assign(tabIdsMap);

const objectsView = 'objects';

const generateTabId = viewName => {
  return 'tab-' + viewName;
};

const generateTabPanelId = viewName => {
  return 'tabpanel-' + viewName;
};

const tabItems = [];

tabItems[tabIdsMap['user']] = {
  label: 'Потребител',
  id: 'user',
  tabId: generateTabId('user'),
  panelId: generateTabPanelId('user'),
  disabled: false,
};

tabItems[tabIdsMap['objects']] = {
  label: 'Обекти',
  id: 'objects',
  tabId: generateTabId('objects'),
  panelId: generateTabPanelId('objects'),
  disabled: false,
};

tabItems[tabIdsMap['speed']] = {
  label: 'Скорост',
  id: 'speed',
  tabId: generateTabId('speed'),
  panelId: generateTabPanelId('speed'),
  disabled: true,
};

tabItems[tabIdsMap['fuel']] = {
  label: 'Гориво',
  id: 'fuel',
  tabId: generateTabId('fuel'),
  panelId: generateTabPanelId('fuel'),
  disabled: true,
};

tabItems[tabIdsMap['print']] = {
  label: 'Пътен Лист',
  id: 'print',
  tabId: generateTabId('print'),
  panelId: generateTabPanelId('print'),
  disabled: true,
};

tabItems[tabIdsMap['alarm']] = {
  label: 'Аларми',
  id: 'alarm',
  tabId: generateTabId('alarm'),
  panelId: generateTabPanelId('alarm'),
  disabled: true,
};

tabItems[tabIdsMap['custom-report']] = {
  label: 'Други Справки',
  id: 'custom-report',
  tabId: generateTabId('custom-report'),
  panelId: generateTabPanelId('custom-report'),
  disabled: true,
};

// tabItems[tabIdsMap['coverage']] = {
//   label: 'Площ',
//   id: 'coverage-report',
//   tabId: generateTabId('coverage-report'),
//   panelId: generateTabPanelId('coverage-report'),
//   disabled: true,
// };

const coverageReportTab = {
  label: 'Площ',
  id: 'coverage',
  tabId: generateTabId('coverage'),
  panelId: generateTabPanelId('coverage'),
  disabled: true,
};

const initState = {
  addView: { objects: true },
  dialog: { show: false },
  tabItems: tabItems,
  selectedView: objectsView,
  selectedTab: tabIdsMap.objects,
};

const deinitTabItems = state => {
  state.tabItems.forEach(item => (item.disabled = true));
  state.tabItems[tabIdsMap['user']].disabled = false;
  state.tabItems[tabIdsMap['objects']].disabled = false;
};

export const viewStackReducer = (state = initState, action) => {
  let type = action.type;
  switch (type) {
    case 'viewStack.index':
      state.selectedView = action.data;
      state.selectedTab = tabIdsMap[action.data];
      break;
    case 'data.deinit':
      state.addView = { objects: true };
      // state.dialog = { show: false };
      state.selectedView = objectsView;
      state.selectedTab = tabIdsMap.objects;
      deinitTabItems(state);
      state.tabItems = [...tabItems];
      break;
    case 'viewStack.add':
      state.tabItems[tabIdsMap[action.data]].disabled = false;
      state.addView[action.data] = true;
      state.tabItems = state.tabItems.slice();
      break;
    case 'dialog.show':
      state.dialog = Object.assign(action.data, { show: true });
      break;
    case 'dialog.hide':
      state.dialog = Object.assign({}, state.dialog, { show: false });
      break;
    case 'dialog.reopen':
      state.dialog.show = true;
      // state.showView = 'objects';
      state.selectedView = objectsView;
      state.selectedTab = tabIdsMap.objects;
      state.addView = { objects: true };
      break;
    case 'report.coverage.enable':
      state.tabItems[tabIdsMap['coverage']] = coverageReportTab;
      state.tabItems = [...state.tabItems];
      break;
    default:
      return state;
  }
  return state;
};
