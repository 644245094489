const reducerName = 'user.reducer';

export const ExpandedSectionEnum = {
  Notifications: 'panel-notifications',
  General: 'panel-settings',
  Object: 'panel-objects',
}

export const ObjectSettingsStateEnum = {
  Selection: 1,
  Show: 2,
}

const defaultState = {
  username: '',
  email: '',
  editSettingsOn: false,
  editObjectSettingsOn: false,
  hasWorktime: false,
  hasNotesColumn: false,
  workStart: '00:00',
  workEnd: '00:00',
  expandedPanel: ExpandedSectionEnum.General,
  objectSettings: undefined,
  selectedObject: undefined,
  objectSettingsState: ObjectSettingsStateEnum.Selection,
};

const initState = JSON.parse(localStorage.getItem(reducerName)) || {
  ...defaultState,
};

export const userReducer = (state = initState, action) => {
  let type = action.type;
  let save = true;
  switch (type) {
    case 'user.init':
      state = Object.assign({}, state, action.data);
      break;
    case 'user.editMode':
      state.editSettingsOn = action.data;
      state = {...state, editSettingsOn: action.data.user, editObjectSettingsOn: action.data.settings }
      //console.log(state);
      break;
    case 'data.deinit':
      state = { ...defaultState };
      break;
    case 'user.expandSection':
      state = {...state, expandedPanel: action.data, objectSettingsState: ObjectSettingsStateEnum.Selection }
      break;
    case 'user.objectSettings':
      state = {...state, objectSettings: {...action.data} }
      break;
    case 'user.selectedObject':
      state = {...state, selectedObject: action.data }
      break;
    case 'user.objectSettingsState':
        state = {...state, objectSettingsState: action.data }
        break;
    default:
      save = false;
      return state;
  }

  if (save) {
    localStorage.setItem(reducerName, JSON.stringify(state));
  }
  return state;
};
