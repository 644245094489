import { viewStackReducer } from './viewStack';
import { dataReducer } from './data';
import { reportReducer } from './report';
import { coverageReportReducer } from './coverageReport';
import { connectionReducer } from './connection';
import { userReducer } from './user';
import { alarmReducer } from './alarm';
import { notificationReducer } from './notification';
import { loginReducer } from './login';
import { dialogReducer } from './dialog';
import { filterReducer } from './filter';
import { combineReducers } from 'redux';

const allReducer = combineReducers({
  viewStack: viewStackReducer,
  data: dataReducer,
  report: reportReducer,
  connection: connectionReducer,
  user: userReducer,
  alarm: alarmReducer,
  notification: notificationReducer,
  login: loginReducer,
  dialog: dialogReducer,
  filter: filterReducer,
  coverageReport: coverageReportReducer,
});

export default allReducer;
