import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import DialogPeriodBase from './DialogPeriodBase';
//import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  objectName: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  objectsList: {
    width: '100%',
  },
  objectsListContainer: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

const CustomReportDialog = ({
  open,
  target,
  reportList,
  state,
  type,
  onClose,
  onChange,
}) => {
  const classes = useStyles();
  const { reportStart, reportEnd } = state;
  const [selectedIndex, setSelectedIndex] = React.useState('');

  // const [objectName, setObjectName] = React.useState(
  //   Array.isArray(target) ? null : target.name,
  // );
  const elementList = React.useMemo(() => {
    const componentsList = reportList.map((item, index) => {
      return (
        <MenuItem key={index} value={index}>
          {item}
        </MenuItem>
      );
    });
    return componentsList;
  }, [reportList]);

  const objectName = <span className={classes.objectName}>{target.name}</span>;
  const title = (
    <div>
      {'Справка'} {objectName}
    </div>
  );

  const handleStartChange = date => {
    onChange({ ...state, reportStart: date });
  };

  const handleEndChange = date => {
    onChange({ ...state, reportEnd: date });
  };

  const handleSelectObject = React.useCallback(ev => {
    const index = ev.target.value;
    setSelectedIndex(index);
  }, []);

  React.useEffect(() => {
    setSelectedIndex('');
  }, [reportList]);

  const handleClose = data => {
    if (data) {
      onClose(
        Object.assign(
          {
            objectId: target.id,
            name: target.name,
            reportIndex: selectedIndex,
            type: type,
          },
          data,
        ),
      );
    } else {
      onClose();
    }
  };

  return (
    <DialogPeriodBase
      open={open}
      title={title}
      disableApply={selectedIndex === ''}
      onClose={handleClose}
      startDate={reportStart}
      endDate={reportEnd}
      onStartChange={handleStartChange}
      onEndChange={handleEndChange}
    >
      <InputLabel id='object-list-label'>
        Изберете параметър за справка:
      </InputLabel>
      <Select
        className={classes.objectsList}
        labelId='object-list-label'
        id='object-list'
        onChange={handleSelectObject}
        value={selectedIndex}
      >
        {elementList}
      </Select>
    </DialogPeriodBase>
  );
};

CustomReportDialog.propTypes = {
  open: PropTypes.bool,
  target: PropTypes.object.isRequired,
  reportList: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  state: PropTypes.object,
  type: PropTypes.string,
};

export default CustomReportDialog;
