const initState = {
  list: [],
  countNew: 0,
  onFocus: false,
};

const removeNotification = (state, id) => {
  const index = state.list.findIndex(item => item.id === id);
  if (index === -1) {
    throw new Error('Notification id ' + id + ' not found.');
  }
  if (state.list[index].status === 'unseen') {
    state.countNew = state.countNew - 1;
  }
  state.list.splice(index, 1);
};

const markAllSeen = state => {
  state.list.forEach(item => (item.status = 'seen'));
  return state.list.slice();
};

export const notificationReducer = (state = initState, action) => {
  let type = action.type;
  switch (type) {
    case 'notification.update':
      state.list = action.data.list;
      state.countNew = action.data.countNew;
      break;
    // case 'notification.failed':
    //   break;
    case 'notification.delete':
      removeNotification(state, action.data);
      state.list = state.list.slice();
      break;
    case 'notification.mark':
      state.list = markAllSeen(state);
      state.countNew = 0;
      break;
    case 'viewStack.index':
      state.onFocus = action.data === 'user';
      break;
    default:
      return state;
  }
  return state;
};
