import React from 'react';
import PropTypes from 'prop-types';

const useMount = onMounted => {
  const callback = React.useCallback(onMounted, []);
  React.useEffect(() => {
    return callback();
  }, [callback]);
};

useMount.propTypes = {
  onMounted: PropTypes.func,
};

export default useMount;
