import React from 'react';
import PropTypes from 'prop-types';
// import { EngineOnIcon, EngineOffIcon } from './CustomIcons.js';
// import MomentInfoBasic from './MomentInfoBasic';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import configuration from '../configuration';

const dateLocale = configuration().locale.date;

const useStyles = makeStyles(theme => ({
  name: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  nameTypograpy: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  period: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  property: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  propsContainer: {
    paddingBottom: theme.spacing(1),
    minHeight: 0,
    overflowY: 'auto',
  },
}));

const MomentInfoPrint = ({
  name,
  start,
  end,
  startDist,
  endDist,
  totalDist,
  totalRest,
  totalWork,
  overtime,
  orientation = 'horizontal',
  onDriverInput,
}) => {
  const classes = useStyles();
  const gridXs = orientation === 'vertical' ? 12 : 6;
  // const noDataSymbol = '-.-';
  const kmLabel = 'км';

  const primaryData = [
    {
      label: 'Почивка',
      value: totalRest,
    },
    {
      label: 'Работа',
      value: totalWork,
    },
  ];

  const secondaryData = [
    {
      label: 'Километраж начало:',
      value: startDist + ' ' + kmLabel,
    },
    {
      label: 'Километраж край:',
      value: endDist + ' ' + kmLabel,
    },
    {
      label: 'Изминат път:',
      value: totalDist + ' ' + kmLabel,
    },
    {
      label: 'Извънреден труд:',
      value: overtime,
    },
  ];

  const primaryComponents = primaryData.map((item, index) => {
    return (
      <Grid key={index} item xs={gridXs} className={classes.property}>
        <Typography color='textSecondary' variant='body2'>
          {item.label}:
        </Typography>
        <Typography variant='body2'>{item.value}</Typography>
      </Grid>
    );
  });

  const secondaryComponents = secondaryData.map((item, index) => {
    return (
      <Grid key={index} item sm={gridXs} className={classes.property}>
        <Typography color='textSecondary' variant='body2'>
          {item.label}
        </Typography>
        <Typography variant='body2'>{item.value}</Typography>
      </Grid>
    );
  });

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.name}>
          <Typography variant='body1' className={classes.nameTypograpy}>
            {name}
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} className={classes.period}>
            <Typography color='textSecondary' variant='body2'>
              {new Date(start).toLocaleString(dateLocale)} -{' '}
              {new Date(end).toLocaleString(dateLocale)}
            </Typography>
            <FormControl>
              <InputLabel htmlFor={'car-driver-input'}>{'Водач'}</InputLabel>
              <Input id={'car-driver-input'} onChange={onDriverInput} />
            </FormControl>
          </Grid>
        </Hidden>
      </Grid>
      <Divider variant='middle' />
      <Grid container className={classes.propsContainer}>
        {primaryComponents}
        <Hidden xsDown>{secondaryComponents}</Hidden>
      </Grid>
    </React.Fragment>
  );
};

MomentInfoPrint.propTypes = {
  name: PropTypes.string.isRequired,
  start: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  end: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  orientation: PropTypes.string,
  startDist: PropTypes.number,
  endDist: PropTypes.number,
  totalDist: PropTypes.number,
  overtime: PropTypes.string,
  totalRest: PropTypes.string,
  totalWork: PropTypes.string,
  onDriverInput: PropTypes.func,
};

export default MomentInfoPrint;
