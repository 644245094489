import React from 'react';
import PropTypes from 'prop-types';

const useInterval = (onUpdate, updateInterval, hold = false) => {
  const [running, setRunning] = React.useState(!hold);

  React.useEffect(() => {
    if (!running) {
      return;
    }

    const interval = setInterval(onUpdate, updateInterval);
    return () => {
      clearInterval(interval);
    };
  }, [onUpdate, running, updateInterval]);

  return {
    stop: () => !running || setRunning(false),
    start: () => running || setRunning(true),
    toggle: () => setRunning(!running),
    isRunning: () => running,
  };
};

useInterval.propTypes = {
  onUpdate: PropTypes.func,
  updateInterval: PropTypes.number,
  hode: PropTypes.boolean,
};

export default useInterval;
