import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
//import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  lockIcon: {
    float: 'right',
  },
  title: {
    alignSelf: 'center',
    paddingLeft: theme.spacing(2),
  },
  paragraph: {
    // fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 18,
  },
}));

const AlarmHeader = ({ text, onLockChange, disableLock }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const onEditModeOn = React.useCallback(() => {
    onLockChange(true);
    setEditMode(true);
  }, [onLockChange]);

  const onEditModeOff = React.useCallback(() => {
    onLockChange(false);
    setEditMode(false);
  }, [onLockChange]);

  return (
    <Grid container wrap='nowrap' alignContent='center' justify='center'>
      <Grid item xs={6} className={classes.title}>
        <Typography variant='body1' className={classes.paragraph}>
          {text}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {editMode ? (
          <IconButton onClick={onEditModeOff} className={classes.lockIcon}>
            <LockOpenIcon />
          </IconButton>
        ) : (
          <IconButton
            className={classes.lockIcon}
            disabled={disableLock}
            onClick={onEditModeOn}
          >
            <LockIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

AlarmHeader.propTypes = {
  onLockChange: PropTypes.func,
  disableLock: PropTypes.bool,
  text: PropTypes.string,
  // onAddItem: PropTypes.func,
};

export default AlarmHeader;
