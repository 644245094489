import React from 'react';
//import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux';
//import PropTypes from 'prop-types';

import restApi from '../restApi';
import MapPrintReport from './MapPrintReport';
//import PropTypes from 'prop-types';
import NoDataDialog from './NoDataDialog';
import { showView, reportReset } from '../actions/index';
import { useToasts } from 'react-toast-notifications';
import configuration from '../configuration';

const PrintTraceView = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const onConfirm = () => {
    dispatch(showView('objects'));
    dispatch(reportReset('print'));
  };
  const [addressList, setAddressList] = React.useState([]);
  const [disableButton, setDisableButton] = React.useState(true);

  const printReport = useSelector(state => state.report.print);
  const printReportOptions = useSelector(state => state.report.options);
  //  const userData = useSelector(state => state.user);
  const errorDialog = printReport && printReport.status === 'nodata';
  const loading =
    printReport.status === 'loading' || printReport.status === 'nodata';

  React.useEffect(() => {
    if (configuration().geocoding.disable) {
      return;
    }
    if (!loading && printReport.trackingId) {
      restApi.getReportAddressList(printReport.trackingId).then(resp => {
        if (resp.ok) {
          setAddressList(resp.data.addressList);
          setDisableButton(false);
        } else {
          addToast('Адресите не могат да се изчислят.', {
            appearance: 'error',
          });
          setDisableButton(false);
        }
      });
    }
  }, [addToast, loading, printReport.trackingId]);

  React.useEffect(() => {
    if (!addressList.length && printReport.map) {
      setAddressList(printReport.map.geometry.coordinates.map(() => '...'));
    }
  }, [addressList.length, printReport.map]);

  React.useEffect(() => {
    if (loading) {
      setAddressList([]);
      setDisableButton(true);
    }
  }, [loading]);

  return (
    <React.Fragment>
      {errorDialog && (
        <NoDataDialog
          open={true}
          onClose={onConfirm}
          objectName={printReport.objectName}
          startDate={printReport.start}
          endDate={printReport.end}
        />
      )}
      <MapPrintReport
        object={printReport}
        loading={loading}
        addressList={addressList}
        disableButton={disableButton}
        options={{ print: printReportOptions }}
      />
    </React.Fragment>
  );
};

export default PrintTraceView;
