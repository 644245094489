import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//import { useUpdate } from '../hooks';
import DialogBase from './DialogBase';

const useStyles = makeStyles(theme => ({
  objectName: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  objectsList: {
    width: '100%',
  },
  objectsListContainer: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

const ObjectSelectDialog = ({
  open,
  objectList,
  title,
  state,
  onClose,
  onChange,
}) => {
  const classes = useStyles();
  //const [selectedIndex, setSelectedIndex] = React.useState('');
  const selectedIndex = React.useMemo(() => {
    if (state.reportObject === null) {
      return '';
    }
    return objectList.findIndex(item => item.id === state.reportObject);
  }, [state, objectList]);

  const carList = React.useMemo(() => {
    const componentsList = objectList.map((item, index) => {
      return (
        <MenuItem key={item.id} value={index}>
          {item.name}
        </MenuItem>
      );
    });
    return componentsList;
  }, [objectList]);

  const handleApply = React.useCallback(() => {
    const object = objectList[selectedIndex];
    onClose({
      objectId: object.id,
      name: object.name,
    });
  }, [objectList, onClose, selectedIndex]);

  const handleCancel = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSelectObject = React.useCallback(
    ev => {
      const index = ev.target.value;
      const object = objectList[index];
      onChange({ ...state, reportObject: object.id });
    },
    [objectList, onChange, state],
  );
  //
  // useUpdate(() => {
  //   onChange({ ...state, reportObject: null });
  //   //setSelectedIndex('');
  // }, [objectList]);

  return (
    <DialogBase
      open={open}
      title={title}
      disableApply={selectedIndex === null}
      handleClose={handleClose}
      handleCancel={handleCancel}
      handleApply={handleApply}
    >
      <InputLabel id='object-select-label'>
        Изберете обект на справка:
      </InputLabel>
      <Select
        className={classes.objectsList}
        labelId='object-select-label'
        id='object-select'
        onChange={handleSelectObject}
        value={selectedIndex}
      >
        {carList}
      </Select>
    </DialogBase>
  );
};

ObjectSelectDialog.propTypes = {
  open: PropTypes.bool,
  objectList: PropTypes.array.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  state: PropTypes.object,
};

export default ObjectSelectDialog;
