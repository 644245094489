import React from 'react';
import server from '../restApi';
//import configuration from '../configuration';

export default function useCustomReportList(onSuccess, onError) {
  React.useEffect(() => {
    server
      .getCustomReportListAsync()
      .then(onSuccess)
      .catch(onError);
  }, [onError, onSuccess]);
  return null;
}
