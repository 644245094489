import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import ObjectsView from './ObjectsView';
import SpeedView from './SpeedView';
import FuelView from './FuelView';
import CoverageReportView from './CoverageReportView';
import PrintView from './PrintView';
import UserView from './UserView';
import AlarmView from './AlarmView';
import CustomReportView from './CustomReportView';
import { getTabIdsMap } from '../reducers/viewStack';

const tabIdsMap = getTabIdsMap();

const ViewStack = ({ tabPanels, showView, onLogout }) => {
  return (
    <Box width={1} height={1}>
      <Box
        role='tabpanel'
        id={tabPanels[tabIdsMap['user']].panelId}
        aria-labelledby={tabPanels[tabIdsMap['user']].tabId}
        display={showView === 'user' ? 'block' : 'none'}
        height={1}
        width={1}
      >
        <UserView onLogout={onLogout} />
      </Box>
      {showView === 'objects' ? (
        <Box
          role='tabpalel'
          id={tabPanels[tabIdsMap['objects']].panelId}
          aria-labelledby={tabPanels[tabIdsMap['objects']].tabId}
          height={1}
          width={1}
        >
          <ObjectsView />
        </Box>
      ) : null}
      {showView === 'speed' ? (
        <Box
          role='tabpalel'
          id={tabPanels[tabIdsMap['speed']].panelId}
          aria-labelledby={tabPanels[tabIdsMap['speed']].tabId}
          height={1}
          width={1}
        >
          <SpeedView />
        </Box>
      ) : null}
      {showView === 'fuel' ? (
        <Box
          role='tabpalel'
          id={tabPanels[tabIdsMap['fuel']].panelId}
          aria-labelledby={tabPanels[tabIdsMap['fuel']].tabId}
          height={1}
          width={1}
        >
          <FuelView />
        </Box>
      ) : null}
      {showView === 'print' ? (
        <Box
          role='tabpalel'
          id={tabPanels[tabIdsMap['print']].panelId}
          aria-labelledby={tabPanels[tabIdsMap['print']].tabId}
          height={1}
          width={1}
          //This is a patch for a mystic semicolon (;) which gets rendered after
          //the children of this 'Box' component. We cannot stop it from rendering
          //but at least it doesn't gets displayed in the visible area of
          //the component anymore.
          //style={{ overflow: 'hidden' }}
        >
          <PrintView />
        </Box>
      ) : null}
      {showView === 'coverage' ? (
        <Box
          role='tabpalel'
          id={tabPanels[tabIdsMap['coverage']].panelId}
          aria-labelledby={tabPanels[tabIdsMap['coverage']].tabId}
          height={1}
          width={1}
          //This is a patch for a mystic semicolon (;) which gets rendered after
          //the children of this 'Box' component. We cannot stop it from rendering
          //but at least it doesn't gets displayed in the visible area of
          //the component anymore.
          //style={{ overflow: 'hidden' }}
        >
          <CoverageReportView />
        </Box>
      ) : null}
      {showView === 'custom-report' ? (
        <Box
          role='tabpalel'
          id={tabPanels[tabIdsMap['custom-report']].panelId}
          aria-labelledby={tabPanels[tabIdsMap['custom-report']].tabId}
          height={1}
          width={1}
        >
          <CustomReportView />
        </Box>
      ) : null}
      {showView === 'alarm' ? (
        <Box
          role='tabpalel'
          id={tabPanels[tabIdsMap['alarm']].panelId}
          aria-labelledby={tabPanels[tabIdsMap['alarm']].tabId}
          height={1}
          width={1}
        >
          <AlarmView />
        </Box>
      ) : null}
    </Box>
  );
};

ViewStack.propTypes = {
  tabPanels: PropTypes.array,
  showView: PropTypes.string,
  onClickMenu: PropTypes.func,
  onLogout: PropTypes.func,
};

export default ViewStack;
