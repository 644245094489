import React from 'react';
import PropTypes from 'prop-types';
import { useLeaflet } from 'react-leaflet';
import { FeatureGroup, Marker, CircleMarker } from 'react-leaflet';
import { getLngLat } from './index.js';
import { getLeafletIcon } from './index.js';

const endpointStartIcon = getLeafletIcon('start');
const endpointFinishIcon = getLeafletIcon('finish');
const frozenObjectIcon = getLeafletIcon('stop');

const ObjectStops = ({ data, select }) => {
  const { map } = useLeaflet();
  const markersPadding = 25;
  const geoJson = data;
  const coords = geoJson.geometry.coordinates;
  const start = getLngLat(coords[0]);
  const end = getLngLat(coords[coords.length - 1]);
  const markerGroupRef = React.useRef();

  const marker = React.useMemo(() => {
    if (select || select === 0) {
      const selectedPoint = getLngLat(coords[select]);
      return <CircleMarker center={selectedPoint} zIndexOffset={1000} />;
    } else {
      return null;
    }
  }, [coords, select]);

  const objectStops = React.useMemo(() => {
    return coords.map((item, index) => {
      return (
        <Marker
          key={index}
          icon={frozenObjectIcon}
          position={getLngLat(item)}
        />
      );
    });
  }, [coords]);

  React.useEffect(() => {
    if (markerGroupRef.current) {
      map.fitBounds(markerGroupRef.current.leafletElement.getBounds(), {
        padding: [markersPadding, markersPadding],
      });
    }
  }, [map]);

  React.useEffect(() => {
    if (select !== null) {
      const point = getLngLat(coords[select]);
      map.panTo(point);
    }
  }, [map, select, coords]);

  return (
    <React.Fragment>
      {marker}
      <FeatureGroup ref={markerGroupRef}>
        <Marker icon={endpointFinishIcon} position={end} zIndexOffset={1000} />
        <Marker icon={endpointStartIcon} position={start} zIndexOffset={1000} />
        {objectStops}
      </FeatureGroup>
    </React.Fragment>
  );
};

ObjectStops.propTypes = {
  data: PropTypes.object.isRequired,
  select: PropTypes.number,
};

export default ObjectStops;
