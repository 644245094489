import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 15,
    fontStyle: 'italic',
  },
}))(TableCell);

const list = [null, null];
const SkeletonAlarmList = () => {
  const items = React.useMemo(() => {
    return list.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant='text' height={'40px'} />
          </TableCell>
          <TableCell>
            <Skeleton variant='text' height={'40px'} />
          </TableCell>
          <TableCell>
            <Skeleton variant='text' height={'40px'} />
          </TableCell>
        </TableRow>
      );
    });
  }, []);

  return (
    <Table size='small' aria-label='print report table' stickyHeader>
      <TableHead>
        <TableRow>
          <StyledTableCell align='left'>Име</StyledTableCell>
          <StyledTableCell align='left'>Вид</StyledTableCell>
          <StyledTableCell align='left'>Цел</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>{items}</TableBody>
    </Table>
  );
};

SkeletonAlarmList.propTypes = {
  list: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  editMode: PropTypes.bool,
};

export default SkeletonAlarmList;
