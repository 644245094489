import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import AlarmItem from './AlarmItem';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  emptyList: {
    height: '80px',
    textAlign: 'center',
  },
  table: {
    '& tr td:nth-child(2)': {
      minWidth: '200px',
    },
    '& tr td:nth-child(4)': {
      minWidth: '180px',
    },
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white,
    fontSize: 15,
    fontStyle: 'italic',
  },
}))(TableCell);

const AlarmList = ({ list, onChange, onDelete, onError, editMode }) => {
  const classes = useStyles();
  const items = React.useMemo(() => {
    return list.map(alarm => {
      return (
        <AlarmItem
          key={alarm.id}
          data={alarm}
          onChange={onChange}
          onDelete={onDelete}
          onError={onError}
          editMode={editMode}
        ></AlarmItem>
      );
    });
  }, [editMode, list, onChange, onDelete, onError]);

  return items.length > 0 ? (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TableContainer>
        <Table
          className={classes.table}
          size='small'
          aria-label='print report table'
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align='left'></StyledTableCell>
              <StyledTableCell align='left'>Име</StyledTableCell>
              <StyledTableCell align='left'>Вид</StyledTableCell>
              <StyledTableCell align='left'>Цел</StyledTableCell>
              <StyledTableCell align='left'>Вкл.</StyledTableCell>
              {editMode && <StyledTableCell align='left'></StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{items}</TableBody>
        </Table>
      </TableContainer>
    </MuiPickersUtilsProvider>
  ) : (
    <div className={classes.emptyList}>
      <p>Няма аларми</p>
    </div>
  );
};

AlarmList.propTypes = {
  list: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
  editMode: PropTypes.bool,
};

export default AlarmList;
