import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import SideDrawerButton from './SideDrawerButton.js';
import { makeStyles } from '@material-ui/core/styles';

const makeDynamicStyles = (desktopWidth, mobileHeight) => {
  const convert = length => {
    if (typeof lenght != 'string') {
      if (length <= 1) {
        return length * 100 + '%';
      }
    }
    return length;
  };

  return makeStyles(theme => ({
    drawerPaper: {
      width: convert(desktopWidth),
      position: 'relative',
    },
    drawerPaperSm: {
      overflow: 'visible',
      height: convert(mobileHeight),
    },
    drawer: {
      display: 'flex',
      minHeight: 0,
      flexBasis: 'auto',
      flexGrow: 0,
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        flexShrink: 0,
        width: convert(desktopWidth),
      },
    },
    hidden: {
      display: 'flex',
    },
  }));
};

const useStyles = makeStyles(theme => ({
  // root: {
  //   display: 'flex',
  // },
  drawerContainer: {
    overflowY: 'auto',
  },
  toolbar: theme.mixins.toolbar,
  buttonUp: {
    width: '20%',
    height: '20px',
    position: 'fixed',
    bottom: '0px',
    left: '40%',
  },
  buttonDown: {
    width: '20%',
    height: '20px',
    position: 'absolute',
    top: '-20px',
    left: '40%',
  },
}));

function SideDrawer({ children, desktopWidth, mobileHeight }) {
  const useDynamicClasses = makeDynamicStyles(desktopWidth, mobileHeight);
  const classes = useStyles();
  const dynamicClasses = useDynamicClasses();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={dynamicClasses.drawer} aria-label='objects menu'>
        <Hidden smUp implementation='js'>
          <Drawer
            //SlideProps={{ in: true }}
            //container={container}
            variant='temporary'
            anchor={'bottom'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: dynamicClasses.drawerPaperSm,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SideDrawerButton
              state='down'
              className={classes.buttonDown}
              onClick={handleDrawerToggle}
            />
            {children}
          </Drawer>
          <SideDrawerButton
            state='up'
            className={classes.buttonUp}
            onClick={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation='js' className={classes.hidden}>
          <Drawer
            classes={{
              paper: dynamicClasses.drawerPaper,
            }}
            variant='permanent'
            open
          >
            {children}
          </Drawer>
        </Hidden>
      </div>
    </React.Fragment>
  );
}

SideDrawer.propTypes = {
  children: PropTypes.object,
  desktopWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mobileHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SideDrawer;
