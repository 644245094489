import React from 'react';
import PropTypes from 'prop-types';

const useTimeout = (onTimeout, timeout, deps) => {
  const [running, setRunning] = React.useState(true);

  const callback = React.useCallback(onTimeout, deps);

  React.useEffect(() => {
    if (!running) {
      return;
    }

    const timer = setTimeout(callback, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, [callback, running, timeout]);

  return {
    stop: () => !running || setRunning(false),
    start: () => running || setRunning(true),
    isRunning: () => running,
  };
};

useTimeout.propTypes = {
  onTimeout: PropTypes.func,
  timeout: PropTypes.number,
  deps: PropTypes.array,
};

export default useTimeout;
