import { formatTimeInterval, formatTime, formatDate } from './common';

//Let's split rest perdiod by day(s)
const splitRestPeriod = period => {
  const list = [];
  while (period.restStart.getDate() !== period.restEnd.getDate()) {
    const restEnd = new Date(
      new Date(period.restStart).setHours(23, 59, 59, 999),
    );
    const restStart = new Date(new Date(period.restEnd).setHours(0, 0, 0, 0));
    list.push(
      Object.assign({}, period, {
        restEnd: restEnd,
        workStart: restEnd,
        workEnd: restEnd,
        distance: 0,
      }),
    );
    period = Object.assign({}, period, { restStart: restStart });
  }
  list.push(period);
  return list;
};

//Let's split work perdiod by day(s)
// const splitWorkPeriod = period => {
//   const list = [];
//   while (period.workStart.getDate() != period.workEnd.getDate()) {
//     const workEnd = new Date(
//       new Date(period.workStart).setHours(23, 59, 59, 999),
//     );
//     const workStart = new Date(new Date(period.workEnd).setHours(0, 0, 0, 0));
//     list.push(Object.assign({}, period, { workEnd: workEnd }));
//     period = Object.assign({}, period, {
//       restStart: workStart,
//       restEnd: workStart,
//       workStart: workStart,
//     });
//   }
//   if (list.length) {
//     return list;
//   } else {
//     return [period];
//   }
// };

const splitPeriods = periods => {
  return periods.reduce((list, period) => {
    const restList = splitRestPeriod(period);
    // const workList = splitWorkPeriod(restList.pop());
    // return list.concat(restList.concat(workList));
    return list.concat(restList);
  }, []);
};

const createGroup = period => {
  return {
    startTime: new Date(new Date(period.restStart).setHours(0, 0, 0, 0)),
    periods: [],
  };
};

const groupPeriods = periods => {
  const [startPeriod] = periods;
  let date = startPeriod.restStart.getDate();
  let currentGroup = createGroup(startPeriod);
  let groups = [];
  for (let i = 0; i < periods.length; i++) {
    let period = periods[i];
    if (date !== period.restStart.getDate()) {
      groups.push(currentGroup);
      currentGroup = createGroup(period);
      date = period.restStart.getDate();
    }
    currentGroup.periods.push(period);
  }
  groups.push(currentGroup);
  return groups;
};

const preparePeriods = periods => {
  return periods.map((period, index) => {
    return Object.assign(period, {
      restStart: period.restStart && new Date(period.restStart),
      restEnd: period.restEnd && new Date(period.restEnd),
      workStart: period.workStart && new Date(period.workStart),
      workEnd: period.workEnd && new Date(period.workEnd),
      keyIndex: index,
    });
  });
};

const posOrNull = value => (value < 0 ? 0 : value);

export const calcOvertime = (
  startMinutes,
  endMinutes,
  limitStart,
  limitEnd,
) => {
  const w = endMinutes - startMinutes;
  const a = limitStart - startMinutes;
  const b = endMinutes - limitEnd;
  const sum = posOrNull(a) + posOrNull(b);
  return sum > w ? w : sum;
};

const calcStartDayMiliSec = date => {
  return date.getTime() - new Date(date).setHours(0, 0, 0, 0);
};

// const calcPeriodsOvertime = (groups, options) => {
//   return groups.map(group => {
//     let totalOvertime = 0;
//     const periods = group.periods.map(period => {
//       const start = calcStartDayMiliSec(period.workStart);
//       const end = calcStartDayMiliSec(period.workEnd);
//       const overtime = calcOvertime(
//         start,
//         end,
//         options.workStart,
//         options.workEnd,
//       );
//       totalOvertime += overtime;
//       return { ...period, overtime };
//     });
//     return { ...group, periods, overtime: totalOvertime };
//   });
// };

const formatInput = periods => {
  const prepared = preparePeriods(periods);
  const splited = splitPeriods(prepared);
  return groupPeriods(splited);
};

export default class PrintReport {
  constructor(name, periods, details) {
    this.name = name;
    this.periods = periods;
    this.details = details;
  }

  init() {
    if (!this.dayGroups) {
      this.dayGroups = formatInput(this.periods);
    }
  }

  formatData() {
    this.init();
    const { hasWorktime } = this.details;
    let prevPeriod = null;
    let totalDayRest, totalDayWork, totalDayOvertime, totalDayDist;
    let totalRest, totalWork, totalOvertime; //let totalRest, totalWork, totalDist;
    totalRest = totalWork = totalOvertime = 0; // totalRest = totalWork = totalDist = 0;
    const daysFormated = this.dayGroups.map(group => {
      totalDayRest = totalDayWork = totalDayDist = totalDayOvertime = 0;
      const startTime = new Date(group.startTime);
      const periodsFormated = group.periods.map(item => {
        const keyIndex = item.keyIndex;
        const restStart = new Date(item.restStart);
        const restEnd = new Date(item.restEnd);
        let work, workHours, distance;
        if (item.workStart === null || item.workEnd === null) {
          work = null;
          workHours = null;
          distance = null;
        } else {
          const workStart = new Date(item.workStart);
          const workEnd = new Date(item.workEnd);
          const workHoursMSec = workEnd - workStart;
          if (workStart.getTime() === workEnd.getTime()) {
            distance = null;
            work = null;
            workHours = null;
          } else {
            distance = item.distance / 1000;
            work = formatTime(workStart) + '-' + formatTime(workEnd);
            if (workEnd.getDate() > workStart.getDate()) {
              work += ' (' + formatDate(workEnd) + ')';
            }
            workHours = formatTimeInterval(workEnd - workStart);
            totalDayWork += workHoursMSec;
            totalDayDist += distance;
          }
        }
        const restHoursMSec = restEnd - restStart;
        const restHours = formatTimeInterval(restHoursMSec);
        totalDayRest += restHoursMSec;

        const currPeriod = {
          key: restStart.getTime(),
          work: work,
          workHours: workHours,
          distance: distance,
          rest: formatTime(restStart) + '-' + formatTime(restEnd),
          restHours: restHours,
          coordinates: item.coordinates,
          keyIndex: keyIndex,
          nextPeriod: null,
        };

        if (prevPeriod) {
          prevPeriod.nextPeriod = currPeriod;
        }

        prevPeriod = currPeriod;

        if (hasWorktime) {
          const start = calcStartDayMiliSec(item.workStart);
          const end = calcStartDayMiliSec(item.workEnd);
          const day = item.workStart.getDay();
          const overtime =
            day === 0 || day === 6
              ? item.workEnd.getTime() - item.workStart.getTime()
              : calcOvertime(
                  start,
                  end,
                  this.details.workStart,
                  this.details.workEnd,
                );
          currPeriod.overtime = formatTimeInterval(overtime);
          totalDayOvertime += overtime;
        }
        return currPeriod;
      });
      totalRest += totalDayRest;
      totalWork += totalDayWork;
      totalOvertime += totalDayOvertime;
      //totalDist += totalDayDist;
      return {
        day: formatDate(startTime),
        periods: periodsFormated,
        rest: formatTimeInterval(totalDayRest),
        work: formatTimeInterval(totalDayWork),
        dist: Math.round(totalDayDist * 100) / 100,
        overtime: hasWorktime
          ? formatTimeInterval(totalDayOvertime)
          : undefined,
      };
    });
    return {
      days: daysFormated,
      name: this.name,
      totalRest: formatTimeInterval(totalRest),
      totalWork: formatTimeInterval(totalWork),
      startDist: this.details.startDist,
      endDist: this.details.endDist,
      totalDist: this.details.totalDist,
      overtime: hasWorktime ? formatTimeInterval(totalOvertime) : undefined,
      //totalDist: Math.round(totalDist * 100) / 100,
    };
  }
}
