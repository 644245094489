import configuration from './configuration';

const querystring = require('querystring');

const corsOptions = {
  credentials: 'include',
};

const loginData = {
  jwt: undefined,
};

const removeJWT = () => {
  loginData.jwt = undefined;
};

const saveJWT = token => {
  loginData.jwt = token;
};

const loadJWT = () => {
  return loginData.jwt;
};

const verrifyResponseData = promise => {
  return promise
    .then(data => {
      if (!data.ok) {
        throw new Error('Ajax request failed to fetch custom report`s data!');
      }
      return data.json();
    })
    .then(json => {
      if (!json.ok) {
        throw new Error('Ajax request failed to fetch custom report`s data!');
      }
      return json;
    });
};

const getObjectList = (server, options, authData) => {
  const path = '/api/v1/object';
  const url = server + path;
  const { signal } = options || {};

  return new Promise((resolve, reject) => {
    if (signal) {
      signal(reject, this);
    }
    fetch(url, { ...corsOptions, headers: authData.headers })
      .then(data => data.json())
      .then(resolve)
      .catch(reject);
  });
};

const getHeartbeat = (server, authData) => {
  const path = '/api/v1/heartbeat';
  const url = server + path;
  return fetch(url, { headers: authData.headers }).then(data => {
    return data.json();
  });
};

const getObjectLatestData = (server, objectId, options, authData) => {
  const path = '/api/v1/object/' + objectId;
  const url = server + path;
  const { signal } = options || {};

  return new Promise((resolve, reject) => {
    if (signal) {
      signal(reject, this);
    }
    fetch(url, { ...corsOptions, headers: authData.headers })
      .then(data => {
        return data.json();
      })
      .then(resolve)
      .catch(reject);
  });
};

const getAddressListAsync = (server, trackingId, authData) => {
  const path = '/api/v1/report/print/geocode/' + trackingId;
  const url = server + path;
  return fetch(url, { ...corsOptions, headers: authData.headers }).then(data =>
    data.json(),
  );
};

const getReportAsync = (
  server,
  reportType,
  id,
  from,
  to,
  authData,
  otherParams,
) => {
  let path = '/api/v1/report/' + reportType + '/' + id;
  const objectQuery = {
    from: from
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    to: to
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
  };
  const queryString = querystring.stringify(
    otherParams ? { ...objectQuery, ...otherParams } : objectQuery,
  );
  const url = server + path + '?' + queryString;
  return fetch(url, { ...corsOptions, headers: authData.headers })
    .then(data => {
      if (!data.ok) {
        throw new Error('Ajax request failed to fetch custom report`s data!');
      }
      return data.json();
    })
    .then(json => {
      if (!json.ok) {
        throw new Error('Ajax request failed to fetch custom report`s data!');
      }
      return json;
    });
};

const getCustomReportList = (server, authData) => {
  const path = '/api/v1/report/custom';

  const url = server + path;
  return fetch(url, { ...corsOptions, headers: authData.headers })
    .then(data => {
      if (!data.ok) {
        throw new Error('Ajax request failed to fetch custom report`s data!');
      }
      return data.json();
    })
    .then(json => {
      if (!json.ok) {
        throw new Error('Ajax request failed to fetch custom report`s data!');
      }
      return json;
    });
};

const getCustomReport = (server, objectId, reportParam, from, to, authData) => {
  let path = '/api/v1/report/custom/' + reportParam + '/' + objectId;
  const queryString = querystring.stringify({
    from: from
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    to: to
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
  });
  const url = server + path + '?' + queryString;
  return verrifyResponseData(
    fetch(url, { ...corsOptions, headers: authData.headers }),
  );
};

const login = (server, user, pass) => {
  const path = '/api/v1/login';
  const url = server + path;
  if (user && pass) {
    //const base64 = window.btoa(user + ':' + pass);
    // const headers = { Authorization: 'Basic ' + base64, ...authData.headers };
    const body = {
      username: user,
      password: pass,
    };
    const options = {
      ...corsOptions,
      body: JSON.stringify(body),
      method: 'POST',
    };
    return fetch(url, options)
      .then(data => {
        return data.json();
      })
      .catch(e => {
        throw e;
      });
  } else {
    // return fetch(url, corsOptions).then(data => {
    //   return data.json();
    // });
    return Promise.resolve({});
  }
};

const refreshToken = (server, authData) => {
  const path = '/api/v1/login/refresh';
  const url = server + path;
  const options = { ...corsOptions, headers: authData.headers };
  return fetch(url, options)
    .then(data => {
      return data.json();
    })
    .catch(e => {
      throw e;
    });
};

const logout = () => {
  // const value = authData.token;
  // const path = '/api/v1/logout';
  // const url = server + path;
  // const headers = {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  //   'X-CSRF-TOKEN': value,
  // };
  // const options = Object.assign(
  //   { headers: headers, method: 'POST' },
  //   corsOptions,
  // );
  //return fetch(url, options);
  removeJWT();
  return Promise.resolve();
};

const getAllAlarms = (server, authData) => {
  const path = '/api/v1/alarm';
  const url = server + path;
  return fetch(url, { ...corsOptions, headers: authData.headers }).then(
    data => {
      if (!data.ok) {
        throw new Error('Ajax request failed to fetch alarms` data!');
      }
      return data.json();
    },
  );
};

const getAlarms = (server, objectId, authData) => {
  const path = '/api/v1/alarm/' + objectId;
  const url = server + path;
  return fetch(url, { ...corsOptions, headers: authData.headers }).then(
    data => {
      if (!data.ok) {
        throw new Error('Ajax request failed to fetch alarms` data!');
      }
      return data.json();
    },
  );
};

const createAlarm = (server, objectId, name, type, authData) => {
  const path = '/api/v1/alarm/' + objectId;
  // const token = authData.token;
  const url = server + path;
  const data = { name, type };
  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
    //    'X-CSRF-TOKEN': token,
    ...authData.headers,
  };
  const options = Object.assign(
    { headers: headers, method: 'POST', body: JSON.stringify(data) },
    corsOptions,
  );
  return fetch(url, options).then(data => {
    if (!data.ok) {
      throw new Error('Ajax request failed to submit changes!');
    }
    return data.json();
  });
};

const updateAlarm = (server, data, authData) => {
  const path = '/api/v1/alarm/' + data.id;
  // const token = csrfPair.token;
  const url = server + path;
  const newData = {
    name: data.name,
    type: data.type,
    value: data.value,
    text: data.text,
    status: data.status,
  };
  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
    // 'X-CSRF-TOKEN': token,
    ...authData.headers,
  };
  const options = Object.assign(
    { headers: headers, method: 'PUT', body: JSON.stringify(newData) },
    corsOptions,
  );

  return fetch(url, options).then(data => {
    if (!data.ok) {
      throw new Error('Ajax request failed to submit changes!');
    }
    return data.json().then(json => {
      if (!json.ok) {
        throw new Error('Server says unprocessable data!');
      }
      return json;
    });
  });
};

const deleteAlarm = (server, alarmId, authData) => {
  const path = '/api/v1/alarm/' + alarmId;
  //const token = csrfPair.token;
  const url = server + path;
  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
    // 'X-CSRF-TOKEN': token,
    ...authData.headers,
  };

  const options = Object.assign(
    { headers: headers, method: 'DELETE' },
    corsOptions,
  );

  return fetch(url, options).then(data => {
    if (!data.ok) {
      throw new Error('Ajax request failed to submit changes!');
    }
    return data.json();
  });
};

const getNotifications = (server, options, authData) => {
  const path = '/api/v1/notification';
  const url = server + path;
  const { signal } = options || {};

  return new Promise((resolve, reject) => {
    if (signal) {
      signal(reject, this);
    }

    return fetch(url, { ...corsOptions, headers: authData.headers })
      .then(data => data.json())
      .then(resolve)
      .catch(reject);
  });
};

const markNotificationsAsRead = (server, idList, authData) => {
  const path = '/api/v1/notification';
  const url = server + path;
  const data = { list: idList };
  // const token = csrfPair.token;
  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
    // 'X-CSRF-TOKEN': token,
    ...authData.headers,
  };
  const options = Object.assign(
    { headers: headers, method: 'PUT', body: JSON.stringify(data) },
    corsOptions,
  );
  return fetch(url, options).then(data => {
    if (data.status !== 200) {
      throw new Error('Ajax request failed to submit changes!');
    }
    return data.json();
  });
};

const deleteNotification = (server, id, authData) => {
  const path = '/api/v1/notification/' + id;
  const url = server + path;
  // const token = csrfPair.token;
  const headers = {
    // 'X-CSRF-TOKEN': token,
    ...authData.headers,
  };
  const options = Object.assign(
    { headers: headers, method: 'DELETE' },
    corsOptions,
  );
  return fetch(url, options).then(data => {
    if (data.status !== 200) {
      throw new Error('Ajax request failed to submit changes!');
    }
    return data.json();
  });
};

const getUserData = (server, authData) => {
  const path = '/api/v1/user';
  const url = server + path;
  return fetch(url, { ...corsOptions, headers: authData.headers }).then(
    data => {
      if (!data.ok) {
        throw new Error('Ajax request failed to fetch user`s data!');
      }
      return data.json();
    },
  );
};

const updateUserData = (server, settings, authData) => {
  const path = '/api/v1/user';
  const url = server + path;
  const { hasWorktime, username, ...data } = settings;
  // const token = csrfPair.token;
  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
    // 'X-CSRF-TOKEN': token,
    ...authData.headers,
  };
  const options = Object.assign(
    { headers: headers, method: 'PUT', body: JSON.stringify(data) },
    corsOptions,
  );
  return fetch(url, options).then(data => {
    if (data.status !== 200) {
      throw new Error('Ajax request failed to submit changes!');
    }
    return data.json();
  });
};

const getObjectSettings = (server, objectId, authData) => {
  const path = '/api/v1/user/object/' + objectId;
  const url = server + path;

  return new Promise((resolve, reject) => {

    fetch(url, { ...corsOptions, headers: authData.headers })
      .then(data => {
        return data.json();
      })
      .then(resolve)
      .catch(reject);
  });
};

const updateObjectSettings = (server, objectId, data, authData) => {
  const path = '/api/v1/user/object/' + objectId;
  const url = server + path;
  // const token = csrfPair.token;
  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
    // 'X-CSRF-TOKEN': token,
    ...authData.headers,
  };
  const options = Object.assign(
    { headers: headers, method: 'PUT', body: JSON.stringify(data) },
    corsOptions,
  );
  return fetch(url, options).then(data => {
    if (data.status !== 200) {
      throw new Error('Ajax request failed to submit changes!');
    }
    return data.json();
  });
}

const restApi = ((host, port) => {
  const generateHeadersData = function() {
    // const token = localStorage.getItem('_jwt');
    const token = loadJWT();
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      return {};
    }
  };

  const server = host + (port ? ':' + port : '');
  return {
    getHeartbeatAsync: function() {
      return getHeartbeat(server, generateHeadersData());
    },
    getObjectAsync: function(objectId, options) {
      return getObjectLatestData(
        server,
        objectId,
        options,
        generateHeadersData(),
      );
    },
    getObjectListAsync: function(options) {
      return getObjectList(server, options, generateHeadersData());
    },
    getSpeedViewAsync: function(objectId, from, to) {
      return getReportAsync(
        server,
        'speed',
        objectId,
        from,
        to,
        generateHeadersData(),
      );
    },
    getFuelViewAsync: function(objectId, from, to, otherParams) {
      return getReportAsync(
        server,
        'fuel',
        objectId,
        from,
        to,
        generateHeadersData(),
        otherParams,
      );
    },
    getPrintViewAsync: function(objectId, from, to) {
      return getReportAsync(
        server,
        'print',
        objectId,
        from,
        to,
        generateHeadersData(),
      );
    },
    getCoverageViewAsync: function(objectId, from, to) {
      return getReportAsync(
        server,
        'coverage',
        objectId,
        from,
        to,
        generateHeadersData(),
      );
    },
    getReportViewAsync: function(view, objectId, from, to, otherParams) {
      switch (view) {
        case 'speed':
          return this.getSpeedViewAsync(objectId, from, to);
        case 'fuel':
          return this.getFuelViewAsync(objectId, from, to, otherParams);
        case 'print':
          return this.getPrintViewAsync(objectId, from, to);
        case 'coverage':
          return this.getCoverageViewAsync(objectId, from, to);
        default:
      }
    },
    getCustomReportListAsync: function() {
      return getCustomReportList(server, generateHeadersData());
    },
    getCustomReportAsync: function(objectId, reportParam, from, to) {
      return getCustomReport(
        server,
        objectId,
        reportParam,
        from,
        to,
        generateHeadersData(),
      );
    },
    loginAsync: function(user, pass) {
      return login(server, user, pass, generateHeadersData());
    },
    refreshToken: function() {
      return refreshToken(server, generateHeadersData());
    },
    logoutAsync: function() {
      return logout(server, generateHeadersData());
    },
    getReportAddressList: function(trackingId) {
      return getAddressListAsync(server, trackingId, generateHeadersData());
    },
    getAllAlarmsAsync: function() {
      return getAllAlarms(server, generateHeadersData());
    },
    getAlarmsAsync: function(objectId) {
      return getAlarms(server, objectId, generateHeadersData());
    },
    createAlarmAsync: function(objectId, name, type) {
      return createAlarm(server, objectId, name, type, generateHeadersData());
    },
    updateAlarmAsync: function(data) {
      return updateAlarm(server, data, generateHeadersData());
    },
    deleteAlarmAsync: function(alarmId) {
      return deleteAlarm(server, alarmId, generateHeadersData());
    },
    getNotificationsAsync: function(options) {
      return getNotifications(server, options, generateHeadersData());
    },
    markNotificationsAsReadAsync: function(idList) {
      return markNotificationsAsRead(server, idList, generateHeadersData());
    },
    deleteNotificationAsync: function(id) {
      return deleteNotification(server, id, generateHeadersData());
    },
    updateUserDataAsync: function(settings) {
      return updateUserData(server, settings, generateHeadersData());
    },
    getUserDataAsync: function() {
      return getUserData(server, generateHeadersData());
    },
    getObjectSettingsAsyn: function(objectId) {
      return getObjectSettings(server, objectId, generateHeadersData());
    },
    updateObjectSettingsAsyn: function(objectId, data) {
      return updateObjectSettings(server, objectId, data, generateHeadersData());
    },    
    setAuthTokens: function(token) {
      //authTokens.username = pair.username;
      // localStorage.setItem('_jwt', pair.token);
      saveJWT(token);
      // authTokens.token = pair.token;
    },
    // getAuthTokens: function() {
    //   // return localStorage.getItem('_jwt');
    //   return loadJWT();
    // },
  };
})(configuration().api.url, configuration().api.port);

export default restApi;

//This class can be used to 'abort' some of ajax requests in the code above.
//The object's instance is reusable and will affect only the last used request,
//event if 'abort' has already been called.
export class RestApiController {
  constructor() {
    this.aborted = false;
    this.signal = this.signal.bind(this);
  }
  signal(abortFn, scope) {
    if (this.aborted) {
      abortFn.apply(scope, { reason: 'canceled' });
      this.aborted = false;
    } else {
      this.abortFn = abortFn.bind(scope);
    }
  }
  abort() {
    if (this.abortFn) {
      this.abortFn({ reason: 'canceled' });
      this.aborted = false;
    } else {
      this.aborted = true;
    }
  }
}

export const retryRestRequest = (fn, options) => {
  let { maxCount } = options || {};
  return fn().catch(message => {
    if (message && message.reason === 'canceled') {
      throw message;
    }

    if (maxCount === undefined) {
      //Let's deffer 'retryRestRequest' in 10 seconds,
      //to avoid a high rate repetitive eternal looping invokation.
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          retryRestRequest(fn)
            .then(resolve)
            .catch(reject);
        }, 10000);
      });
    } else if (maxCount > 0) {
      --options.maxCount;
      return retryRestRequest(fn, options);
    } else {
      throw new Error('Max tries reached!');
    }
  });
};
