import React from 'react';
import PropTypes from 'prop-types';
import NoDataDialog from './NoDataDialog';
import ObjectReportDialog from './ObjectReportDialog';
import ObjectListReportDialog from './ObjectListReportDialog';
import ObjectSelectDialog from './ObjectSelectDialog';
import CustomReportDialog from './CustomReportDialog';
import ObjectListCustomReportDialog from './ObjectListCustomReportDialog';

const DialogManager = ({ open, dialogType, data, state = {}, onChange }) => {
  return (
    <React.Fragment>
      {dialogType === NoDataDialog && <NoDataDialog open={open} {...data} />}
      {dialogType === ObjectReportDialog && (
        <ObjectReportDialog
          open={open}
          onChange={onChange}
          state={state}
          {...data}
        />
      )}
      {dialogType === ObjectSelectDialog && (
        <ObjectSelectDialog
          open={open}
          onChange={onChange}
          state={state}
          {...data}
        />
      )}
      {dialogType === ObjectListReportDialog && (
        <ObjectListReportDialog
          open={open}
          onChange={onChange}
          state={state}
          {...data}
        />
      )}
      {dialogType === CustomReportDialog && (
        <CustomReportDialog
          open={open}
          onChange={onChange}
          state={state}
          {...data}
        />
      )}
      {dialogType === ObjectListCustomReportDialog && (
        <ObjectListCustomReportDialog
          open={open}
          onChange={onChange}
          state={state}
          {...data}
        />
      )}
    </React.Fragment>
  );
};

DialogManager.propTypes = {
  open: PropTypes.bool,
  dialogType: PropTypes.elementType,
  data: PropTypes.object,
  state: PropTypes.object,
  onChange: PropTypes.func,
};

export default DialogManager;
