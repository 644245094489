import React from 'react';
import PropTypes from 'prop-types';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { TimeInput } from '@trendmicro/react-datepicker';
import { useUpdate } from '../hooks';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  // root: {
  //   '& div': {
  //     paddingTope: theme.spacing(2),
  //   },
  // },
  leftColumn: {
    [theme.breakpoints.up("sm")]: {
      textAlign: 'right',
    },
    paddingRight: theme.spacing(2),
  },
  singleColumn: {
    textAlign: 'center',
  },
}));

const UserSettings = ({ onLogout, data, editMode, onChange }) => {
  const classes = useStyles();
  const [dataState, setDataState] = React.useState(data);
  // const [email, setEmail] = React.useState(data.email);
  const [emailErrorText, setEmailErrorText] = React.useState(null);
  const wrongMailFormatError = 'Има грешка в изписването на пощата ви!';
  const hasWorktime = data.hasWorktime === true;

  useUpdate(() => {
    setDataState(data);
  }, [data]);

  const validateEmail = email => {
    return /^[\w-.]+@[\w-]+\.+[\w-]{2,4}$/g.test(email);
  };

  const onEmailChange = ev => {
    const value = ev.currentTarget.value;
    if (validateEmail(value)) {
      onChange(Object.assign({}, data, { email: value }));
      setEmailErrorText(null);
    } else {
      setEmailErrorText(wrongMailFormatError);
    }
    setDataState({ ...dataState, email: value });
  };

  const onWorkStartChange = React.useCallback(
    value => {
      onChange(Object.assign({}, dataState, { workStart: value }));
      setDataState({ ...dataState, workStart: value });
    },
    [dataState, onChange],
  );

  const onWorkEndChange = React.useCallback(
    value => {
      onChange(Object.assign({}, dataState, { workEnd: value }));
      setDataState({ ...dataState, workEnd: value });
    },
    [dataState, onChange],
  );

  return (
    <Grid
      container
      // justify='center'
      // alignItems='center'
      // direction='column'
      //style={{ minHeight: '100%' }}
    >
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Потребител: </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>{data.username}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Електронна поща:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            error={emailErrorText !== null}
            value={dataState.email}
            name={'email'}
            helperText={emailErrorText}
            inputProps={{ minLength: 4 }}
            onChange={onEmailChange}
            disabled={editMode === false}
          />
        </Typography>
      </Grid>
      {hasWorktime && (
        <React.Fragment>
          <Grid item xs={12} sm={6} className={classes.leftColumn}>
            <Typography variant='h6'>Работно време начало:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {editMode === false ? (
              <Typography variant='h6'>{dataState.workStart}</Typography>
            ) : (
              <TimeInput
                value={dataState.workStart || '00:00'}
                onChange={onWorkStartChange}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.leftColumn}>
            <Typography variant='h6'>Работно време край:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {editMode === false ? (
              <Typography variant='h6'>{dataState.workEnd}</Typography>
            ) : (
              <TimeInput
                value={dataState.workEnd || '00:00'}
                onChange={onWorkEndChange}
              />
            )}
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={12} className={classes.singleColumn}>
        <Button type='button' onClick={onLogout}>
          Излез
          <LogoutIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

UserSettings.propTypes = {
  onLogout: PropTypes.func,
  data: PropTypes.object,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
};

export default UserSettings;
