/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import LoginForm from './components/LoginForm';
import LoadingAnimation from './components/LoadingAnimation';
import {
  deinitData,
  userInit,
  reportOptionsUpdate,
  loginJwt,
} from './actions/index';

import { useDispatch } from 'react-redux';
import server from './restApi';

const Login = ({ auth }) => {
  const dispatch = useDispatch();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const onSubmit = (user, pass) => {
    auth
      .login(user, pass)
      .then(result => {
        if (result.success) {
          const jwt = result.data.data.jwt;
          server.setAuthTokens(jwt);
          dispatch(deinitData());
          dispatch(loginJwt(jwt));
          return server.getUserDataAsync();
        } else {
          throw new Error('Грешна парола или потребител.');
        }
      })
      .then(result => {
        const { hasWorktime, hasNotesColumn, workStart, workEnd } = result.data;
        dispatch(
          reportOptionsUpdate({
            hasWorktime,
            hasNotesColumn,
            workStart,
            workEnd,
          }),
        );
        dispatch(userInit(result.data));
      })
      .catch(e => {
        setError(e.message);
      });
  };

  React.useEffect(() => {
    auth
      .login()
      .then(result => {
        if (result.success) {
          return server.getUserDataAsync();
        }
      })
      .then(result => {
        if (result) {
          const {
            hasWorktime,
            hasNotesColumn,
            workStart,
            workEnd,
          } = result.data;
          dispatch(
            reportOptionsUpdate({
              hasWorktime,
              hasNotesColumn,
              workStart,
              workEnd,
            }),
          );
          dispatch(userInit(result.data));
          //onRedirect();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [auth, dispatch]);

  if (loading) {
    return <LoadingAnimation />;
  } else {
    return <LoginForm onSubmit={onSubmit} error={error} />;
  }
};

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  //onRedirect: PropTypes.func.isRequired,
};

export default Login;
