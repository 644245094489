import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NoDataDialog from './NoDataDialog';
import { showView, reportReset, changeCoverageDay } from '../actions/index';
import MapCoverageReport from './MapCoverageReport';

const CoverageReportView = () => {
  const dispatch = useDispatch();
  const onConfirm = () => {
    dispatch(showView('objects'));
    dispatch(reportReset('coverage'));
  };
  const coverageReport = useSelector(state => state.coverageReport);

  const handleSelectItem = React.useCallback(selectedDayData => {
    dispatch(changeCoverageDay(selectedDayData));
  }, [dispatch]);
  // const data = React.useMemo(() => {
  //   return {
  //     data: coverageReport.data,
  //     selectedDayData: coverageReport.selectedDayData,
  //   };
  // }, [coverageReport]);
  const errorDialog = coverageReport.status === 'nodata';

  return (
    <React.Fragment>
      {errorDialog && (
        <NoDataDialog
          open={true}
          onClose={onConfirm}
          objectName={coverageReport.queryParams.name}
          startDate={coverageReport.queryParams.start}
          endDate={coverageReport.queryParams.end}
        />
      )}
      <MapCoverageReport 
        data={coverageReport.data} 
        selectedDayData={coverageReport.selectedDayData} 
        params={coverageReport.queryParams}
        onSelectItem={handleSelectItem}
      />
    </React.Fragment>
  );
};

// ViewStack.propTypes = {
//   views: PropTypes.object,
//   show: PropTypes.string,
// };

export default CoverageReportView;
