import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  text: {
    color: 'black',
  },
}));

const NoDataDialog = ({ open, onClose, objectName, startDate, endDate }) => {
  const styles = useStyles();
  const handleConfirm = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const startFormated = moment(startDate).format('MMMM Do YYYY, h:mm:ss a');
  const endFormated = moment(endDate).format('MMMM Do YYYY, h:mm:ss a');
  const message = (
    <React.Fragment>
      Няма данни за обект <span className={styles.text}>{objectName}</span> в
      периода между <span className={styles.text}>{startFormated}</span> и{' '}
      <span className={styles.text}>{endFormated}</span>
    </React.Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>{'Няма данни'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} variant='outlined'>
          Разбрах
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NoDataDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  objectName: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

export default NoDataDialog;
