import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfoChartMap from './InfoChartMap';
import NoDataDialog from './NoDataDialog';
import { showView, reportReset } from '../actions/index';

const SpeedView = () => {
  const dispatch = useDispatch();
  const onConfirm = () => {
    dispatch(showView('objects'));
    dispatch(reportReset('speed'));
  };
  const speedReport = useSelector(state => state.report.speed);
  const errorDialog = speedReport.status === 'nodata';
  const reportType =
    speedReport.status === 'nodata' || speedReport.status === 'loading'
      ? 'loading'
      : 'speed';

  return (
    <React.Fragment>
      {errorDialog && (
        <NoDataDialog
          open={true}
          onClose={onConfirm}
          objectName={speedReport.objectName}
          startDate={speedReport.start}
          endDate={speedReport.end}
        />
      )}
      <InfoChartMap type={reportType} object={speedReport} />
    </React.Fragment>
  );
};

// ViewStack.propTypes = {
//   views: PropTypes.object,
//   show: PropTypes.string,
// };

export default SpeedView;
