import React from 'react';
import PropTypes from 'prop-types';
import {
  Map as LeafletMap,
  TileLayer,
  CircleMarker,
  AttributionControl,
} from 'react-leaflet';
import LocateButton from './LocateButton';
import TextSwitchButton from './TextSwitchButton';
import Leaflet, { DivIcon as LeafletDivIcon } from 'leaflet';
import configuration from '../../configuration';
import { useSelector } from 'react-redux';

const boundsPadding = 25;

const getLeafletIconUrl = type => {
  switch (type) {
    case 'start':
      return 'endpoint-start.svg';
    case 'finish':
      return 'endpoint-finish.svg';
    case 'stop':
      return 'frozen-object.svg';
    default:
      throw new Error('Icon type not recognized!');
  }
};

export const getLeafletIcon = type => {
  const url = getLeafletIconUrl(type);
  const img = "<img width=35 height=35 src='" + url + "' />";
  return new LeafletDivIcon({
    className: '',
    html: img,
    iconAnchor: [17, 32],
  });
};

export const calcAngle = (p0, p1) => {
  let x0 = p0.lng,
    x1 = p1.lng;
  let y0 = p0.lat,
    y1 = p1.lat;
  const dx = x1 - x0;
  const dy = y1 - y0;
  let radians = Math.atan(dy / dx);
  if (dx < 0) {
    radians += Math.PI;
  }
  return Math.PI / 2 - radians;
};

export const getLngLat = geoJsonCoords => {
  return {
    lng: geoJsonCoords[0],
    lat: geoJsonCoords[1],
  };
};

const calcViewportBounds = () => {
  if (configuration().map.bounds) {
    const northEastBound = Leaflet.latLng(
      configuration().map.bounds.northEast.lat,
      configuration().map.bounds.northEast.lng,
    );
    const southWestBound = Leaflet.latLng(
      configuration().map.bounds.southWest.lat,
      configuration().map.bounds.southWest.lng,
    );
    return Leaflet.latLngBounds(northEastBound, southWestBound);
  } else {
    return;
  }
};

const viewportBounds = calcViewportBounds();

const Map = ({
  viewport,
  bounds,
  userLocation,
  children,
  mapRef,
  onClick,
  onMouseDown,
  onSwitchTextBallon,
  textSwitchButton = false,
  // onLocationFound,
  ...other
}) => {
  const login = useSelector(state => state.login);
  const ref = React.useRef(mapRef);
  const [zoomMin, setZoomMin] = React.useState(
    other.maxZoom || configuration().map.zoom.min,
  );
  const [zoomMax, setZoomMax] = React.useState(
    other.minZoom || configuration().map.zoom.max,
  );
  const [viewSettings, setViewSettings] = React.useState({});

  //Viewport has greater importance than bounds
  const getViewSettings = (viewport, bounds) => {
    if (!viewport) {
      if (bounds) {
        const boundsZoom = ref.current.leafletElement.getBoundsZoom(
          bounds,
          false,
          [boundsPadding, boundsPadding],
        );
        const boundsCenter = [
          (bounds[0][0] + bounds[1][0]) / 2,
          (bounds[0][1] + bounds[1][1]) / 2,
        ];
        return { viewport: { zoom: boundsZoom, center: boundsCenter } };
      }
      return {};
    }

    if (viewport.zoom !== undefined && viewport.center !== undefined) {
      return { viewport: viewport };
    } else {
      return { zoom: viewport.zoom, center: viewport.center };
    }
  };

  // const handleLocationFound = e => {
  //   onLocationFound(e.latlng);
  // };

  let circleMarker = '';
  if (userLocation) {
    circleMarker = <CircleMarker center={userLocation} radius={20} />;
  }

  const getZoomBounds = viewportBounds => {
    if (
      viewportBounds.getWest() < configuration().map.bounds.southWest.lng ||
      viewportBounds.getEast() > configuration().map.bounds.northEast.lng ||
      viewportBounds.getSouth() < configuration().map.bounds.southWest.lat ||
      viewportBounds.getNorth() > configuration().map.bounds.northEast.lat
    ) {
      return [
        configuration().map.outboundsZoom.min,
        configuration().map.outboundsZoom.max,
      ];
    } else {
      return [configuration().map.zoom.min, configuration().map.zoom.max];
    }
  };

  const handleViewportChange = React.useCallback(() => {
    if (ref.current && configuration().map.outboundsZoom) {
      console.log('handleViewportChange <-----');
      const bounds = ref.current.leafletElement.getBounds();
      const [min, max] = getZoomBounds(bounds);
      setZoomMin(min);
      setZoomMax(max);
    }
  }, []);

  React.useEffect(() => {
    setViewSettings(getViewSettings(viewport, bounds));
  }, [viewport, bounds]);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const actualZoom = ref.current.leafletElement.getZoom();
    try {
      const center = ref.current.leafletElement.getCenter();
      if (actualZoom > zoomMax) {
        setViewSettings({ center: center, zoom: zoomMax });
      } else {
        setViewSettings({ center: center, zoom: actualZoom });
      }
    } catch (e) {
      console.warn('Failed to get map center: ', e.message);
    }
    ref.current.leafletElement.options.minZoom = zoomMin;
    ref.current.leafletElement.options.maxZoom = zoomMax;
    ref.current.leafletElement.zoomControl.enable();
  }, [zoomMin, zoomMax]);

  const tilesUrl = React.useMemo(() => {
    const url = configuration().map.tilesUrl;
    if (url.match(/{jwt/)) {
      return url.replace(/{jwt}/, login.jwt);
    } else {
      return url;
    }
  }, [login]);

  return (
    <LeafletMap
      {...other}
      viewport={viewSettings.viewport}
      length={3}
      onClick={onClick}
      onMousedown={onMouseDown}
      // onLocationfound={handleLocationFound}
      ref={ref}
      center={viewSettings.center}
      zoom={viewSettings.zoom}
      minZoom={zoomMin}
      maxZoom={zoomMax}
      zoomSnap={0}
      zoomDelta={0.75}
      attributionControl={false}
      onViewportChanged={handleViewportChange}
      maxBoundsViscosity={0.5}
      maxBounds={viewportBounds}
    >
      <TileLayer
        //url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        url={tilesUrl} //url={configuration().map.tilesUrl}
        //attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <AttributionControl position='topright' />
      {false && <LocateButton onClick={null} />}
      {textSwitchButton && <TextSwitchButton onClick={onSwitchTextBallon} />}
      {children ? children : ''}
      {circleMarker}
    </LeafletMap>
  );
};

Map.propTypes = {
  // viewport: (props, propName, componentName) => {
  //   if (!props.viewport && !props.center) {
  //     return new Error(
  //       `One of props 'viewport' or 'center' was not specified in '${componentName}'.`,
  //     );
  //   }
  //   if (props.viewport && !props.viewport.center) {
  //     return new Error(
  //       `The 'viewport' prop must be an object and have 'center : {lat: ..., lng: ...}' property.'${componentName}'.`,
  //     );
  //   }
  // },
  // center: (props, propName, componentName) => {
  //   if (!props.viewport && !props.center) {
  //     return new Error(
  //       `One of props 'viewport' or 'center' was not specified in '${componentName}'.`,
  //     );
  //   }
  //   if (props.center && (!props.center.lat || !props.center.lng)) {
  //     return new Error(
  //       `The 'center' prop must be an object and have both 'lng' and 'lat' properties.'${componentName}'.`,
  //     );
  //   }
  // },
  viewport: PropTypes.object,
  bounds: PropTypes.array,
  center: PropTypes.object,
  zoom: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  onClick: PropTypes.func,
  onMoveStart: PropTypes.func,
  mapRef: PropTypes.object,
  userLocation: PropTypes.object,
  onLocationFound: PropTypes.func,
  onMouseDown: PropTypes.func,
  onSwitchTextBallon: PropTypes.func,
  textSwitchButton: PropTypes.bool,
};

export default Map;
