import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import configuration from '../configuration';

const dateLocale = configuration().locale.date;

const useStyles = makeStyles(theme => ({
  name: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  nameTypograpy: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  period: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  property: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  propsContainer: {
    paddingBottom: theme.spacing(1),
  },
}));

const MomentInfoBasic = ({
  name,
  start,
  end,
  primaryData = [],
  secondaryData = [],
  subheaderData = [],
  orientation = 'horizontal',
}) => {
  const classes = useStyles();
  const gridXs = orientation === 'vertical' ? 12 : 6;

  const subheader = React.useMemo(() => {
    return subheaderData.map((item, index) => {
      return (
        <Grid key={index} item xs={12} className={classes.period}>
          <Typography
            color='textSecondary'
            variant='body2'
            className={classes.nameTypograpy}
            component='span'
          >
            {`${item.label}: `}
          </Typography>
          <Typography
            variant='body2'
            className={classes.nameTypograpy}
            component='span'
          >
            {item.value}
          </Typography>
        </Grid>
      );
    });
  }, [classes.nameTypograpy, classes.period, subheaderData]);

  const primaryComponents = primaryData.map((item, index) => {
    return (
      <Grid key={index} item xs={gridXs} className={classes.property}>
        <Typography color='textSecondary' variant='body2'>
          {item.label}:
        </Typography>
        <Typography variant='body2'>{item.value}</Typography>
      </Grid>
    );
  });

  const secondaryComponents = secondaryData.map((item, index) => {
    return (
      <Grid key={index} item sm={gridXs} className={classes.property}>
        <Typography color='textSecondary' variant='body2'>
          {item.label}
        </Typography>
        <Typography variant='body2'>{item.value}</Typography>
      </Grid>
    );
  });

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.name}>
          <Typography variant='body1' className={classes.nameTypograpy}>
            {name}
          </Typography>
        </Grid>
        <Grid container>{subheader}</Grid>
        <Hidden xsDown>
          <Grid item xs={12} className={classes.period}>
            <Typography color='textSecondary' variant='body2'>
              {new Date(start).toLocaleString(dateLocale)} -{' '}
              {new Date(end).toLocaleString(dateLocale)}
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
      <Divider variant='middle' />
      <Grid container className={classes.propsContainer}>
        {primaryComponents}
        <Hidden xsDown>{secondaryComponents}</Hidden>
      </Grid>
    </React.Fragment>
  );
};

MomentInfoBasic.propTypes = {
  name: PropTypes.string,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
  primaryData: PropTypes.array,
  secondaryData: PropTypes.array,
  subheaderData: PropTypes.array,
  orientation: PropTypes.string,
};

export default MomentInfoBasic;
