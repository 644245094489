import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const DialogBase = ({
  children,
  open,
  title,
  disableApply,
  handleClose,
  handleCancel,
  handleApply,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant='outlined'>
            Откажи
          </Button>
          <Button
            onClick={handleApply}
            variant='contained'
            color='primary'
            disabled={disableApply}
          >
            Заяви
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DialogBase.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disableApply: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  handleApply: PropTypes.func,
};

export default DialogBase;
