import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import AlarmFooter from './Alarm/AlarmFooter';
import AlarmHeader from './Alarm/AlarmHeader';
import AlarmList from './Alarm/AlarmList';
import SkeletonAlarmList from './SkeletonAlarmList';
import { debounce } from '../common';
import { updateAlarm } from '../actions/index';
import server from '../restApi';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  alarmList: {
    flexGrow: 1,
    // overflow: 'scroll',
    minHeight: 0,
    display: 'flex',
  },
  listPaper: {
    //marginBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  footerPaper: {
    marginTop: '20px',
  },
});

const AlarmView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const alarmList = useSelector(state => state.alarm.list);
  const object = useSelector(state => state.alarm.object);
  const loading = useSelector(state => state.alarm.ready) === false;
  const { addToast } = useToasts();
  const name = object.name;
  const [editMode, setEditMode] = React.useState(false);
  const errorMessage = 'Възникна грешка, промените ви не бяха отразени';
  //  const [list, setList] = React.useState(alarmList.slice());

  // React.useEffect(() => {
  //   setList(alarmList);
  // }, [alarmList]);
  const onLockChange = React.useCallback(lock => {
    setEditMode(lock);
  }, []);

  const setOnChange = React.useCallback(
    data => {
      const list = alarmList.slice();
      const index = list.findIndex(item => item.id === data.id);
      if (0 <= index) {
        server
          .updateAlarmAsync(data)
          .then(resp => {
            list[index] = resp.data;
            dispatch(updateAlarm(list));
          })
          .catch(() => {
            addToast(errorMessage, {
              appearance: 'error',
              autoDismiss: true,
            });
          });
      }
    },
    [addToast, alarmList, dispatch],
  );

  const onChangeDebounce = React.useCallback(debounce(setOnChange, 500));

  const onChange = React.useCallback(
    (oldData, newData) => {
      if (newData.type !== oldData.type) {
        setOnChange(newData);
      } else {
        onChangeDebounce(newData);
      }
    },
    [setOnChange, onChangeDebounce],
  );

  const onAddItem = React.useCallback(() => {
    const list = alarmList.slice();
    const data = {
      name: 'Нова аларма',
      type: 'distance',
    };
    // dispatch(updateAlarm(list));
    server
      .createAlarmAsync(object.id, data.name, data.type)
      .then(resp => {
        list.push(resp.data);
        dispatch(updateAlarm(list));
      })
      .catch(() => {
        addToast(errorMessage, {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  }, [addToast, alarmList, dispatch, object.id]);

  const onDeleteItem = React.useCallback(
    id => {
      const list = alarmList.slice();
      const index = list.findIndex(item => item.id === id);
      if (0 <= index) {
        server
          .deleteAlarmAsync(id)
          .then(() => {
            list.splice(index, 1);
            dispatch(updateAlarm(list));
          })
          .catch(() => {
            addToast(errorMessage, {
              appearance: 'error',
              autoDismiss: true,
            });
          });
      }
    },
    [addToast, alarmList, dispatch],
  );

  const onError = React.useCallback(
    msg => {
      addToast(msg, {
        appearance: 'error',
        autoDismiss: true,
      });
    },
    [addToast],
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.alarmList}>
          <Paper className={classes.listPaper} elevation={3}>
            <AlarmHeader
              text={name}
              onLockChange={onLockChange}
              disableLock={loading}
            />
            {loading ? (
              <SkeletonAlarmList />
            ) : (
              <AlarmList
                list={alarmList}
                onChange={onChange}
                onDelete={onDeleteItem}
                onError={onError}
                editMode={editMode}
              />
            )}
          </Paper>
        </div>
        <div>
          {editMode && (
            <Paper className={classes.footerPaper} elevation={3}>
              <AlarmFooter onAddItem={onAddItem} />
            </Paper>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlarmView;
