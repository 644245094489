import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useUpdate } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  // root: {
  //   '& div': {
  //     paddingTope: theme.spacing(2),
  //   },
  // },
  leftColumn: {
    [theme.breakpoints.up("sm")]: {
      textAlign: 'right',
    },
    paddingRight: theme.spacing(2),
  },
  singleColumn: {
    textAlign: 'center',
  },
  buttonsBox: {
    padding: '16px',
  },
  buttons: {
    padding: '8px',
  }
}));

const SettingsBox = ({ data = { }, editMode, onChange, onClose, onCancel }) => {
  const classes = useStyles();
  const [dataState, setDataState] = React.useState(data);
  const [stateChanged, setStateChanged] = React.useState(false);
//  const [emailErrorText, setEmailErrorText] = React.useState(null);
//  const wrongMailFormatError = 'Има грешка в изписването на пощата ви!';
  const saveCloseButtonText = React.useMemo(() => {
    if(stateChanged){
       return "Запази";
    }else{
       return "Назад"
    }
  }, [stateChanged]);

  useUpdate(() => {
    setDataState(data);
    setStateChanged(false);
  }, [data]);

//   const validateEmail = email => {
//     return /^[\w-.]+@[\w-]+\.+[\w-]{2,4}$/g.test(email);
//   };

  const handleChange = data => {
    setDataState(data);
    setStateChanged(true);
  }

  const handleSaveClose = () => {
    if(stateChanged){
        onChange && onChange(dataState);
        setStateChanged(false);
    }else{
        onClose && onClose();
    }
  }

  const handleCancel = () => {
    onCancel && onCancel();
  }

  const onNameChange = ev => {
    const value = ev.currentTarget.value;
    // if (validateEmail(value)) {
    //   onChange(Object.assign({}, data, { email: value }));
    //   setEmailErrorText(null);
    // } else {
    //   setEmailErrorText(wrongMailFormatError);
    // }
    handleChange({ ...dataState, name: value });
  };

  const onBrandChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, brand: value });
  };

  const onModelChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, model: value });
  };

  const onYearChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, year: value });
  };

  const onPlateNumberChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, plateNumber: value });
  };

  const onFuelTankChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, fuelTank: value });
  };

  const onCounterChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, counter: value });
  };

  const onNotesChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, notes: value });
  };

  const onConsumptionKMChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, consumption: {...dataState.consumption, litersPerKm: value } });
  };

  const onConsumptionHourChange = ev => {
    const value = ev.currentTarget.value;
    handleChange({ ...dataState, consumption: {...dataState.consumption, litersPerHour: value } });
  };

  return (
    <Grid
      container
      // justify='center'
      // alignItems='center'
      // direction='column'
      //style={{ minHeight: '100%' }}
    >
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Име:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.name != null ? dataState.name : ''}
            name={'objectName'}
            //helperText={emailErrorText}
            //inputProps={{ minLength: 1 }}
            onChange={onNameChange}
            disabled={editMode === false}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Марка:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.brand != null ? dataState.brand : ''}
            name={'brand'}
            //helperText={emailErrorText}
            //inputProps={{ minLength: 1 }}
            onChange={onBrandChange}
            disabled={editMode === false}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Модел:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.model != null ? dataState.model : ''}
            name={'model'}
            //helperText={emailErrorText}
            //inputProps={{ minLength: 1 }}
            onChange={onModelChange}
            disabled={editMode === false}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Година:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.year != null ? dataState.year : ''}
            name={'year'}
            type='number'
            //helperText={emailErrorText}
            inputProps={{ min: 1900, max: 2100 }}
            onChange={onYearChange}
            disabled={editMode === false}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Номер:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.plateNumber != null ? dataState.plateNumber : ''}
            name={'plateNumber'}
            //helperText={emailErrorText}
            //inputProps={{ minLength: 1 }}
            onChange={onPlateNumberChange}
            disabled={editMode === false}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Резервоар:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.fuelTank != null ? dataState.fuelTank : ''}
            name={'fuelTank'}
            type='number'
            //helperText={emailErrorText}
            inputProps={{ min: 0 }}
            onChange={onFuelTankChange}
            disabled={editMode === false}
            InputProps={{
                endAdornment: <InputAdornment position="end">литра</InputAdornment>,
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Километраж:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.counter != null ? dataState.counter : ''}
            name={'counter'}
            type='number'
            //helperText={emailErrorText}
            inputProps={{ min: 0 }}
            onChange={onCounterChange}
            disabled={editMode === false}
            InputProps={{
                endAdornment: <InputAdornment position="end">км</InputAdornment>,
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Разход Км:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.consumption && (dataState.consumption.litersPerKm != null ? dataState.consumption.litersPerKm : '')}
            name={'consumption-km'}
            type={'number'}
            //helperText={emailErrorText}
            inputProps={{ min: 0 }}
            onChange={onConsumptionKMChange}
            disabled={editMode === false}
            InputProps={{
                endAdornment: <InputAdornment position="end">литра/100км</InputAdornment>,
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Разход Час:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.consumption && (dataState.consumption.litersPerHour != null ? dataState.consumption.litersPerHour : '')}
            name={'consumption-hour'}
            type='number'
            inputProps={{ min: 0 }}
            //helperText={emailErrorText}
            //inputProps={{ minLength: 1 }}
            onChange={onConsumptionHourChange}
            disabled={editMode === false}
            InputProps={{
                endAdornment: <InputAdornment position="end">литра/час</InputAdornment>,
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.leftColumn}>
        <Typography variant='h6'>Бележки:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          <TextField
            // error={emailErrorText !== null}
            value={dataState.notes != null ? dataState.notes : ''}
            name={'notes'}
            multiline
            //helperText={emailErrorText}
            //inputProps={{ minLength: 1 }}
            onChange={onNotesChange}
            disabled={editMode === false}
          />
        </Typography>
      </Grid>
      <Grid item container xs={12} className={classes.buttonsBox}>
        <Grid item xs={6} className={`${classes.leftColumn} ${classes.buttons}`}>
            {editMode !== false && <Button variant='outlined' onClick={handleCancel}>Прекрати</Button>}
        </Grid>
        <Grid item xs={6} className={classes.buttons}>
            <Button variant='contained' onClick={handleSaveClose}>{saveCloseButtonText}</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

SettingsBox.propTypes = {
  data: PropTypes.object, 
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SettingsBox;
