import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    alignItems: 'center',
    marginBottom: '2px',
    height: '97px',
    display: 'block',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  inputProps: {
    'aria-label': 'filter objects',
    width: '80%',
  },
  iconButton: {
    padding: 10,
  },
  iconButtonClose: {
    position: 'absolute',
    right: 0,
  },
}));

const ObjectFilter = ({
  onFilter,
  onCheckbox,
  onDateOrder,
  onNameOrder,
  checkAll,
  order = {},
  filterText,
}) => {
  const classes = useStyles();
  const inputEl = React.useRef();
  const { dateAsc, nameAsc } = order;
  const byDateAsc = !!dateAsc;
  const byNameAsc = !!nameAsc;
  const handleCheckbox = React.useCallback(
    (ev, checked) => {
      onCheckbox(checked);
    },
    [onCheckbox],
  );
  const handleDateOrder = React.useCallback(() => {
    const asc = !byDateAsc;
    onDateOrder(asc);
  }, [byDateAsc, onDateOrder]);
  const handleNameOrder = React.useCallback(() => {
    const asc = !byNameAsc;
    onNameOrder(asc);
  }, [byNameAsc, onNameOrder]);

  const arrowUp = '\u25B2';
  const arrowDown = '\u25BC';

  //const onFilterDebounced = debounce(onFilter, 500);
  const onFilterDebounced = onFilter;
  const onClearInput = ev => {
    ev.preventDefault();
    inputEl.current.value = '';
    onFilterDebounced('');
  };

  const onChangeInput = () => {
    const input = inputEl.current;
    const value = input.value.trim();
    onFilterDebounced(value);
  };

  return (
    <div>
      <Paper component='form' className={classes.root} onSubmit={onClearInput}>
        <div>
          <IconButton className={classes.iconButton} disabled aria-label='menu'>
            <SearchIcon />
          </IconButton>
          <InputBase
            size={'small'}
            className={classes.input}
            placeholder='Filter'
            onChange={onChangeInput}
            inputProps={{
              ref: inputEl,
              className: classes.inputProps,
            }}
            value={filterText}
          />
          <IconButton
            type='submit'
            className={classes.iconButtonClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <div>
          <Checkbox onChange={handleCheckbox} checked={checkAll} />
          <Button onClick={handleDateOrder}>
            По дата {byDateAsc ? arrowDown : arrowUp}
          </Button>
          <Button onClick={handleNameOrder}>
            По име {byNameAsc ? arrowDown : arrowUp}
          </Button>
        </div>
      </Paper>
    </div>
  );
};

ObjectFilter.propTypes = {
  onFilter: PropTypes.func,
  listData: PropTypes.array,
  onCheckbox: PropTypes.func,
  onDateOrder: PropTypes.func,
  onNameOrder: PropTypes.func,
  checkAll: PropTypes.bool,
  order: PropTypes.object,
  filterText: PropTypes.string,
};

export default ObjectFilter;
