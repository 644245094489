import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import Box from '@material-ui/core/Box';
import InfoChartMap from './InfoChartMap';
import NoDataDialog from './NoDataDialog';
import { showView, reportReset } from '../actions/index';
//import PropTypes from 'prop-types';

const FuelView = () => {
  const dispatch = useDispatch();
  const onConfirm = () => {
    dispatch(showView('objects'));
    dispatch(reportReset('fuel'));
  };

  const fuelReport = useSelector(state => state.report.fuel);
  const errorDialog = fuelReport.status === 'nodata';
  const reportType =
    fuelReport.status === 'nodata' || fuelReport.status === 'loading'
      ? 'loading'
      : 'fuel';

  return (
    <React.Fragment>
      {errorDialog && (
        <NoDataDialog
          open={true}
          onClose={onConfirm}
          objectName={fuelReport.objectName}
          startDate={fuelReport.start}
          endDate={fuelReport.end}
        />
      )}
      <InfoChartMap type={reportType} object={fuelReport} />
    </React.Fragment>
  );
};
// ViewStack.propTypes = {
//   views: PropTypes.object,
//   show: PropTypes.string,
// };

export default FuelView;
