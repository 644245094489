import React from 'react';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
//import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateTimePicker from './DateTimePicker';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DialogBase from './DialogBase';
import { useTranslator } from '../Translator';

const DialogPeriodBase = ({
  children,
  open,
  title,
  disableApply,
  onClose,
  startDate = moment(),
  endDate = moment().startOf('day'),
  onStartChange,
  onEndChange,
}) => {
  const translator = useTranslator();
  const today = moment();
  // const yesterday = moment()
  //   // .subtract(1, 'days')
  //   .startOf('day');

  // const [startDate, setStartDate] = React.useState(yesterday);
  // const [endDate, setEndDate] = React.useState(today);
  const [error, setError] = React.useState(false);

  const minDate = React.useMemo(() => {
    return startDate;
  }, [startDate]);
  const maxDate = React.useMemo(() => {
    const date = moment(startDate)
      .add(31, 'days')
      .endOf('day');
    return date.isAfter(today) ? today : date;
  }, [startDate, today]);

  const handleApply = React.useCallback(() => {
    onClose({
      start: startDate,
      end: endDate,
    });
  }, [endDate, onClose, startDate]);

  const handleCancel = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <div>
      <DialogBase
        title={title}
        open={open}
        disableApply={disableApply || error}
        handleApply={handleApply}
        handleCancel={handleCancel}
        handleClose={handleClose}
      >
        {children}
        <DialogContentText>Изберете период на справката:</DialogContentText>
        <Box>
          <Grid container alignContent='center'>
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                locale={translator.locale}
                // locale={'bg'}
                label='Начало'
                value={startDate}
                onChange={onStartChange}
                maxDate={today}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                locale={translator.locale}
                // locale={'bg'}
                label='Край'
                value={endDate}
                minDate={minDate}
                minDateMessage={
                  'Крайната дата трябва да бъде по-голяма от началната.'
                }
                onChange={onEndChange}
                maxDate={maxDate}
                maxDateMessage={
                  'Интервала на справката не може да бъде по-голям от месец.'
                }
                onError={message => {
                  setError(message !== '');
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogBase>
    </div>
  );
};

DialogPeriodBase.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disableApply: PropTypes.bool,
  onClose: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
};

export default DialogPeriodBase;
