import server from './restApi';

export default class Authentication {
  // constructor() {
  //   //this.csrfPair = {};
  // }

  login(username, password) {
    return server
      .loginAsync(username, password)
      .then(data => {
        if (data.ok) {
          // server.setAuthTokens({
          //   // username: data.data.username,
          //   token: data.data.jwt,
          // });
          // this.csrfPair = {
          //   param: data.csrfParam,
          //   token: data.csrfToken,
          // };
          this.logged = true;
        }
        return { success: this.logged, data: data };
      })
      .catch(() => {
        throw new Error('Грешка при връзка със сървъра');
      });
  }

  logout() {
    return server.logoutAsync().then(() => {
      this.logged = false;
    });
  }

  // isAuthenticated() {
  //   return this.logged;
  // }
  //
  // setAuthenticated(loggedIn) {
  //   this.logged = loggedIn;
  // }
  //
  // getExpirationTimestamp() {
  //   const tokenString = server.getAuthTokens();
  //   if (tokenString) {
  //     const mainTokenString = tokenString.split('.')[1];
  //     const mainTokenData = JSON.parse(window.atob(mainTokenString));
  //     return mainTokenData.exp;
  //   }
  // }
}
