import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundColor: 'rgba(0,0,0,0.05)',
      borderRadius: '5px',
      padding: '5px',
      fontFamily: theme.typography.fontFamily,
      fontSize: 'inherit',
      borderColor: 'grey',
      borderWidth: '0px',
    },
  };
});

const NotesInput = () => {
  const classes = useStyles();
  const [notes, setNotes] = React.useState();
  const onChangeInput = ev => {
    setNotes(ev.target.value);
  };

  return (
    <textarea
      className={classes.root}
      onChange={onChangeInput}
      value={notes}
    ></textarea>
  );
};

export default NotesInput;
