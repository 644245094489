import React from 'react';
import { useSelector } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import Main from './Main';
import Login from './Login';
import { Redirect } from 'react-router-dom';
import Authentication from './Authentication';
import ErrorBoundary from './components/ErrorBoundary';
// import { useDispatch } from 'react-redux';
// import { showView } from './actions/index';

// import restApi from './restApi';
const auth = new Authentication();

export const MainRoute = () => {
  const login = useSelector(state => state.login);
  const [redirect, setRedirect] = React.useState(!login.loggedIn);
  const loginPath = '/login';

  React.useEffect(() => {
    setRedirect(!login.loggedIn);
  }, [login]);

  return (
    <ToastProvider>
      {redirect && <Redirect to={loginPath} />}
      <ErrorBoundary>{[<Main key={0} auth={auth} />]}</ErrorBoundary>
    </ToastProvider>
  );
};

export const LoginRoute = () => {
  // const dispatch = useDispatch();
  const login = useSelector(state => state.login);
  const [redirect, setRedirect] = React.useState(login.loggedIn);
  const url = '/';

  React.useEffect(() => {
    setRedirect(login.loggedIn);
  }, [login]);

  return (
    <React.Fragment>
      {redirect && <Redirect to={url} />}
      <Login auth={auth} />
    </React.Fragment>
  );
};
