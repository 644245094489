const initState = {
    selectedDayData: undefined,
    disableReport : true,
    data: undefined,
    queryParams: undefined,
    status: 'loading',
};

const prepareCoverageReportData = (state, object, queryParams) => {
  const { geometry: { coordinates } } = object;

  if(coordinates.length < 2){
    return { status: 'nodata', disableReport: state.disableReport, queryParams: queryParams };
  }

  const dataProcessedTime = { 
    ...object,
    properties: { 
      ...object.properties, 
      created: object.properties.created.map(item => new Date(item)) 
  }}

  return {
    ...state, 
    data: dataProcessedTime, 
    queryParams: queryParams,
    status: 'ready',
  }
}

const getResetState = (action, state) => {
  if(action.report === 'coverage'){
    return { ...initState, disableReport: state.disableReport }
  }else{
    return state;
  }
}

export const coverageReportReducer = (state = { ...initState }, action) => {
  const type = action.type;
  const data = action.data;

  if(type === 'report.update' && action.report !== 'coverage'){
    return state;
  }

  switch (type) {
    case 'report.coverage.selectedDay':
      state = {...state, selectedDayData: { from: data.from, to: data.to, day: data.day }};
      break;
    case 'report.coverage.enable':
      state = {...state, disableReport: false };
      break;
    case 'report.update':
      state = prepareCoverageReportData(state, data, action.queryParams);
      break;
    case 'report.coverage.changeDay':
      state = {...state,  selectedDayData: action.data };
      break;
    case 'report.reset':
      state = getResetState(action, state);
      break;
    case 'data.deinit':
      state = { ...initState };
      break;
    default:
      return state;
  }
  return state;
};
