const reducerName = 'dialog.filter';

const defaultState = {
  dateAsc: false,
  nameAsc: true,
  text: '',
};

const initState = JSON.parse(localStorage.getItem(reducerName)) || {
  ...defaultState,
};

export const filterReducer = (state = initState, action) => {
  let type = action.type;
  let save = true;
  switch (type) {
    case 'filter.nameAsc':
      state = {
        ...state,
        nameAsc: action.data,
      };
      break;
    case 'filter.dateAsc':
      state = {
        ...state,
        dateAsc: action.data,
      };
      break;
    case 'filter.text':
      state = {
        ...state,
        text: action.data,
      };
      break;
    default:
      save = false;
      return state;
  }

  if (save) {
    localStorage.setItem(reducerName, JSON.stringify(state));
  }
  return state;
};
