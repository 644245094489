import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  // root: {
  //   '& div': {
  //     paddingTope: theme.spacing(2),
  //   },
  // },
  leftColumn: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  singleColumn: {
    textAlign: 'center',
    padding: '32px',
  },
  // buttonsBox: {
  //   paddingTop: 24,
  // }
}));

const ObjectsSelect = ({ objectList = [], onSelect, object }) => {
  const classes = useStyles();
  const [selectedObject, setSelectedObject] = React.useState({id: ''});

//   const validateEmail = email => {
//     return /^[\w-.]+@[\w-]+\.+[\w-]{2,4}$/g.test(email);
//   };

  React.useEffect(() => {
    if(object && objectList.length){
      setSelectedObject(object);
    }
  }, [object, objectList])

  const items = objectList.map(item => (
    <MenuItem value={item.id} key={item.id}>
        {item.name}
    </MenuItem>
  ))

  const handleSelect = data => {
    onSelect(selectedObject);
  }

  const handleChange = ev => {
    setSelectedObject(objectList.find(item => item.id === ev.target.value));
  }

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      style={{ minHeight: '100%' }}
    >
        <Grid item container xs={12}>
            <Grid item xs={12} sm={6} className={classes.leftColumn}>
                <Typography variant='h6'>Обекти:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Select onChange={handleChange} value={selectedObject.id}>
                    {items}
                </Select>
            </Grid>            
        </Grid>
        <Grid item xs={12} className={classes.singleColumn}>
            <Button variant='contained' disabled={selectedObject === null} onClick={handleSelect}>Напред</Button>
        </Grid>
    </Grid>
  );
};

ObjectsSelect.propTypes = {
  onSelect: PropTypes.func,
  objectList: PropTypes.array,
  object: PropTypes.object,
};

export default ObjectsSelect;
