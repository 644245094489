import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import NotesInput from './NotesInput';

const useStyles = makeStyles({
  root: {
    '& button': {
      background: 'none!important',
      border: 'none',
      padding: '0!important',
      color: '#0000cd',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    overflow: 'scroll',
  },
});

const StyledTableCellWork = withStyles({
  root: {
    padding: '4px',
    color: 'green',
  },
})(TableCell);

const StyledTableCellRest = withStyles({
  root: {
    padding: '4px',
    color: 'grey',
  },
})(TableCell);

const StyledTableCellOvertime = withStyles({
  root: {
    padding: '4px',
    color: 'grey',
  },
})(TableCell);

const StyledTableCell = withStyles({
  root: {
    padding: '4px',
  },
})(TableCell);

const AddressButton = ({ address, onclick, index }) => {
  const onclickPrepare = React.useCallback(
    ev => {
      ev.preventDefault();
      onclick && onclick(index);
    },
    [index, onclick],
  );
  return <button onClick={onclickPrepare}>{address}</button>;
};

AddressButton.propTypes = {
  address: PropTypes.string,
  onclick: PropTypes.func,
  index: PropTypes.number,
};

const hasOvertime = overtime => {
  return overtime !== '00:00:00';
};

const SkeletonTableRow = () => {
  return (
    <TableRow>
      <StyledTableCell component='th' scope='row'>
        <Skeleton variant='text' />
      </StyledTableCell>
      <StyledTableCellRest align='right'>
        <Skeleton variant='text' />
      </StyledTableCellRest>
      <StyledTableCellRest align='right'>
        <Skeleton variant='text' />
      </StyledTableCellRest>
      <StyledTableCellWork align='right'>
        <Skeleton variant='text' />
      </StyledTableCellWork>
      <StyledTableCellWork align='right'>
        <Skeleton variant='text' />
      </StyledTableCellWork>
      <StyledTableCellWork align='right'>
        <Skeleton variant='text' />
      </StyledTableCellWork>
      <StyledTableCell align='right'>
        <Skeleton variant='text' />
      </StyledTableCell>
    </TableRow>
  );
};

const PrintTableRowDaySummary = ({
  style,
  day,
  restHours,
  workHours,
  distance,
  overtime,
  options,
}) => {
  const { hasNotesColumn, hasWorktime } = options;
  return (
    <TableRow style={style}>
      <StyledTableCell component='th' scope='row'>
        {day}
      </StyledTableCell>
      <StyledTableCellRest align='right'></StyledTableCellRest>
      <StyledTableCellRest align='right'>{restHours}</StyledTableCellRest>
      <StyledTableCellWork align='right'></StyledTableCellWork>
      <StyledTableCellWork align='right'>{workHours}</StyledTableCellWork>
      <StyledTableCellWork align='right'>{distance}</StyledTableCellWork>
      {hasWorktime && (
        <StyledTableCellOvertime align='right'>
          {hasOvertime(overtime) && overtime}
        </StyledTableCellOvertime>
      )}
      <StyledTableCell align='right'></StyledTableCell>
      {hasNotesColumn && <StyledTableCell align='right'></StyledTableCell>}
    </TableRow>
  );
};

PrintTableRowDaySummary.propTypes = {
  style: PropTypes.object,
  restHours: PropTypes.string,
  workHours: PropTypes.string,
  distance: PropTypes.number,
  day: PropTypes.string,
  overtime: PropTypes.string,
  options: PropTypes.object,
};

const PrintTableRow = ({
  style,
  period,
  nextPeriod,
  addressList,
  onclick,
  options,
}) => {
  const { restHours, workHours, distance, rest, work, overtime } = period;
  const { hasNotesColumn, hasWorktime } = options;
  const beforeWorkAddress = addressList ? addressList[period.keyIndex] : '???';
  const afterWorkAddress =
    nextPeriod && (addressList ? addressList[nextPeriod.keyIndex] : '???');

  return (
    <React.Fragment>
      <TableRow style={style}>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
        <StyledTableCellRest align='right'>{rest}</StyledTableCellRest>
        <StyledTableCellRest align='right'>{restHours}</StyledTableCellRest>
        <StyledTableCellWork align='right'></StyledTableCellWork>
        <StyledTableCellWork align='right'></StyledTableCellWork>
        <StyledTableCellWork align='right'></StyledTableCellWork>
        {hasWorktime && (
          <StyledTableCellOvertime align='right'></StyledTableCellOvertime>
        )}
        <StyledTableCell align='right'>
          <AddressButton
            address={beforeWorkAddress}
            index={period.keyIndex}
            onclick={onclick}
          />
        </StyledTableCell>
        {hasNotesColumn && (
          <StyledTableCell>
            <NotesInput />
          </StyledTableCell>
        )}
      </TableRow>
      <TableRow style={style}>
        <StyledTableCell component='th' scope='row'></StyledTableCell>
        <StyledTableCellRest align='right'></StyledTableCellRest>
        <StyledTableCellRest align='right'></StyledTableCellRest>
        <StyledTableCellWork align='right'>{work}</StyledTableCellWork>
        <StyledTableCellWork align='right'>{workHours}</StyledTableCellWork>
        <StyledTableCellWork align='right'>{distance}</StyledTableCellWork>
        {hasWorktime && (
          <StyledTableCellOvertime align='right'>
            {hasOvertime(overtime) && overtime}
          </StyledTableCellOvertime>
        )}
        <StyledTableCell align='right'>
          {work && nextPeriod && (
            <AddressButton
              address={afterWorkAddress}
              index={nextPeriod.keyIndex}
              onclick={onclick}
            />
          )}
        </StyledTableCell>
        {hasNotesColumn && (
          <StyledTableCell>
            <NotesInput />
          </StyledTableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

PrintTableRow.propTypes = {
  style: PropTypes.object,
  period: PropTypes.shape({
    restHours: PropTypes.string,
    workHours: PropTypes.string,
    distance: PropTypes.number,
    rest: PropTypes.string,
    work: PropTypes.string,
    keyIndex: PropTypes.number,
    overtime: PropTypes.string,
  }),
  options: PropTypes.object,
  nextPeriod: PropTypes.shape({
    keyIndex: PropTypes.number,
  }),
  addressList: PropTypes.array,
  onclick: PropTypes.func,
};

const PrintReport = ({
  id,
  object,
  addressList,
  loading,
  onSelectItem,
  options = {},
}) => {
  const { hasNotesColumn, hasWorktime } = options;
  const classes = useStyles();
  const countSkeletonItems = 3;
  let tableBody;
  if (loading) {
    tableBody = Array.from(new Array(countSkeletonItems)).map((item, index) => {
      return <SkeletonTableRow key={index} />;
    });
  } else {
    tableBody = object.days.map((dayGroup, dayIndex) => {
      const { day, rest, work, dist, overtime } = dayGroup;
      const rows = dayGroup.periods.map(period => {
        const row = period;
        return (
          <PrintTableRow
            key={row.key}
            period={row}
            nextPeriod={row.nextPeriod}
            addressList={addressList}
            onclick={onSelectItem}
            overtime={row.overtime}
            options={options}
          />
        );
      });

      let firstRow = [
        <PrintTableRowDaySummary
          //style={{ borderStyle: 'solid' }}
          key={-dayIndex}
          restHours={rest}
          workHours={work}
          distance={dist}
          overtime={overtime}
          day={day}
          options={options}
        />,
      ];

      return firstRow.concat(rows);
    });
  }

  return (
    <TableContainer
      className={classes.root}
      id={id}
      component={Paper}
      style={{ maxHeight: '100%' }}
    >
      <Table size='small' aria-label='print report table' stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>Ден</StyledTableCell>
            <StyledTableCell align='right'>Период престой</StyledTableCell>
            <StyledTableCell align='right'>Престой</StyledTableCell>
            <StyledTableCell align='right'>Период работа</StyledTableCell>
            <StyledTableCell align='right'>Моточасове</StyledTableCell>
            <StyledTableCell align='right'>Пробег(км)</StyledTableCell>
            {hasWorktime && (
              <StyledTableCell align='right'>Изв.труд</StyledTableCell>
            )}
            <StyledTableCell align='right'>Населено Място</StyledTableCell>
            {hasNotesColumn && (
              <StyledTableCell align='right'>Бележки</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  );
};

PrintReport.propTypes = {
  object: PropTypes.object,
  loading: PropTypes.bool,
  addressList: PropTypes.array,
  onSelectItem: PropTypes.func,
  options: PropTypes.object,
  id: PropTypes.string,
};

export default PrintReport;
