import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  NotificationInfoIcon,
  NotificationSuccessIcon,
  NotificationWarningIcon,
  NotificationErrorIcon,
} from '../CustomIcons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
  title: {
    fontSize: '0.95em',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(1),
  },
  new: {
    flexShrink: 0,
    height: '10px',
    width: '10px',
    borderRadius: '5px',
    backgroundColor: 'white',
    alignSelf: 'center',
    margin: theme.spacing(2),
    boxShadow: 'rgba(256, 0, 0) 0px 0px 5px',
    borderColor: '#ffa3a3',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  text: {
    fontSize: '0.9em',
    '& > p': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

// import TableRow from '@material-ui/core/TableRow';
// import TableCell from '@material-ui/core/TableCell';

const useTypeStyles = makeStyles(() => ({
  success: {
    backgroundColor: 'rgb(227, 252, 239)',
    color: 'rgb(54, 179, 126)',
  },
  error: {
    backgroundColor: 'rgb(255, 235, 230)',
    color: 'rgb(191, 38, 0)',
  },
  warning: {
    backgroundColor: 'rgb(255, 250, 230)',
    color: 'rgb(255, 139, 0)',
  },
  info: {
    backgroundColor: 'white',
    color: 'rgb(80, 95, 121)',
  },
}));

const NotificationItem = ({ id, title, text, type, status, onClose }) => {
  const classes = useStyles();
  const typeClasses = useTypeStyles();
  const handleOnClose = React.useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  const icon = React.useMemo(() => {
    switch (type) {
      case 'success':
        return <NotificationSuccessIcon />;
      case 'error':
        return <NotificationErrorIcon />;
      case 'warning':
        return <NotificationWarningIcon />;
      case 'info':
        return <NotificationInfoIcon />;
      default:
        return <NotificationInfoIcon />;
    }
  }, [type]);

  return (
    <li>
      <div className={clsx(classes.root, typeClasses[type])}>
        <div>{icon}</div>
        <div className={classes.content}>
          <div className={classes.title}>
            <span>{title}</span>
          </div>
          <div className={classes.text}>
            <p>{text}</p>
          </div>
        </div>
        {status === 'unseen' && <div className={classes.new}></div>}
        <div className={classes.buttonContainer}>
          <IconButton aria-label='close' onClick={handleOnClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </li>
  );
};

NotificationItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  status: PropTypes.oneOf(['seen', 'unseen']),
  onClose: PropTypes.func,
};

export default NotificationItem;
