import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import InfoChartMap from './InfoChartMap';
import NoDataDialog from './NoDataDialog';
import { showView, reportReset } from '../actions/index';
import InfoChartMap from './InfoChartMap';

const CustomReportView = () => {
  const dispatch = useDispatch();
  const onConfirm = () => {
    dispatch(showView('objects'));
    dispatch(reportReset('custom-report'));
  };
  const customReport = useSelector(state => state.report['custom-report']);
  const errorDialog = customReport.status === 'nodata';
  const reportType =
    customReport.status === 'nodata' || customReport.status === 'loading'
      ? 'loading'
      : 'custom';

  return (
    <React.Fragment>
      {errorDialog && (
        <NoDataDialog
          open={true}
          onClose={onConfirm}
          objectName={customReport.objectName}
          startDate={customReport.start}
          endDate={customReport.end}
        />
      )}
      <InfoChartMap object={customReport} type={reportType} />
    </React.Fragment>
  );
};

export default CustomReportView;
