const reducerName = 'login.reducer';
const defaultState = {
  jwt: undefined,
  exp: undefined,
  loggedIn: false,
};

const initState = JSON.parse(localStorage.getItem(reducerName)) || {
  ...defaultState,
};

export const loginReducer = (state = { ...initState }, action) => {
  let type = action.type;
  let save = true;
  switch (type) {
    case 'login.jwt':
      state = {
        jwt: action.data.jwt,
        exp: action.data.exp,
        loggedIn: action.data.loggedIn,
      };
      break;
    case 'data.deinit':
      state = { ...defaultState };
      break;
    default:
      save = false;
      return state;
  }

  if (save) {
    localStorage.setItem(reducerName, JSON.stringify(state));
  }

  return state;
};
