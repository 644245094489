import React from 'react';
import PropTypes from 'prop-types';

function TranslatorBase(langCode) {
  this.lang = langCode;
  this.defaultLang = 'en';
  this.dictionary = this.dictionaryList[langCode];
  this.defaultDictionary = this.dictionaryList[this.defaultLang];
}

TranslatorBase.prototype.dictionaryList = {
  en: {
    speed: 'speed',
    kmh: 'km/h',
  },
  bg: {
    speed: 'скорост',
    kmh: 'км/ч',
  },
};

TranslatorBase.prototype.translate = function(keyWord) {
  let translation = this.dictionary[keyWord];
  if (!translation) {
    return this.defaultDictionary[keyWord];
  }
  return translation;
};

TranslatorBase.prototype.getLanguage = function() {
  return this.lang;
};

const TranslatorContext = React.createContext(new TranslatorBase('en'));

const TranslatorProvider = ({ children, locale }) => {
  const [context, setContext] = React.useState(new TranslatorBase('en'));
  React.useEffect(() => {
    const translator = new TranslatorBase(locale);
    // console.log('TranslatorProvider:', translator);
    setContext(translator);
  }, [locale]);
  return (
    <TranslatorContext.Provider value={context}>
      {children}
    </TranslatorContext.Provider>
  );
};

const createTranslatorHook = () => {
  return function useTranslator() {
    const context = React.useContext(TranslatorContext);
    // console.log('useTranslator:', context);
    if (!context) {
      throw new Error('Wrap the component with TranslatorProvider!');
    }
    return {
      locale: context.getLanguage(),
      translate: keyWord => context.translate(keyWord),
    };
  };
};

export const useTranslator = createTranslatorHook();

TranslatorProvider.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.node,
};

export default TranslatorProvider;
