import moment from 'moment';

const reducerName = 'dialog.reducer';
const today = moment();
const yesterday = moment().startOf('day');

const defaultState = {
  reportStart: yesterday,
  reportEnd: today,
  reportObject: null,
};

const initState = JSON.parse(localStorage.getItem(reducerName)) || {
  ...defaultState,
};

export const dialogReducer = (state = initState, action) => {
  let type = action.type;
  let save = false;
  switch (type) {
    case 'dialog.update.report':
      state = {
        ...state,
        reportStart: action.data.start,
        reportEnd: action.data.end,
        reportObject: action.data.object,
      };
      break;
    default:
      save = false;
      return state;
  }

  if (save) {
    localStorage.setItem(reducerName, JSON.stringify(state));
  }
  return state;
};
