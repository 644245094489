import React from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { debounce } from '../common';
import UserSettings from './UserSettings';
import UserViewNotification from './UserViewNotifications';
import NotificationLabeled from './NotificationLabeled';
import server from '../restApi';
import {
  deleteNotification,
  markNotification,
  userEditMode,
  userInit,
  reportOptionsUpdate,
  userExpand,
  objectSettings,
  userSelected,
  userObject,
  updateNameData,
} from '../actions/index';
import { ExpandedSectionEnum, ObjectSettingsStateEnum } from '../reducers/user';
import ObjectSettings from './ObjectSettings/index';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  noNotifications: {
    textAlign: 'center',
    width: '100%',
  },
  accordionItem: {
    width: '100%',
  },
  accordinItemNotifications: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    //workaround
    '& div.MuiCollapse-container': {
      display: 'flex',
      width: '100%',
    },
    //workaround
    '& div.MuiCollapse-wrapper': {
      display: 'flex',
      width: '100%',
    },
    //workaround
    '& div.MuiCollapse-wrapperInner': {
      display: 'flex',
      width: '100%',
    },
    //workaround
    '& div#panel-notifications-content': {
      display: 'flex',
      width: '100%',
    },
  },
  accordionDetailsNotifications: {
    display: 'flex',
    width: '100%',
  },
  accordionSummary: {
    display: 'flex',
    width: '100%',
  },
  settingsLabel: {
    flexGrow: 1,
  },
}));

const UserView = ({ onLogout }) => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userReducer = useSelector(state => state.user);
  const listData = useSelector(state => state.data.listData);
  const newNotificationsCount = useSelector(
    state => state.notification.countNew,
  );
  const notificationList = useSelector(state => state.notification.list);
  const expanded = userReducer.expandedPanel;

  const handleChangeNotifications = (event, newExpanded) => {
    if(newExpanded){
      dispatch(userExpand(ExpandedSectionEnum.Notifications));
    }else{
      dispatch(userExpand(null));
    }
  };

  const handleChangeSettings = (event, newExpanded) => {
    if(newExpanded){
      dispatch(userExpand(ExpandedSectionEnum.General));
    }else{
      dispatch(userExpand(null));
    }
  };

  const handleChangeObjects = (event, newExpanded) => {
    if(newExpanded){
      dispatch(userExpand(ExpandedSectionEnum.Object));
    }else{
      dispatch(userExpand(null));
    }
  };
 
  const handleSettingsSelectObject = object => {
    server.getObjectSettingsAsyn(object.id).then(result => {
      dispatch(userSelected(object));
      dispatch(userObject(result.data));
      dispatch(objectSettings(ObjectSettingsStateEnum.Show));
    }).catch(()=> {
      addToast('Заявката не може да бъде изпълнена.', {
        appearance: 'error',
      });
    });
  }

  const errorMessage = 'Промените ви не бяха отразени, няма връзка със сървъра';

  const handleOnDelete = React.useCallback(
    id => {
      server
        .deleteNotificationAsync(id)
        .then(() => {
          dispatch(deleteNotification(id));
        })
        .catch(() => {
          addToast(errorMessage, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    },
    [addToast, dispatch],
  );

  const onLockOpen = e => {
    dispatch(userEditMode({user: true, settings: false}));
    e.stopPropagation();
  };

  const onLockClose = e => {
    e.stopPropagation();
    dispatch(userEditMode({user: false, settings: false}));
  };

  const onObjectLockOpen = e => {
    dispatch(userEditMode({user: false, settings: true}));
    e.stopPropagation();
  };

  const onObjectLockClose = e => {
    e.stopPropagation();
    dispatch(userEditMode({user: false, settings: false}));
  }

  const handleOnMarkAsRead = React.useCallback(
    idList => {
      server
        .markNotificationsAsReadAsync(idList)
        .then(() => {
          dispatch(markNotification());
        })
        .catch(() => {
          addToast(errorMessage, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    },
    [addToast, dispatch],
  );

  const handleOnChangeSettings = debounce(data => {
    server.updateUserDataAsync(data);
    if (data.workStart && data.workEnd) {
      dispatch(
        reportOptionsUpdate({
          workStart: data.workStart,
          workEnd: data.workEnd,
        }),
      );
    }
    dispatch(userInit(data));
  }, 1500);

  const handleObjectSettingsState = state => {
    dispatch(objectSettings(state));
  }

  const handleUpdateSettings = (id, data) => {
    server.updateObjectSettingsAsyn(id, data).then(() => {
      dispatch(updateNameData(id, data.name));
    }).catch(()=>{
      addToast('Заявката не може да бъде изпълнена.', {
        appearance: 'error',
      });
    })
  }

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordinItemNotifications}
        expanded={expanded === ExpandedSectionEnum.Notifications}
        onChange={handleChangeNotifications}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel-notifications-content'
          id='panel-notifications-header'
        >
          <NotificationLabeled
            label={<Typography>Известия</Typography>}
            notificationsCount={newNotificationsCount}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsNotifications}>
          {notificationList.length > 0 ? (
            <UserViewNotification
              list={notificationList}
              onDelete={handleOnDelete}
              onMarkAsRead={handleOnMarkAsRead}
            />
          ) : (
            <Typography className={classes.noNotifications}>
              Няма известия
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordionItem}
        expanded={expanded === ExpandedSectionEnum.General}
        onChange={handleChangeSettings}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel-settings-content'
          id='panel-settings-header'
        >
          <div className={classes.accordionSummary}>
            <div className={classes.settingsLabel}>
              <Typography>Управление</Typography>
            </div>
            <div>
              {userReducer.editSettingsOn ? (
                <LockOpenIcon onClick={onLockClose} />
              ) : (
                <LockIcon onClick={onLockOpen} />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <UserSettings
            onLogout={onLogout}
            data={userReducer}
            editMode={userReducer.editSettingsOn}
            onChange={handleOnChangeSettings}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordionItem}
        expanded={expanded === ExpandedSectionEnum.Object}
        onChange={handleChangeObjects}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel-object-content'
          id='panel-object-header'
        >
          <div className={classes.accordionSummary}>
            <div className={classes.settingsLabel}>
              <Typography>Обекти</Typography>
            </div>
            <div>
              {userReducer.editObjectSettingsOn ? (
                <LockOpenIcon onClick={onObjectLockClose} />
              ) : (
                <LockIcon onClick={onObjectLockOpen} />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ObjectSettings 
            editMode={userReducer.editObjectSettingsOn}
            settings={userReducer.objectSettings}
            objectList={listData.map(item => item.properties)}
            object={userReducer.selectedObject}
            state={userReducer.objectSettingsState}
            onUpdateSettings={handleUpdateSettings}
            onChangeState={handleObjectSettingsState}
            onSelectObject={handleSettingsSelectObject}
          />
        </AccordionDetails>
      </Accordion>

    </div>
  );
};

UserView.propTypes = {
  onLogout: PropTypes.func,
};

export default UserView;
