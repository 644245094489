import React from 'react';
// import PropTypes from 'prop-types';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Map from './Map';
import ObjectMarker from './Map/ObjectMarker';
import { useDispatch, useSelector } from 'react-redux';
import { deselectObject, switchWordBalloon } from '../actions/index';
import { BalloonType } from '../reducers/data';
import './ObjectsView.css';

const polylineStyle = { color: '#ff7800', weight: 5, opacity: 0.65 };

export const polylinePalette = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
];

const ObjectsView = () => {
  const dispatch = useDispatch();
  const listData = useSelector(state => state.data.listData);
  const objectsFilter = useSelector(state => state.data.filter);
  const viewport = useSelector(state => state.data.viewport);
  const bounds = useSelector(state => state.data.bounds);
  const wordBalloonType = useSelector(state => state.data.wordBalloonType);
  const userLocation = useSelector(state => state.data.user);

  // const onLocationFound = latlng => {
  //   dispatch(locateMap(latlng));
  // };

  const handleSwitchTextBallon = React.useCallback(
    ev => {
      ev.stopPropagation();
      ev.preventDefault();
      dispatch(switchWordBalloon());
      return false;
    },
    [dispatch],
  );

  const onMouseDown = () => {
    dispatch(deselectObject());
  };

  const filteredObjects = listData.filter((object, index) => {
    return objectsFilter[index];
  });

  const generateBalloonText = React.useCallback(
    objectData => {
      if (wordBalloonType === BalloonType.INFO) {
        const { speed, name } = objectData.properties;
        const speedText = speed != null ? '(' + speed + 'км/ч)' : '';
        const text = name + ' ' + speedText;
        return text;
      } else if (wordBalloonType === BalloonType.COORDS) {
        const { coordinates } = objectData.geometry;
        const coordinatesPoint = coordinates[coordinates.length - 1];
        const text = `${Math.floor(coordinatesPoint[1] * 100000) /
          100000},${Math.floor(coordinatesPoint[0] * 100000) / 100000}`;
        return `<div class="object-balloon">${text} <button onclick="navigator.clipboard.writeText('${text}')">Copy</button></div>`;
      }
    },
    [wordBalloonType],
  );

  const layers = React.useMemo(() => {
    return filteredObjects.reduce((ctx, data, index) => {
      const paletteIndex = index % polylinePalette.length;
      const style = Object.assign({}, polylineStyle, {
        color: polylinePalette[paletteIndex],
      });

      const text = generateBalloonText(data);
      ctx.push(
        <ObjectMarker
          key={data.properties.id}
          data={data.properties}
          coordinates={data.geometry.coordinates}
          polyline={style}
          text={text}
        />,
      );
      return ctx;
    }, []);
  }, [filteredObjects, generateBalloonText]);

  return (
    <Map
      viewport={viewport}
      bounds={bounds}
      userLocation={userLocation.position}
      //onLocationFound={onLocationFound}
      onMouseDown={onMouseDown}
      onSwitchTextBallon={handleSwitchTextBallon}
      textSwitchButton={true}
    >
      <MarkerClusterGroup
        // onspiderfied={onSpiderfied}
        showCoverageOnHover={false}
      >
        {layers}
      </MarkerClusterGroup>
    </Map>
  );
};

// ObjectsView.propTypes = {
//   listData: PropTypes.array,
//   objectsFilter: PropTypes.array,
//   userLocation: PropTypes.object,
//   viewport: PropTypes.object,
//   onMoveStart: PropTypes.func,
//   onMouseDown: PropTypes.func,
// };

export default ObjectsView;
