import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const ITEM_HEIGHT = 48;

const ObjectListItemExtensionMenu = ({
  options,
  handleClose,
  anchorEl,
  open,
  ...other
}) => {
  return (
    <Menu
      {...other}
      id='long-menu'
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: 200,
        },
      }}
    >
      {options.map((option, index) => (
        <MenuItem key={option.view} onClick={event => handleClose(event, index)}>
          {option.text}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ObjectListItemExtensionMenu;

ObjectListItemExtensionMenu.propTypes = {
  options: PropTypes.array,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
};
