import React from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    minHeight: 0,
    '& li > div': {
      // backgroundColor: 'rgb(227, 252, 239)',
      // color: 'rgb(54, 179, 126)',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.176) 0px 3px 8px',
    },
    '& li': {
      listStyleType: 'none',
    },
  },
}));

const NotificationList = ({ list, onClose, ...other }) => {
  const classes = useStyles();
  const items = React.useMemo(() => {
    return list
      .slice()
      .reverse()
      .map(notification => {
        return (
          <NotificationItem
            key={notification.id}
            id={notification.id}
            title={notification.title}
            text={notification.text}
            type={notification.type}
            status={notification.status}
            onClose={onClose}
          />
        );
      });
  }, [list, onClose]);

  return (
    <ul {...other} className={classes.root}>
      {items}
    </ul>
  );
};

NotificationList.propTypes = {
  list: PropTypes.array,
  onClose: PropTypes.func,
};

export default NotificationList;
