import { Control as LeafletControl } from 'leaflet';
import L from 'leaflet';
import { withLeaflet } from 'react-leaflet';
import { MapControl } from 'react-leaflet';
import PropTypes from 'prop-types';

class TextSwitchButton extends MapControl {
  createLeafletElement({ position = 'topleft', onClick }) {
    L.Control.TextSwitchButton = LeafletControl.extend({
      onAdd: function() {
        var div = L.DomUtil.create('div');
        div.classList.add('leaflet-control');
        div.classList.add('leaflet-bar');
        div.classList.add('text-switch-button');
        var a = L.DomUtil.create('a');
        a.setAttribute('title', 'Switch text');
        a.addEventListener('click', onClick);
        a.setAttribute('role', 'button');
        div.appendChild(a);
        return div;
      },
    });

    L.control.textSwitchButton = function(opts) {
      return new L.Control.TextSwitchButton(opts);
    };

    let el = L.control.textSwitchButton({ position: position });
    return el;
  }
}

TextSwitchButton.propTypes = {
  position: PropTypes.object,
};

export default withLeaflet(TextSwitchButton);
