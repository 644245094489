import React from 'react';
import AlarmTime from './AlarmTime';
import AlarmDistance from './AlarmDistance';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

export const alarmTypes = [
  { id: 'time', value: 'Време', type: 'time' },
  { id: 'distance', value: 'Километри', type: 'distance' },
];

export const alarmStatus = {
  waiting: 'waiting',
  soon: 'soon',
  finished: 'finished',
};

const styles = theme => ({
  descriptionCell: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  switchDisabled: {
    '&.Mui-disabled.Mui-checked': {
      color: theme.palette.secondary.main,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  inputDisabled: {
    color: theme.palette.text.primary,
  },
  selectDisabled: {
    color: theme.palette.text.primary,
  },
});

const AlarmTimeStyled = withStyles(styles)(AlarmTime);
const AlarmDistanceStyled = withStyles(styles)(AlarmDistance);

const AlarmItem = ({ editMode, data, onChange, onDelete, onError }) => {
  return (
    <React.Fragment>
      {data.type === alarmTypes[0].id && (
        <AlarmTimeStyled
          data={data}
          editMode={editMode}
          onChange={onChange}
          onDelete={onDelete}
          onError={onError}
        />
      )}
      {data.type === alarmTypes[1].id && (
        <AlarmDistanceStyled
          data={data}
          editMode={editMode}
          onChange={onChange}
          onDelete={onDelete}
          onError={onError}
        />
      )}
    </React.Fragment>
  );
};

AlarmItem.propTypes = {
  data: PropTypes.object,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
};

export default AlarmItem;
