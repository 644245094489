import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { makeStyles } from '@material-ui/core/styles';
import ViewStack from './components/ViewStack';
import SideDrawer from './components/SideDrawer';
import ObjectsMenu from './components/ObjectsMenu.js';
import ObjectListReportDialog from './components/ObjectListReportDialog';
import ObjectListCustomReportDialog from './components/ObjectListCustomReportDialog';
import ObjectSelectDialog from './components/ObjectSelectDialog';
import DialogManager from './components/DialogManager';
import ViewStackTabs from './components/ViewStackTabs';
import { updateNotification, loginJwt, deinitData } from './actions/index';
import {
  useUpdateNotifications,
  useCustomReportList,
  useRefreshLogin,
  useTimeout,
} from './hooks';
import {
  useInterval,
  useUpdate,
  useMount,
  // useAppInitObjects,
  // useAppAutoUpdate,
} from './hooks';
import {
  addView,
  showView,
  hideReportDialog,
  reportUpdate,
  reportNodata,
  reopenReportDialog,
  reportReset,
  connectionLost,
  connectionOk,
  showReportDialog,
  initAlarm,
  deinitAlarm,
  reportOptionsUpdate,
  initData,
  updateData,
  updateReportDialog,
  enableCoverageReport,
  // userLoggedOut,
} from './actions/index';
//import { menuMap } from './common.js';
import restApi, { RestApiController } from './restApi';
import configuration from './configuration';
import { fuelReportTypes } from './reducers/report';

import './Main.css';

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 0,
    width: '100%',
  },
  viewStack: {
    flexGrow: 1,
    minWidth: '0px',
    display: 'flex',
    width: '100%',
  },
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabPanels: {
    width: '100%',
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    minHeight: 0,
    overflow: 'hidden',
  },
  tabs: { flexShrink: 0 },
}));

const onBeforePageUnload = e => {
  // Cancel the event
  e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = '';
};

const updateInterval = configuration().objects.updateMs;

const controller = new RestApiController();

function Main({ auth }) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const mounted = React.useRef(false);

  const onUpdateNotifications = React.useCallback(
    data => {
      dispatch(updateNotification(data.list, data.countNew));
    },
    [dispatch],
  );

  const onCheckConnectivity = React.useCallback(() => {
    restApi
      .getHeartbeatAsync()
      .then(() => {
        dispatch(connectionOk());
      })
      .catch(() => {
        dispatch(dispatch(connectionLost()));
      });
  }, [dispatch]);

  const tabItemsReducer = useSelector(state => state.viewStack.tabItems);
  const selectedViewReducer = useSelector(
    state => state.viewStack.selectedView,
  );
  const selectedTab = useSelector(state => state.viewStack.selectedTab);
  const dialogReducer = useSelector(state => state.viewStack.dialog);
  const userReducer = useSelector(state => state.user);
  const reportReducer = useSelector(state => state.report);
  const coverageReportReducer = useSelector(state => state.coverageReport);
  const listDataReducer = useSelector(state => state.data.listData);
  const status = useSelector(state => state.connection.status);
  const autoupdate = useSelector(state => state.data.autoupdate);
  const initialized = useSelector(state => state.data.isInit);
  const login = useSelector(state => state.login);
  const dialogState = useSelector(state => state.dialog);
  const customReportTypes = reportReducer.options.customReportTypes;

  const onRefreshToken = React.useCallback(() => {
    //console.debug('refreshing: ', new Date());
    restApi
      .refreshToken()
      .then(data => {
        const json = data.data;
        dispatch(loginJwt(json.jwt));
      })
      .catch(() => {
        auth.logout().then(() => {
          dispatch(deinitData());
          //onRedirect();
        });
      });
  }, [auth, dispatch]);

  const onFailedRefresh = React.useCallback(() => {
    auth.logout().then(() => {
      dispatch(deinitData());
    });
  }, [auth, dispatch]);

  React.useEffect(() => {
    if(userReducer.hasCoverageReport){
      dispatch(enableCoverageReport());
    }
  }, [userReducer])

  React.useEffect(() => {
    restApi.setAuthTokens(login.jwt);
  }, [login.jwt]);

  useInterval(onCheckConnectivity, configuration().heartbeat.updateMs); //const heartbeatHandle = useInterval(onCheckConnectivity, 5000);
  // useAppInitObjects();
  // useAppAutoUpdate(
  //   initialized && autoupdate && status !== 'lost',
  //   updateInterval,
  // );

  useMount(() => {
    restApi
      .getObjectListAsync(controller)
      .then(data => {
        dispatch(initData(data));
      })
      .catch(e => {
        if (e.reason !== 'canceled') {
          console.debug(e);
        }
      });
  });

  useTimeout(
    () => {
      if (initialized && autoupdate && status !== 'lost') {
        restApi
          .getObjectListAsync(controller)
          .then(data => {
            dispatch(updateData(data));
          })
          .catch(e => {
            if (e.reason !== 'canceled') {
              console.debug(e);
            }
          });
      }
    },
    updateInterval,
    [dispatch, updateData, listDataReducer, initialized, autoupdate, status],
  );

  useMount(() => {
    return () => {
      controller.abort();
    };
  });

  useRefreshLogin(onRefreshToken, onFailedRefresh, login.exp, [login.exp]);

  useMount(() => {
    onRefreshToken();
  });

  useUpdate(() => {
    if (status === 'lost') {
      addToast('Няма връзка със сървъра. Може би нямате интернет свързаност.', {
        appearance: 'warning',
      });
    }
    if (status === 'ok' && mounted.current) {
      addToast('Установена е връзка със сървъра.', {
        appearance: 'info',
      });
    }
  }, [status]);

  useMount(() => {
    mounted.current = true;
    if (!configuration().debugMode) {
      window.addEventListener('beforeunload', onBeforePageUnload);
    }
  });

  const onCustomReportListSuccess = React.useCallback(
    data => {
      dispatch(reportOptionsUpdate({ customReportTypes: data.data }));
    },
    [dispatch],
  );

  const onCustomReportListError = React.useCallback(() => {
    dispatch(reportOptionsUpdate({ customReportTypes: [] }));
  }, [dispatch]);

  useUpdateNotifications(onUpdateNotifications);
  useCustomReportList(onCustomReportListSuccess, onCustomReportListError);

  const tabItems = React.useMemo(() => {
    return tabItemsReducer.map(item => {
      return {
        label: item.label,
        id: item.id,
        tabId: item.tabId,
        ariaControls: item.panelId,
        disabled: item.disabled,
      };
    });
  }, [tabItemsReducer]);

  const onLogout = React.useCallback(() => {
    auth.logout().then(() => {
      dispatch(deinitData());
    });
  }, [auth, dispatch]);

  const onClickMenu = viewName => {
    dispatch(showView(viewName));
  };

  const onSelectCustomReport = React.useCallback(
    data => {
      if (data) {
        const view = 'custom-report';
        const reportProperties = customReportTypes[data.reportIndex];
        restApi
          .getCustomReportAsync(
            data.objectId,
            reportProperties.param,
            data.start,
            data.end,
          )
          .then(json => {
            if (json.ok) {
              dispatch(reportUpdate(view, json.data, data));
            } else if (json.code === 404) {
              dispatch(reportNodata(view, data));
            }
          })
          .catch(ex => {
            addToast('Възникна грешка при опит да се направи справка', {
              appearance: 'error',
              onDismiss: () => {
                dispatch(reopenReportDialog(data.type));
              },
            });
            console.error(ex);
          });
        dispatch(reportReset(view));
        dispatch(addView(view));
        dispatch(showView(view));
      }
      dispatch(hideReportDialog());
    },
    [addToast, customReportTypes, dispatch],
  );

  const onSelectReport = React.useCallback(
    data => {
      const extraParam =
        data && data.reportIndex
          ? { type: fuelReportTypes[data.reportIndex].value }
          : null;

      if (data) {
        const view = data.type;
        restApi
          .getReportViewAsync(
            view,
            data.objectId,
            data.start,
            data.end,
            extraParam,
          )
          .then(json => {
            if (json.ok) {
              dispatch(reportUpdate(view, json.data, data));
            } else if (json.code === 404) {
              dispatch(reportNodata(view, data));
            }
          })
          .catch(ex => {
            addToast('Възникна грешка при опит да се направи справка', {
              appearance: 'error',
              onDismiss: () => {
                dispatch(reopenReportDialog(data.type));
              },
            });
            console.error(ex);
          });
        dispatch(reportReset(view));
        dispatch(addView(view));
        dispatch(showView(view));
      }
      dispatch(hideReportDialog());
    },
    [addToast, dispatch],
  );

  const onSelectObjectAlarms = React.useCallback(
    id => {
      dispatch(addView('alarm'));
      dispatch(showView('alarm'));
      dispatch(deinitAlarm());
      restApi
        .getAlarmsAsync(id)
        .then(resp => {
          dispatch(initAlarm(resp.data.object, resp.data.data));
        })
        .catch(() => {
          addToast(
            'Няма връзка със сървъра - списъкът с аларми е недостъпен.',
            {
              appearance: 'error',
              autoDismiss: true,
            },
          );
        });
    },
    [addToast, dispatch],
  );

  const handleDialogChange = React.useCallback(
    event => {
      const { reportStart, reportEnd, reportObject } = event;
      dispatch(
        updateReportDialog({
          start: reportStart,
          end: reportEnd,
          object: reportObject,
        }),
      );
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    event => {
      const view = event.currentTarget.getAttribute('data-view-index');
      if (view === 'print' || view === 'speed') {
        if (reportReducer[view].status !== 'ready') {
          const targetList = listDataReducer.map(item => {
            return { name: item.properties.name, id: item.properties.id };
          });
          const dialogData = {
            targets: targetList,
            type: view,
            onClose: onSelectReport,
          };
          dispatch(showReportDialog(dialogData, ObjectListReportDialog));
          return;
        }
      }
      if (view === 'fuel') {
        if (reportReducer[view].status !== 'ready') {
          const targetList = listDataReducer.map(item => {
            return { name: item.properties.name, id: item.properties.id };
          });
          const reportList = fuelReportTypes.map(item => {
            return item.label;
          });
          const dialogData = {
            targets: targetList,
            reportList: reportList,
            type: view,
            onClose: onSelectReport,
          };
          dispatch(showReportDialog(dialogData, ObjectListCustomReportDialog));
          return;
        }
      }
      if (view === 'custom-report') {
        if (reportReducer[view].status !== 'ready') {
          const targetList = listDataReducer.map(item => {
            return { name: item.properties.name, id: item.properties.id };
          });
          const reportList = customReportTypes.map(item => {
            return item.label;
          });
          const dialogData = {
            targets: targetList,
            reportList: reportList,
            onClose: onSelectCustomReport,
          };
          dispatch(showReportDialog(dialogData, ObjectListCustomReportDialog));
          return;
        }
      }
      if (view === 'alarm') {
        const targetList = listDataReducer.map(item => {
          return { name: item.properties.name, id: item.properties.id };
        });
        const onClose = data => {
          if (data) {
            onSelectObjectAlarms(data.objectId);
          }
          dispatch(hideReportDialog());
        };

        const dialogData = {
          title: 'Аларми',
          objectList: targetList,
          onClose: onClose,
        };
        dispatch(showReportDialog(dialogData, ObjectSelectDialog));
        return;
      }
      if( view === 'coverage'){
        if (coverageReportReducer.status !== 'ready') {
          const targetList = listDataReducer.map(item => {
            return { name: item.properties.name, id: item.properties.id };
          });
          const dialogData = {
            targets: targetList,
            type: view,
            onClose: onSelectReport,
          };
          dispatch(showReportDialog(dialogData, ObjectListReportDialog));
          return;
        }
      }
      dispatch(showView(view));
    },
    [
      customReportTypes,
      dispatch,
      listDataReducer,
      onSelectCustomReport,
      onSelectObjectAlarms,
      onSelectReport,
      reportReducer,
      coverageReportReducer,
    ],
  );

  const customReportTypeList = React.useMemo(() => {
    return customReportTypes.map(item => item.label);
  }, [customReportTypes]);

  const classes = useStyles();
  const drawerDesktopWidth = 370;
  const drawerMobileHeight = 0.5;
  //show if 'objects' view
  const showSidebar = selectedViewReducer === 'objects';

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        <ViewStackTabs
          items={tabItems}
          onChange={handleChange}
          selected={selectedTab}
        />
      </div>
      <div className={classes.tabPanels}>
        <div className={classes.main}>
          {showSidebar && (
            <SideDrawer
              desktopWidth={drawerDesktopWidth}
              mobileHeight={drawerMobileHeight}
            >
              <ObjectsMenu
                onSelectCustomReport={onSelectCustomReport}
                onSelectReport={onSelectReport}
                onSelectObjectAlarms={onSelectObjectAlarms}
                customReportTypeList={customReportTypeList}
              />
            </SideDrawer>
          )}
          <main className={classes.viewStack}>
            <ViewStack
              tabPanels={tabItemsReducer}
              showView={selectedViewReducer}
              onClickMenu={onClickMenu}
              onLogout={onLogout}
            />
          </main>
          {dialogReducer.show && (
            <DialogManager
              dialogType={dialogReducer.dialogType}
              onChange={handleDialogChange}
              open={dialogReducer.show}
              data={dialogReducer.dialogData}
              state={dialogState}
            />
          )}
        </div>
      </div>
    </div>
  );
}

Main.propTypes = {
  auth: PropTypes.object.isRequired,
  //onRedirect: PropTypes.func.isRequired,
};

export default Main;
