import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PrintReport from './PrintReport';
import MomentInfoPrint from './MomentInfoPrint';
import SkeletonInfo from './SkeletonInfo';
import Map from './Map';
import ObjectStops from './Map/ObjectStops';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  container: {
    height: '50%',
    display: 'flex',
    'align-items': 'stretch',
    '@media (orientation: portrait)': {
      flexDirection: `column`,
    },
  },
  subContainer: {
    width: '100%',
    height: '50%',
  },
  info: {
    //width: '300px',
    flexGrow: 1,
    flexBasis: 0,
    '@media (orientation: portrait)': {
      width: '100%',
    },
  },
  map: {
    'flex-grow': 2,
    '-webkit-flex-grow': 2,
    display: 'flex',
  },
  printButton: {
    opacity: 0.3,
    position: 'absolute',
    left: 10,
    bottom: 10,
    zIndex: 1000,
    '&:hover': {
      opacity: 1,
    },
  },
});

const generatePrintHeaderDetail = (label, value) => {
  return (
    '<div><span>' +
    label +
    ': <span><span>' +
    value +
    '</span></span></span></div>'
  );
};

const driverPrintLayout = data => {
  return (
    "<div class='print-header-container'>" +
    "<div class='print-header-driver'><span>Водач: <span><span>" +
    data.driverName +
    '</span></span></span></div>' +
    "<div class='print-header-report-summary'>" +
    generatePrintHeaderDetail('Начало', data.startDate) +
    generatePrintHeaderDetail('Край', data.endDate) +
    generatePrintHeaderDetail('Почивка', data.totalRest) +
    generatePrintHeaderDetail('Работа', data.totalWork) +
    generatePrintHeaderDetail('Километраж начало (км)', data.startDist) +
    generatePrintHeaderDetail('Километраж край (км)', data.endDist) +
    generatePrintHeaderDetail('Изминат път (км)', data.totalDist) +
    generatePrintHeaderDetail('Извънреден труд', data.overtime) +
    '</div>' +
    '</div>'
  );
};

const MapPrintReport = ({
  object = {},
  addressList,
  loading,
  disableButton = false,
  options,
}) => {
  if (!object) {
    throw new Error('Object value must not be set to null or undefined!');
  }
  const classes = useStyles();
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const orientation = isPortrait ? 'horizontal' : 'vertical';
  const driverNameRef = React.useRef('');
  const [index, setIndex] = React.useState(null);

  const printData = object.print;
  const mapData = object.map;
  const objectName = object.objectName;

  const handleDriverInput = event => {
    driverNameRef.current = event.currentTarget.value;
  };

  const onPrint = React.useCallback(() => {
    const table = document.getElementById('print-report');
    const newtab = window.open('', '', 'height=700,width=700');
    const reportDetails = Object.assign(
      {
        startDate: new Date(object.start).toLocaleString(),
        endDate: new Date(object.end).toLocaleString(),
        driverName: driverNameRef.current,
      },
      object.print,
    );
    newtab.document.write(
      '<html><head>' +
        '<title>Пътен лист - ' +
        objectName +
        '</title>' +
        '<link rel="stylesheet" type="text/css" href="/print.css">' +
        '</head><body>',
    );
    newtab.document.write(driverPrintLayout(reportDetails) + table.outerHTML);
    newtab.document.write('</body></html>');
    newtab.document.close();
    newtab.print();
  }, [object.end, object.print, object.start, objectName]);

  const onSelectItem = selectedIndex => {
    setIndex(selectedIndex);
  };

  React.useEffect(() => {
    setIndex(null);
  }, [mapData]);
  // const totalHours = printData.days.reduce(
  //   (hours, period) => {
  //     hours.rest += period.restHours;
  //     hours.work += period.workHours;
  //     return hours;
  //   },
  //   { rest: 0, work: 0 },
  // );

  return (
    <Box height={1} width={1}>
      <Box height={1} className={classes.container}>
        <Box className={classes.info}>
          <Paper
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {loading ? (
              <SkeletonInfo orientation={orientation} />
            ) : (
              <MomentInfoPrint
                name={object.objectName}
                start={new Date(object.start)}
                end={new Date(object.end)}
                startDist={object.print.startDist}
                endDist={object.print.endDist}
                totalDist={object.print.totalDist}
                totalRest={object.print.totalRest}
                totalWork={object.print.totalWork}
                overtime={object.print.overtime}
                orientation={orientation}
                onDriverInput={handleDriverInput}
              />
            )}
          </Paper>
        </Box>
        <Box className={classes.map}>
          <Paper style={{ flex: 1 }}>
            {loading ? (
              <Map></Map>
            ) : (
              <Map>
                <ObjectStops data={mapData} select={index} />
              </Map>
            )}
          </Paper>
        </Box>
      </Box>
      <Box className={classes.subContainer}>
        <Paper style={{ width: '100%', height: '100%', position: 'relative' }}>
          <Button
            color={'primary'}
            variant='contained'
            size='small'
            className={classes.printButton}
            onClick={onPrint}
            disabled={disableButton}
          >
            Принтирай
          </Button>
          <PrintReport
            id={'print-report'}
            object={printData}
            addressList={addressList}
            loading={loading}
            onSelectItem={onSelectItem}
            options={options && options.print}
          />
        </Paper>
      </Box>
    </Box>
  );
};

MapPrintReport.propTypes = {
  object: PropTypes.object,
  addressList: PropTypes.array,
  loading: PropTypes.bool,
  disableButton: PropTypes.bool,
  options: PropTypes.object,
};

export default MapPrintReport;
