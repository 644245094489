import React from 'react';
import PropTypes from 'prop-types';
import { useLeaflet } from 'react-leaflet';
import { GeoJSON, Marker, CircleMarker } from 'react-leaflet';
import { getLngLat } from './index.js';
import ObjectMarker from './ObjectMarker';
import { getLeafletIcon } from './index.js';
//import { calcAngle } from './index';

export const style = {
  color: 'purple',
  weight: 5,
  opacity: 0.65,
};

const getObjectStops = geoJson => {
  if (!geoJson.properties.ignition) {
    return [];
  }
  const coords = geoJson.geometry.coordinates;
  let skip = true;

  return geoJson.properties.ignition.reduce((ctx, ignition, index) => {
    if (ignition) {
      skip = false;
    } else if (!skip) {
      ctx.push(coords[index]);
      skip = true;
    }
    return ctx;
  }, []);
};

const endpointStartIcon = getLeafletIcon('start');
const endpointFinishIcon = getLeafletIcon('finish');
const frozenObjectIcon = getLeafletIcon('stop');

const ObjectTrack = ({ data, select }) => {
  const { map } = useLeaflet();
  const markersPadding = 25;
  const geoJsonRef = React.useRef();

  React.useEffect(() => {
    const layer = geoJsonRef.current;
    if (layer) {
      map.fitBounds(layer.leafletElement.getBounds(), {
        padding: [markersPadding, markersPadding],
      });
      //const currentZoom = map.getZoom();
      //map.setZoom(currentZoom - 1);
    }
  }, [map]);

  const geoJson = data;
  const coords = geoJson.geometry.coordinates;
  const features = geoJson.properties;
  const start = getLngLat(coords[0]);
  const end = getLngLat(coords[coords.length - 1]);
  let marker;
  const selectedPoint =
    select || select === 0 ? getLngLat(coords[select]) : null;
  if (features.speed && selectedPoint) {
    // const speed = features.speed[select];
    // const p0 = getLngLat(coords[select > 0 ? select - 1 : select]);
    // const p1 = getLngLat(coords[select > 0 ? select : select + 1]);
    // const angle = calcAngle(p0, p1);
    marker = (
      <ObjectMarker
        hasPopup={false}
        coordinates={coords}
        data={features}
        showIndex={select}
        zIndexOffset={1001}
      />
    );
  } else {
    marker = <CircleMarker center={selectedPoint} zIndexOffset={1001} />;
  }

  const objectStops = getObjectStops(geoJson).map((item, index) => {
    return (
      <Marker key={index} icon={frozenObjectIcon} position={getLngLat(item)} />
    );
  });

  React.useEffect(() => {
    if (selectedPoint) {
      map.panTo(selectedPoint);
    }
  }, [select, map, selectedPoint]);

  return (
    <React.Fragment>
      <GeoJSON ref={geoJsonRef} data={geoJson} style={style} />
      {selectedPoint && marker}
      <Marker icon={endpointFinishIcon} position={end} zIndexOffset={1000} />
      <Marker icon={endpointStartIcon} position={start} zIndexOffset={1000} />
      {objectStops}
    </React.Fragment>
  );
};

ObjectTrack.propTypes = {
  data: PropTypes.object.isRequired,
  select: PropTypes.number,
};

export default ObjectTrack;
