import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ObjectTrack from './Map/ObjectTrack';
import VerticalLineChart from './VerticalLineChart';
import MomentInfoCustom from './MomentInfoCustom';
import SkeletonInfo from './SkeletonInfo';
import Map from './Map';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '70%',
    display: 'flex',
    'align-items': 'stretch',
    '@media (orientation: portrait)': {
      flexDirection: `column`,
    },
  },
  subContainer: {
    height: '30%',
    marginTop: '10px',
  },
  info: {
    width: '200px',
    '@media (orientation: portrait)': {
      width: '100%',
    },
  },
  map: {
    'flex-grow': 1,
    '-webkit-flex-grow': 1,
    display: 'flex',
  },
}));

const createMomentInfo = (type, object, index, orientation) => {
  const data = object.info;

  const subheaderData = data.params
    ? data.params.map(item => ({
        value: `${item.value} ${item.units}`,
        label: item.label,
      }))
    : [];

  return (
    <MomentInfoCustom
      name={data.name}
      start={new Date(object.start)}
      end={new Date(object.end)}
      variable={data.values && data.values[index]}
      label={data.description.label}
      units={data.description.units}
      time={data.updated && data.updated[index]}
      ignition={data.ignition && data.ignition[index]}
      satellites={data.satellites && data.satellites[index]}
      orientation={orientation}
      subheaderData={subheaderData}
    />
  );
};

const InfoChartMap = ({ object = {}, type }) => {
  if (!object) {
    throw new Error('Object value must not be set to null or undefined!');
  }

  const loading = type === 'loading';
  const classes = useStyles();
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const orientation = isPortrait ? 'horizontal' : 'vertical';

  const [index, setIndex] = React.useState(null);
  const onSelect = React.useCallback(index => setIndex(index), [setIndex]);

  React.useEffect(() => {
    setIndex(null);
  }, [object.map]);

  return (
    <Box height={1} className={classes.root}>
      <Box height={1} className={classes.container}>
        <Box className={classes.info}>
          <Paper style={{ height: '100%' }}>
            {loading ? (
              <SkeletonInfo orientation={orientation} />
            ) : (
              createMomentInfo(type, object, index, orientation)
            )}
          </Paper>
        </Box>
        <Box className={classes.map}>
          <Paper style={{ flex: 1 }}>
            {loading ? (
              <Map></Map>
            ) : (
              <Map>
                <ObjectTrack data={object.map} select={index} />
              </Map>
            )}
          </Paper>
        </Box>
      </Box>
      <Box className={classes.subContainer}>
        <Paper style={{ height: '100%', paddingRight: '10px' }}>
          {!loading && (
            <VerticalLineChart
              data={object.chart}
              onSelect={onSelect}
              type={type}
              timeseries={object.chart.timeseries ? true : false}
              //label={'Object Speed'}
            />
          )}
        </Paper>
      </Box>
    </Box>
  );
};

InfoChartMap.propTypes = {
  object: PropTypes.object,
  type: PropTypes.string,
};

export default InfoChartMap;
