import { importArrayFillMethod } from './common';

importArrayFillMethod();

class FuelMapItem {
  constructor(start, end) {
    const x1 = start[0];
    const x2 = end[0];
    const y1 = start[1];
    const y2 = end[1];
    this.skew = (y2 - y1) / (x2 - x1);
    this.intersection = y1;
    this.x0 = x1;
  }

  convert(units) {
    //According to the equation of a line y = skew * (x - x0) + intercetption
    return this.skew * (units - this.x0) + this.intersection;
  }
}

export default class FuelMap {
  //calibrationData structure must be in the form of 2D array - [[votage0, liters0],[votage1, liters1], ... ,[votageN, litersN]]
  constructor(calibrationData, units) {
    if (!Array.isArray(calibrationData) || !Array.isArray(calibrationData[0])) {
      throw new Error(
        "The structure of argument 'calibrationData' must be in the form of 2D array",
      );
    }

    const min = calibrationData[0][0];
    const max = calibrationData[calibrationData.length - 1][0];
    const map = Array(max + 1);
    if (min > 0) {
      calibrationData.unshift([0, 0]);
    }

    for (let i = 0, j = 1; j < calibrationData.length; i++, j++) {
      const start = calibrationData[i];
      const end = calibrationData[j];
      const item = new FuelMapItem(start, end);
      map.fill(item, start[0], end[0] + 1);
    }

    this.map = map;
    this.units = units;
  }

  convert(units) {
    if (units || units === 0) {
      if (Array.isArray(units)) {
        const map = this.map;
        return units.map(item => {
          if (item >= map.length) {
            item = map.length - 1;
            console.warn(
              'Calibration data seems to be corrupted or incomplete.',
            );
          }
          if (item === null) {
            return null;
          }

          return map[item].convert(item);
        });
      } else {
        return this.map[units].convert(units);
      }
    }
  }

  getUnits() {
    return this.units;
  }
}
