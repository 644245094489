import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTextField-root, & .MuiButton-root': {
      margin: theme.spacing(2),
      width: 200,
    },
    '& .MuiPaper-root': {
      width: 200 + theme.spacing(2) * 2,
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const LoginForm = ({ onSubmit, error }) => {
  const classes = useStyles();
  const formRef = React.useRef();
  const userRef = React.useRef();
  const passRef = React.useRef();
  const [userError, setUserError] = React.useState();
  const [passError, setPassErrorInner] = React.useState();

  const validate = (user, pass) => {
    var valid = true;
    if (!user) {
      setUserError('Моля, въведете потребителско име');
      valid = false;
    } else {
      setUserError('');
    }
    if (!pass) {
      setPassErrorInner('Моля, въведете парола');
      valid = false;
    } else {
      setPassErrorInner('');
    }
    return valid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate(userRef.current.value, passRef.current.value)) {
      onSubmit(userRef.current.value, passRef.current.value, formRef.current);
    }
    return false;
  };

  return (
    <div className={classes.root}>
      <Paper>
        <form
          ref={formRef}
          noValidate
          //autoComplete='on'
          onSubmit={handleSubmit}
          className={classes.form}
          action={'login'}
        >
          <div>
            <TextField
              inputRef={userRef}
              error={userError ? true : false}
              id='username-input'
              label='Потребителско име'
              helperText={userError}
              variant='outlined'
              name={'username'}
              type={'text'}
            />
          </div>
          <div>
            <TextField
              name={'password'}
              inputRef={passRef}
              error={passError ? true : false}
              id='password-input'
              label='Парола'
              helperText={passError}
              type='password'
              variant='outlined'
              autoComplete='on'
            />
          </div>
          <div>
            <FormHelperText className={'Mui-error'}>{error}</FormHelperText>
          </div>
          <div>
            <Button
              value={'Login'}
              type='submit'
              variant='contained'
              color='primary'
              name='doLogin'
            >
              Влез
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};

export default LoginForm;
