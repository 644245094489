import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MainRoute, LoginRoute } from './Routes';
import TranslatorProvider from './Translator';

function App() {
  return (
    <TranslatorProvider locale={'bg'}>
      <BrowserRouter>
        <Switch>
          <Route path='/' component={MainRoute} exact />
          <Route path='/login' component={LoginRoute} />
        </Switch>
      </BrowserRouter>
    </TranslatorProvider>
  );
}

export default App;
