import { getMenuMap } from '../common.js';
import FuelMap from '../FuelMap.js';
import PrintReport from '../PrintReport.js';

export const fuelReportTypes = [
  { label: 'Аналогово гориво', value: 'unit' },
  { label: 'CAN гориво(литри)', value: 'liter' },
  { label: 'CAN гориво(% / литри)', value: 'percentage' },
  { label: 'CAN Разход гориво', value: 'consumption' },
  { label: 'CAN Разход гориво(тотал)', value: 'total_consumption' },
];

const initState = getMenuMap().reduce(
  (context, item) => {
    context[item.view] = { status: 'loading' };
    return context;
  },
  { options: { customReportTypes: [] } },
);

const prepareNodata = queryParams => {
  return {
    start: queryParams.start,
    end: queryParams.end,
    objectName: queryParams.name,
    status: 'nodata',
  };
};

const round3DecimalPlaces = value => {
  return Math.round(value * 1000) / 1000;
};

const applyCoeffs = (variable, multiplier, offset) => {
  if (multiplier === 1) {
    multiplier = undefined;
  }
  if (offset === 0) {
    multiplier = undefined;
  }

  if (multiplier && offset) {
    return variable.map(
      item => round3DecimalPlaces(item * multiplier) + offset,
    );
  } else if (multiplier) {
    return variable.map(item => round3DecimalPlaces(item * multiplier));
  } else if (offset) {
    return variable.map(item => item + offset);
  } else {
    return variable;
  }
};

const createInfo = (properties, label, units, values) => {
  return {
    ...properties,
    description: { label, units },
    values: values,
  };
};

const prepareReportData = (object, queryParams, description, values) => {
  const result = {
    info: null,
    chart: null,
    map: null,
    start: queryParams.start,
    end: queryParams.end,
    objectName: queryParams.name,
    status: 'loading',
  };

  if (object.geometry.coordinates.length < 2) {
    return Object.assign({}, result, { status: 'nodata' });
  }

  if (values[0] === null && values.findIndex(item => item !== null) < 0) {
    return Object.assign({}, result, { status: 'nodata' });
  }

  let variable = applyCoeffs(
    values,
    description.multiplier,
    description.offset,
  );
  const info = createInfo(
    object.properties,
    description.label,
    description.units,
    variable,
  );
  const map = object;
  const chart = {
    variable: variable,
    time: object.properties.updated,
    description: description,
  };
  return Object.assign({}, result, {
    info: info,
    map: map,
    chart: chart,
    status: 'ready',
  });
};

const prepareSpeedData = (object, queryParams) => {
  const description = {
    label: 'Скорост',
    units: 'км/ч',
  };
  return prepareReportData(
    object,
    queryParams,
    description,
    object.properties.speed,
  );
};

const prepareFuelDataDefault = (object, queryParams) => {
  const calibrationData = object.properties.calibration.fuel;
  if (Array.isArray(calibrationData) && Array.isArray(calibrationData[0])) {
    const fuelMap = new FuelMap(calibrationData);
    const fuelData = fuelMap.convert(object.properties.fuel);
    const ignition = object.properties.ignition;
    let lastValue = 0;
    const fuel = fuelData.map((value, index) => {
      if (ignition[index]) {
        lastValue = value;
        return value;
      } else {
        return lastValue;
      }
    });
    return prepareReportData(
      object,
      queryParams,
      { label: 'Гориво', units: 'литра' },
      fuel,
    );
  } else {
    return prepareReportData(
      object,
      queryParams,
      { label: 'Гориво', units: 'отн. ед.' },
      object.properties.fuel,
    );
  }
};

const prepareFuelDataCustom = (object, queryParams) => {
  return prepareReportData(
    object,
    queryParams,
    object.properties.description,
    object.properties.fuel,
  );
};

const insertFuelConsumption = properties => {
  const { description } = properties;
  const multiplier = description ? description.multiplier : 1;
  const value =
    (properties.fuel[properties.fuel.length - 1] - properties.fuel[0]) *
    multiplier;
  return {
    ...properties,
    params: [
      { label: 'Общ. разход', value: Math.round(value * 10) / 10, units: 'л' },
    ],
  };
};

const prepareFuelData = (object, queryParams) => {
  if ([0, undefined, null].includes(queryParams.reportIndex)) {
    return prepareFuelDataDefault(object, queryParams);
  } else if ([3, 4].includes(queryParams.reportIndex)) {
    return prepareFuelDataCustom(
      { ...object, properties: insertFuelConsumption(object.properties) },
      queryParams,
    );
  } else {
    return prepareFuelDataCustom(object, queryParams);
  }
};

const preparePrintData = (object, queryParams, state) => {
  const result = {
    print: null,
    map: null,
    start: queryParams.start,
    end: queryParams.end,
    objectName: queryParams.name,
    status: 'loading',
  };

  const { hasWorktime, hasNotesColumn, workStart, workEnd } = state.options;

  if (!object.periods.length) {
    return Object.assign({}, result, { status: 'nodata' });
  }

  const name = object.name;
  const periods = object.periods;
  if(null == object.details){
    object.details = {};
  }
  const details = {
    ...object.details,
    startDist: object.details.startDist + object.details.distCorrection,
    endDist: object.details.endDist + object.details.distCorrection,
    hasWorktime,
    hasNotesColumn,
    workStart,
    workEnd,
  };
  const geojson = {
    geometry: {
      coordinates: object.periods.map(period => period.coordinates),
    },
  };

  const report = new PrintReport(name, periods, details);

  return Object.assign({}, result, {
    print: report.formatData(),
    map: geojson,
    trackingId: object.trackingId,
    status: 'ready',
  });
};

const prepareCustomReportData = (object, queryParams) => {
  return prepareReportData(
    object,
    queryParams,
    object.properties.description,
    object.properties.values,
  );
};

const prepareData = (state, type, object, queryParams) => {
  switch (type) {
    case 'speed':
      return prepareSpeedData(object, queryParams);
    case 'fuel':
      return prepareFuelData(object, queryParams);
    case 'print':
      return preparePrintData(object, queryParams, state);
    case 'custom-report':
      return prepareCustomReportData(object, queryParams);
    case 'coverage':
      return;
    default:
      throw new Error('Unknown report type!');
  }
};

export const reportReducer = (state = initState, action) => {
  let type = action.type;
  switch (type) {
    case 'report.optionsUpdate':
      //      state.customReportTypes = action.data;

      state.options = {
        ...state.options,
        ...Object.keys(action.data).reduce((acc, item) => {
          if (action.data[item] !== undefined) {
            acc[item] = action.data[item];
          }
          return acc;
        }, {}),
      };
      state = { ...state };
      //state = Object.assign({}, state, { customReportTypes: action.data });
      break;
    case 'report.update':
      state[action.report] = prepareData(
        state,
        action.report,
        action.data,
        action.queryParams,
      );
      break;
    case 'report.reset':
    case 'dialog.reopen':
      state[action.report] = { status: 'loading' };
      break;
    case 'report.nodata':
      state[action.report] = prepareNodata(action.query);
      break;
    case 'report.failure':
      state[action.report] = action.data;
      break;
    case 'report.coverage.enable':
      state['coverage'] = { status: 'loading' };
      break;
    default:
  }
  return state;
};
