import React from 'react';
import server, { RestApiController } from '../restApi';
import configuration from '../configuration';

const getCountNew = list => {
  return list.reduce((count, item) => {
    if (item.status === 'unseen') {
      count++;
    }
    return count;
  }, 0);
};

const updateNotifications = options => {
  return server.getNotificationsAsync(options).then(data => {
    const countNew = getCountNew(data.data);
    return { list: data.data, countNew };
  });
};

export default function useUpdateNotifications(onUpdate) {
  const erroMessage = 'Failed to update notifications.';

  React.useEffect(() => {
    const controller = new RestApiController();
    const { signal } = controller;
    let interval = null;
    updateNotifications({ signal })
      .then(onUpdate)
      .catch(() => {
        console.debug('Notifications update canceled');
      });
    interval = setInterval(() => {
      updateNotifications({ signal })
        .then(onUpdate)
        .catch(() => {
          console.error(erroMessage);
        });
    }, configuration().notifications.updateMs);

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [onUpdate]);

  return null;
}
