import React from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';

const ToastError = ({ errorMessage, onDismiss }) => {
  const { addToast } = useToasts();
  React.useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: false,
        onDismiss: onDismiss,
      });
    }
  }, [addToast, errorMessage, onDismiss]);

  return <React.Fragment></React.Fragment>;
};

ToastError.propTypes = {
  errorMessage: PropTypes.string,
  onDismiss: PropTypes.func,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    void error;
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ToastError
          errorMessage={
            'Възникна грешка! Затворете съобщението за да се презареди страницата.'
          }
          onDismiss={() => {
            // eslint-disable-next-line no-self-assign
            window.location.href = window.location.href;
          }}
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.array,
};

export default ErrorBoundary;
