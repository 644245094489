import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    fill: 'currentColor',
    width: '1.3em',
    height: '1.3em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: '0',
    userSelect: 'none',
  },
};

const BaseIcon = props => {
  const { children, width, height, classes, className, ...other } = props;
  return (
    <svg
      {...other}
      className={clsx(classes.root, className)}
      width={width}
      height={height}
      viewBox='0 0 34.04 34.04'
      //id="Layer_1" data-name="Layer 1"
      aria-hidden={true}
      role='presentation'
    >
      {children}
    </svg>
  );
};

BaseIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  //d: PropTypes.string.isRequired,
  //children: PropTypes.array,
};

export const Icon = withStyles(styles)(BaseIcon);

export const EngineOnIcon = props => {
  const { ...other } = props;
  return (
    <Icon {...other}>
      <path
        d={
          'M 5 4 L 5 6 L 9 6 L 9 4 L 5 4 z M 15 4 L 15 6 L 19 6 L 19 4 L 15 4 z M 4 7 C 2.9 7 2 7.9 2 9 L 2 19 C 2 20.1 2.9 21 4 21 L 20 21 C 21.1 21 22 20.1 22 19 L 22 9 C 22 7.9 21.1 7 20 7 L 4 7 z M 16 11 L 18 11 L 18 13 L 20 13 L 20 15 L 18 15 L 18 17 L 16 17 L 16 15 L 14 15 L 14 13 L 16 13 L 16 11 z M 4 13 L 10 13 L 10 15 L 4 15 L 4 13 z'
        }
      ></path>
    </Icon>
  );
};

export const EngineOffIcon = props => {
  const { ...other } = props;
  return (
    <Icon {...other}>
      <defs>
        <style>
          {
            '.cls-1{fill:#f50057;stroke:#f50057;stroke-miterlimit:10;stroke-width:2px;}'
          }
        </style>
      </defs>
      <title>engine-off</title>
      <path
        d='M5.21,2.4V4.48H9.38V2.4Zm10.41,0V4.48h4.17V2.4ZM4.17,5.52A2.09,2.09,0,0,0,2.08,7.6V18A2.09,2.09,0,0,0,4.17,20.1H20.83A2.09,2.09,0,0,0,22.92,18V7.6a2.09,2.09,0,0,0-2.09-2.08Zm12.5,4.17h2.08v2.08h2.08v2.08H18.75v2.09H16.67V13.85H14.58V11.77h2.09ZM4.17,11.77h6.25v2.08H4.17Z'
        transform='translate(4.52 4.73)'
      />
      <line
        className={'cls-1'}
        x1={'1.05'}
        y1={'32.72'}
        x2={'32.99'}
        y2={'1.32'}
      />
      <line className='cls-1' x1='1.32' y1='1.05' x2='32.72' y2='32.99' />
    </Icon>
  );
};

export const NotificationSuccessIcon = props => {
  const { ...other } = props;
  return (
    <Icon {...other}>
      <path
        fillRule='evenodd'
        d='M12 5.5l-8 8-4-4L1.5 8 4 10.5 10.5 4 12 5.5z'
      ></path>
    </Icon>
  );
};

export const NotificationInfoIcon = props => {
  const { ...other } = props;
  return (
    <Icon {...other}>
      <path
        fillRule='evenodd'
        d='M6.3 5.71a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 8.01c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V8v.01zM7 2.32C3.86 2.32 1.3 4.86 1.3 8c0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z'
      ></path>{' '}
    </Icon>
  );
};

export const NotificationErrorIcon = props => {
  const { ...other } = props;
  return (
    <Icon {...other}>
      <path
        fillRule='evenodd'
        d='M5.05.01c.81 2.17.41 3.38-.52 4.31C3.55 5.37 1.98 6.15.9 7.68c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.01 8.68 2.15 5.05.02L5.03 0l.02.01z'
      ></path>{' '}
    </Icon>
  );
};

export const NotificationWarningIcon = props => {
  const { ...other } = props;
  return (
    <Icon {...other}>
      <path
        fillRule='evenodd'
        d='M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z'
      ></path>
    </Icon>
  );
};

export const DotIcon = props => {
  const { ...other } = props;
  return <span {...other}>&#9679;</span>;
};
