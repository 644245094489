class VerticalLineChartPlugin {
  constructor() {
    this.mouseDown = false;
    this.index = null;
    this._needUpdate = false;
    this.id = 'verticalLine';
    this.buttonTimer = null;
    this.isLineMoving = true;
  }

  _onmousedown() {
    this.mouseDown = true;
  }

  _onmouseup() {
    this.mouseDown = false;
  }

  _onmousemove(ev) {
    if (this.mouseDown) {
      this._update(ev);
    }
  }

  _onclick(ev) {
    this._update(ev);
  }

  _onkeydown(ev) {
    switch (ev.keyCode) {
      case 37:
        this._startMoveLine(false);
        break;
      case 39:
        this._startMoveLine(true);
        break;
      default:
    }
  }

  _onkeyup() {
    this._stopMoveLine();
  }

  _startMoveLine(right) {
    if (this.isLineMoving) {
      this.isLineMoving = false;
      const instance = this;
      this._moveLine(right);
      this.buttonTimer = window.setInterval(() => {
        instance._moveLine(right);
      }, 300);
    }
  }

  _stopMoveLine() {
    //console.log('move stop');
    window.clearInterval(this.buttonTimer);
    this.buttonTimer = null;
    this.isLineMoving = true;
  }

  _moveLine(right) {
    if (right) {
      ++this.index;
      if (this.index >= this.datasetLength) {
        this.index = this.datasetLength - 1;
      }
    } else {
      --this.index;
      if (this.index < 0) {
        this.index = 0;
      }
    }

    if (this._onselect) {
      this._onselect(this.index);
    }

    this.chart.update();
  }

  _init(chart) {
    const canvas = chart.canvas;
    this.chart = chart;
    canvas.onmousedown = this._onmousedown.bind(this);
    canvas.onmouseup = this._onmouseup.bind(this);
    canvas.ontouchstart = this._onmousedown.bind(this);
    canvas.ontouchend = this._onmouseup.bind(this);
    //canvas.onmousemove = this._onmousemove.bind(this);
    canvas.onclick = this._onclick.bind(this);
    document.body.onkeydown = this._onkeydown.bind(this);
    document.body.onkeyup = this._onkeyup.bind(this);
    this.datasetLength = this.chart.data.datasets.reduce(
      (max, data) => (data.data.length > max ? data.data.length : max),
      0,
    );
  }

  drawVerticalLine() {
    if (!this._needUpdate) {
      return;
    }

    if (this.index || this.index === 0) {
      const ctx = this.chart.canvas.getContext('2d');
      const xaxis = this.chart.scales['x-axis-0'];
      const yaxis = this.chart.scales['y-axis-0'];
      const datasetIndex = 0;
      const x = xaxis.getPixelForValue(undefined, this.index, datasetIndex);

      ctx.save();
      ctx.setLineDash([5, 3]);
      ctx.beginPath();
      ctx.moveTo(x, yaxis.top);
      ctx.strokeStyle = '#808080';
      ctx.lineTo(x, yaxis.bottom);
      ctx.stroke();
      ctx.restore();
    }
  }

  _chooseRightElement(elements) {
    const datasetIndex = elements.length ? elements[0]._datasetIndex : 0;
    const dataset = this.chart.data.datasets[datasetIndex];
    const element = elements.reduce((maxValue, element) => {
      if (dataset.data[element._index].y > dataset.data[maxValue._index].y) {
        return element;
      }
      return maxValue;
    }, elements[0]);
    return element;
  }

  _update(ev) {
    const elements = this.chart.getElementsAtXAxis(ev);
    const element = this._chooseRightElement(elements);
    const index = element._index;
    if (this.index !== index) {
      this.index = index;
      this._needUpdate = true;
      this.chart.update(); //this.chart.render();

      if (this._onselect) {
        this._onselect(index);
      }
    }
  }

  afterDraw() {
    this.drawVerticalLine();
    // console.log('drawVerticalLine',easing);
  }

  beforeInit(chart, options) {
    const defaultSettings = {
      tooltips: {
        enabled: false,
      },
      animation: false,
      onHover: this._onmousemove.bind(this),
    };

    chart.options = Object.assign({}, chart.options, defaultSettings);
    this._init(chart);
    this._onselect = options.onselect;
  }
}

export default VerticalLineChartPlugin;
