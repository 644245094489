import React from 'react';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
import ObjectsSelect from './ObjectsSelect';
import SettingsBox from './SettingsBox';
import { ObjectSettingsStateEnum } from '../../reducers/user';

// const useStyles = makeStyles(theme => ({
  // root: {
  //   '& div': {
  //     paddingTope: theme.spacing(2),
  //   },
  // },
// }));

const ObjectSettings = ({ editMode, settings, objectList, object, state, onUpdateSettings, onChangeState, onSelectObject }) => {
  //const classes = useStyles();
  const handleClose = () => {
    onChangeState(ObjectSettingsStateEnum.Selection)
  }

  const handleSelectObject = object => {
    onChangeState(ObjectSettingsStateEnum.Show);
    onSelectObject(object);
  }

  const handleUpdateSettings = data => {
    if(object && data){
      onUpdateSettings(object.id, data);
    }
  }

  if(state === ObjectSettingsStateEnum.Selection){
    return <ObjectsSelect objectList={objectList} onSelect={handleSelectObject} object={object}/>
  }else if(state === ObjectSettingsStateEnum.Show){
    return <SettingsBox data={settings} editMode={editMode} onClose={handleClose} onCancel={handleClose} onChange={handleUpdateSettings}/>
  }else{
    return <></>
  }
};

ObjectSettings.propTypes = {
  onLogout: PropTypes.func,
  data: PropTypes.object,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ObjectSettings;
