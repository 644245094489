import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogPeriodBase from './DialogPeriodBase';
//import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  objectName: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const textStrings = {
  speed: { title: 'Справка Скорост', explanation: 'справка за скоростта' },
  fuel: { title: 'Справка Гориво', explanation: 'справка за горивото' },
  print: { title: 'Справка Пътен Лист', explanation: 'пътен лист' },
  coverage: { title: 'Справка за обработена площ от', explanation: 'справка за обработена площ'}
};

const ObjectReportDialog = ({
  open,
  target,
  type,
  onClose,
  onChange,
  state,
}) => {
  const classes = useStyles();
  const { reportStart, reportEnd } = state;
  const objectName = <span className={classes.objectName}>{target.name}</span>;
  const title = (
    <div>
      {type && textStrings[type].title} {objectName}
    </div>
  );

  const handleStartChange = date => {
    onChange({ ...state, reportStart: date });
  };

  const handleEndChange = date => {
    onChange({ ...state, reportEnd: date });
  };

  const handleClose = data => {
    if (data) {
      onClose(
        Object.assign(
          {
            objectId: target.id,
            name: target.name,
            type: type,
          },
          data,
        ),
      );
    } else {
      onClose();
    }
  };

  return (
    <DialogPeriodBase
      open={open}
      title={title}
      onClose={handleClose}
      startDate={reportStart}
      endDate={reportEnd}
      onStartChange={handleStartChange}
      onEndChange={handleEndChange}
    />
  );
};

ObjectReportDialog.propTypes = {
  open: PropTypes.bool,
  target: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  state: PropTypes.object,
};

export default ObjectReportDialog;
