import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ObjectListItem from './ObjectListItem.js';
import SkeletonListItem from './SkeletonListItem.js';
import Menu from './ObjectListItemExtensionMenu.js';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
// import { getMenuMap } from '../../common.js';

const useStyles = makeStyles(() => ({
  list: {
    overflowY: 'auto',
  },
}));

const ObjectList = ({
  listData = [],
  onSelect,
  onCheckbox,
  onMenu,
  loading,
  selectedItem,
  menuOptions,
}) => {
  const countSkeletonItems = 3;
  const [selected, setSelected] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const styles = useStyles();
  menuOptions = loading ? [] : menuOptions;
  //const menuOptionsList = menuOptions ? menuOptions.map(item => item.text) : getMenuMap();

  React.useEffect(() => {
    setSelected(selectedItem);
  }, [selectedItem]);

  const handleSelect = useCallback(
    ev => {
      const objectId = parseInt(ev.currentTarget.getAttribute('data-item-key'));
      onSelect && onSelect(objectId, ev);
      setSelected(objectId);
    },
    [onSelect],
  );

  const handleCheckbox = useCallback(
    (ev, value) => {
      const index = parseInt(ev.currentTarget.getAttribute('data-item-key'));
      onCheckbox && onCheckbox(index, value);
    },
    [onCheckbox],
  );

  const handleClickMenu = useCallback(ev => {
    const index = parseInt(ev.currentTarget.getAttribute('data-item-key'));
    setAnchorEl(ev.currentTarget);
    setSelected(index);
  }, []);

  const handleClose = useCallback(
    (ev, reason) => {
      setAnchorEl(null);
      switch (reason) {
        case 'backdropClick':
        case 'escapeKeyDown':
        case 'tabKeyDown':
          onMenu(selected, -1);
          break;
        default:
          onMenu(selected, reason);
      }
    },
    [onMenu, selected],
  );

  const open = Boolean(anchorEl);
  let items;
  if (loading) {
    items = Array.from(new Array(countSkeletonItems)).map((item, index) => {
      return <SkeletonListItem key={index} />;
    });
  } else {
    items = listData.map(item => {
      const objectId = item.properties.id;
      if (!item) {
        return null;
      }
      return (
        <ObjectListItem
          key={objectId}
          index={objectId}
          data={item.properties}
          checked={item.checked}
          selected={selected === objectId}
          onClickMenu={handleClickMenu}
          onClickItem={handleSelect}
          onClickCheckbox={handleCheckbox}
        />
      );
    });
  }

  return (
    <React.Fragment>
      <div className={styles.list}>
        <List dense={true}>{items}</List>
      </div>
      <Menu
        options={menuOptions}
        handleClose={handleClose}
        anchorEl={anchorEl}
        open={open}
      />
    </React.Fragment>
  );
};

ObjectList.propTypes = {
  listData: PropTypes.array,
  checkedList: PropTypes.array,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onCheckbox: PropTypes.func,
  onMenu: (props, propName, componentName) => {
    if (!props.loading && !props.onMenu) {
      return new Error(`onMenu was not specified in '${componentName}'.`);
    }
  },
  selectedItem: PropTypes.number,
};

export default ObjectList;
