let instance = null;

const configuration = () => {
  if (!process.env.REACT_APP_LEAFLET_MAP_TILES_URL) {
    console.error(
      'Please specify a map tiles provide in REACT_APP_LEAFLET_MAP_TILES_URL parameter from the .env file. For example "REACT_APP_LEAFLET_MAP_TILES_URL=https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"',
    );
  }

  if (!instance) {
    instance = {
      api: {
        url: process.env.REACT_APP_API_URL,
        port: parseInt(process.env.REACT_APP_API_PORT),
      },
      objects: {
        updateMs:
          parseInt(process.env.REACT_APP_UPDATE_OBJECTS_SECONDS || 5) * 1000,
      },
      geocoding: {
        disable: process.env.REACT_APP_DISABLE_GEOCODING === 'true',
      },
      locale: {
        date: process.env.REACT_APP_DATE_LOCALE || 'ru-Ru',
      },
      notifications: {
        updateMs:
          parseInt(process.env.REACT_APP_UPDATE_NOTIFICATIONS_SECONDS || 1800) *
          1000,
      },
      heartbeat: {
        updateMs:
          parseInt(process.env.REACT_APP_HEARTBEAT_SECONDS || 10) * 1000,
      },
      map: {
        zoom: {
          min: parseInt(process.env.REACT_APP_MIN_ZOOM || 7),
          max: parseInt(process.env.REACT_APP_MAX_ZOOM || 19),
        },
        tilesUrl: process.env.REACT_APP_LEAFLET_MAP_TILES_URL,
      },
      debugMode:
        (process.env.REACT_APP_DEBUG_MODE &&
          process.env.REACT_APP_DEBUG_MODE.toLowerCase()) === 'true',
    };

    if (
      process.env.REACT_APP_MAP_NORTH_BOUND &&
      process.env.REACT_APP_MAP_SOUTH_BOUND &&
      process.env.REACT_APP_MAP_EAST_BOUND &&
      process.env.REACT_APP_MAP_WEST_BOUND
    ) {
      instance.map.bounds = {
        northEast: {
          lat: parseFloat(process.env.REACT_APP_MAP_NORTH_BOUND),
          lng: parseFloat(process.env.REACT_APP_MAP_EAST_BOUND),
        },
        southWest: {
          lat: parseFloat(process.env.REACT_APP_MAP_SOUTH_BOUND),
          lng: parseFloat(process.env.REACT_APP_MAP_WEST_BOUND),
        },
      };
    }

    if (
      process.env.REACT_APP_MAP_DEFAULT_NORTH &&
      process.env.REACT_APP_MAP_DEFAULT_SOUTH &&
      process.env.REACT_APP_MAP_DEFAULT_EAST &&
      process.env.REACT_APP_MAP_DEFAULT_WEST
    ) {
      instance.map.defaultFrame = {
        northEast: {
          lat: parseFloat(process.env.REACT_APP_MAP_DEFAULT_NORTH),
          lng: parseFloat(process.env.REACT_APP_MAP_DEFAULT_EAST),
        },
        southWest: {
          lat: parseFloat(process.env.REACT_APP_MAP_DEFAULT_SOUTH),
          lng: parseFloat(process.env.REACT_APP_MAP_DEFAULT_WEST),
        },
      };
    }

    if (
      process.env.REACT_APP_OUTBOUNDS_MAX_ZOOM &&
      process.env.REACT_APP_OUTBOUNDS_MIN_ZOOM
    ) {
      instance.map.outboundsZoom = {
        min: parseFloat(
          process.env.REACT_APP_OUTBOUNDS_MIN_ZOOM || instance.map.zoom.min,
        ),
        max: parseFloat(
          process.env.REACT_APP_OUTBOUNDS_MAX_ZOOM || instance.map.zoom.max,
        ),
      };
    }
  }

  return instance;
};

export default configuration;
