import React from 'react';
import PropTypes from 'prop-types';
import { EngineOnIcon, EngineOffIcon } from './CustomIcons.js';
import MomentInfoBasic from './MomentInfoBasic';
import configuration from '../configuration';

const dateLocale = configuration().locale.date;

const MomentInfoCustom = ({
  name,
  units,
  start,
  end,
  time,
  label,
  variable,
  satellites,
  ignition,
  orientation = 'horizontal',
  subheaderData,
}) => {
  const noDataSymbol = '-.-';

  let ignitionIcon;
  switch (ignition) {
    case 0:
      ignitionIcon = <EngineOffIcon />;
      break;
    case 1:
      ignitionIcon = <EngineOnIcon />;
      break;
    default:
      ignitionIcon = null;
  }

  const primaryData = [
    {
      label: label,
      value: variable != null ? variable + ' ' + units : noDataSymbol,
    },
    {
      label: 'Време',
      value:
        time != null ? new Date(time).toLocaleString(dateLocale) : noDataSymbol,
    },
  ];

  const secondaryData = [
    {
      label: 'Брой сателити:',
      value: satellites != null ? satellites : noDataSymbol,
    },
    {
      label: 'Контактен Ключ:',
      value: ignition != null ? ignitionIcon : noDataSymbol,
    },
  ];

  return (
    <MomentInfoBasic
      name={name}
      start={start}
      end={end}
      primaryData={primaryData}
      secondaryData={secondaryData}
      orientation={orientation}
      subheaderData={subheaderData}
    />
  );
};

MomentInfoCustom.propTypes = {
  name: PropTypes.string.isRequired,
  time: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  start: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  end: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  label: PropTypes.string.isRequired,
  variable: PropTypes.number,
  units: PropTypes.string.isRequired,
  satellites: PropTypes.number,
  ignition: PropTypes.number,
  orientation: PropTypes.string,
  primaryData: PropTypes.array,
  secondaryData: PropTypes.array,
  subheaderData: PropTypes.array,
};

export default MomentInfoCustom;
