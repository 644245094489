import React from 'react';
import PropTypes from 'prop-types';
import { Line, Chart } from 'react-chartjs-2';
import VerticalLineChartPlugin from '../VerticalLineChartPlugin';

const options = {
  fill: false,
  responsive: true,
  maintainAspectRatio: false,
  hover: { mode: null },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        type: 'category',
        // type: 'time',
        time: {
          unit: 'hour',
          unitStepSize: 4,
          displayFormats: {
            hour: 'MMM DD HH:mm',
          },
        },
        display: true,
        // scaleLabel: {
        //   display: true,
        //   labelString: 'Date',
        // },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: false,
        },
        display: true,
        scaleLabel: {
          display: true, //display: false,
          //labelString: 'км/ч',
        },
      },
    ],
  },
};

Chart.pluginService.register(new VerticalLineChartPlugin());
Chart.defaults.global.elements.point.radius = 1;
Chart.defaults.global.elements.line.borderWidth = 1;

const labelsCached = [];

const getLabels = time => {
  return time.map(item => {
    const datetime = new Date(item);
    const month = datetime.getMonth();
    const day = datetime.getDate();
    if (!labelsCached[month]) {
      labelsCached[month] = [];
    }

    if (labelsCached[month][day]) {
      return labelsCached[month][day];
    } else {
      const label = day + '/' + (month + 1);
      labelsCached[month][day] = label;
      return label;
    }
  });
};

const getAxisOptions = timeseries => {
  if (timeseries) {
    return [
      {
        type: 'time',
        time: {
          unit: 'hour',
          unitStepSize: 4,
          displayFormats: {
            hour: 'MMM DD HH:mm',
          },
        },
        display: true,
      },
    ];
  } else {
    return [
      {
        type: 'category',
        display: true,
      },
    ];
  }
};

const VerticalLineChart = ({ data, onSelect, type, timeseries = false }) => {
  switch (type) {
    case 'fuel':
      options.scales.yAxes[0].scaleLabel.labelString = data.description
        ? data.description.units
        : 'литри';
      break;
    case 'speed':
      options.scales.yAxes[0].scaleLabel.labelString = 'км/ч';
      break;
    case 'custom':
      options.scales.yAxes[0].scaleLabel.labelString =
        data.description.label + ' (' + data.description.units + ')';
      break;
    default:
      console.error('Type not found.');
      break;
  }

  const chartData = {
    datasets: [
      {
        //data: data.variable,
        fill: false,
        //label: label,
        borderColor: 'purple',
        backgroundColor: 'purple',
        lineTension: 0,
      },
    ],
  };

  if (timeseries) {
    chartData.datasets[0].data = data.time.map((item, index) => {
      return {
        x: item,
        y: data.variable[index],
      };
    });
    options.scales.xAxes = getAxisOptions(timeseries);
  } else {
    const labels = getLabels(data.time);
    Object.assign(chartData, { labels: labels });
    chartData.datasets[0].data = data.variable;
    options.scales.xAxes = getAxisOptions(timeseries);
  }

  options.plugins = {
    verticalLine: {
      onselect: onSelect,
    },
  };

  return <Line data={chartData} options={options} />;
};

VerticalLineChart.propTypes = {
  data: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  type: PropTypes.string.isRequired,
  timeseries: PropTypes.bool,
};

export default React.memo(VerticalLineChart);
