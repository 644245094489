import React from 'react';
import L from 'leaflet';
import { Marker as LeafletMarker } from 'leaflet';
import { Popup as LeafletPopup } from 'leaflet';
import { DivIcon as LeafletDivIcon } from 'leaflet';
import { LeafletProvider, withLeaflet } from 'react-leaflet';
import { MapLayer } from 'react-leaflet';
import PropTypes from 'prop-types';
import { preloadImage } from '../../common.js';
import { calcAngle, getLngLat } from './index.js';

const urlGreen = 'pointer-green.svg';
const urlGrey = 'pointer-grey.svg';
const urlGreenCircle = 'pointer-green-circle.svg';
const urlGreyCircle = 'pointer-grey-circle.svg';

class ObjectMarker extends MapLayer {
  // constructor(props) {
  //   super(props);
  // }

  updatePolyline(props) {
    if (props.polyline) {
      const state = this.getPointerState(props.data);
      const coordinates = state === 'rest' ? [] : props.coordinates;
      this.polyline = {
        data: { type: 'LineString', coordinates: coordinates },
        style: props.polyline,
      };
    }
  }

  updateData(props) {
    this.name = props.data.name;
    const speed = props.data.speed;
    if (Array.isArray(speed)) {
      const selected = props.showIndex;
      const index = selected || selected === 0 ? selected : speed.length - 1;
      if (index < 0) {
        throw Error('data.speed Array must be larger than 0 items!');
      }
      this.speed = speed[index];
    } else {
      this.speed = speed;
    }
  }

  updatePosition(props) {
    //get last two points with coordintates
    const coords = props.coordinates;
    const select = props.showIndex;
    const idx1 = select || select === 0 ? select : coords.length - 1;
    const idx0 = idx1 === 0 ? 0 : idx1 - 1;
    let p0 = getLngLat(coords[idx0]);
    let p1 = getLngLat(coords[idx1]);
    this.angle = calcAngle(p0, p1);
    this.position = p1;
  }

  createLeafletElement(props) {
    this.updatePolyline(props);
    this.updateData(props);
    this.updatePosition(props);

    const state = this.getPointerState(props.data);
    const pointer = this.createPointerHtml(state, this.angle);
    this.pointerImg = pointer.domImg;
    //Let's create a leaflet div-icon. See leafletjs.com docs (new L.DivIcon)
    var arrowIcon = new LeafletDivIcon({
      className: '',
      html: pointer.domElement,
      popupAnchor: [0, -22],
    });

    let options = Object.assign({}, this.getOptions(props), {
      icon: arrowIcon,
    });
    //Let's create a leaflet marker. See leafletjs.com docs (new L.Marker)
    const el = new LeafletMarker(this.position, options);
    //Let's create a leaflet popup and append it to the marker. See leafletjs.com docs (new L.Popup)

    this.popup = new LeafletPopup({
      autoClose: false,
      closeOnClick: false,
      autoPan: false,
    });

    this.setPopup(this.popup, props.text);

    this.hasPopup = props.hasPopup !== false ? true : false;
    if (this.hasPopup) {
      el.bindPopup(this.popup);
    }

    if (this.polyline) {
      this.geoJSON = L.geoJSON(this.polyline.data, this.polyline.style);
      el.on('add', this.addGeoJSON.bind(this));
      el.on('remove', this.removeGeoJSON.bind(this));
    }

    el.on('add', () => this.openPopup());
    el.on('remove', () => this.closePopup());

    //Uncomment the netx line if you want ObjectMarker component to accept child components
    //this.contextValue = { ...props.leaflet, popupContainer: el }
    return el;
  }

  createPointerImage() {
    preloadImage(urlGrey);
    preloadImage(urlGreen);
    preloadImage(urlGreenCircle);
    preloadImage(urlGreyCircle);
    const image = L.DomUtil.create('img');
    image.width = 30;
    image.height = 30;
    return image;
  }

  updatePointerImage(imgDom, state, angle) {
    if (window.isNaN(angle)) {
      const src = state === 'rest' ? urlGreyCircle : urlGreenCircle;
      imgDom.src = src;
    } else {
      const src = state === 'rest' ? urlGrey : urlGreen;
      const style = this.generateRotateStyle(angle);
      imgDom.style.cssText = style;
      imgDom.src = src;
    }
  }

  createPointerHtml(state, angle) {
    const img = this.createPointerImage();
    const html = L.DomUtil.create('div');
    html.classList.add('pointer-icon-container');
    html.appendChild(img);
    this.updatePointerImage(img, state, angle);
    return { domElement: html, domImg: img };
  }

  getPointerState(props) {
    const rest = !props.ignition && !props.speed;
    return rest ? 'rest' : 'busy';
  }

  addGeoJSON(ev) {
    let map = ev.target.options.leaflet.map;
    this.geoJSON.addTo(map);
  }

  removeGeoJSON() {
    this.geoJSON.remove();
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.coordinates !== fromProps.coordinates) {
      this.updatePolyline(toProps);
      this.geoJSON.clearLayers();
      this.geoJSON.addData(this.polyline.data);
    }

    if (toProps.coordinates !== fromProps.coordinates) {
      this.updatePosition(toProps);
      this.leafletElement.setLatLng(this.position);
    }

    if (toProps.showIndex !== fromProps.showIndex) {
      this.updatePosition(toProps);
      this.updateData(toProps);
      this.leafletElement.setLatLng(this.position);
      const state = this.getPointerState(toProps.data);
      this.updatePointerImage(this.pointerImg, state, this.angle);
    }

    if (toProps.data !== fromProps.data) {
      this.updateData(toProps);
      const state = this.getPointerState(toProps.data);
      this.updatePointerImage(this.pointerImg, state, this.angle);
    }

    if (toProps.text !== fromProps.text) {
      this.setPopup(this.popup, toProps.text);
    }

    if (toProps.icon !== fromProps.icon) {
      this.leafletElement.setIcon(toProps.icon);
    }
    if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
      this.leafletElement.setZIndexOffset(toProps.zIndexOffset);
    }
    if (toProps.opacity !== fromProps.opacity) {
      this.leafletElement.setOpacity(toProps.opacity);
    }
    if (toProps.draggable !== fromProps.draggable) {
      if (toProps.draggable === true) {
        this.leafletElement.dragging.enable();
      } else {
        this.leafletElement.dragging.disable();
      }
    }
    if (toProps.hasPopup !== fromProps.hasPopup) {
      const hasPopup = toProps.hasPopup !== false;
      if (hasPopup === true) {
        this.leafletElement.bindPopup(this.popup);
      } else {
        this.leafletElement.unbindPopup(this.popup);
      }
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.openPopup();
  }

  setPopup(popup, text) {
    popup.setContent(text);
    return popup;
  }

  openPopup() {
    this.leafletElement.openPopup();
  }

  closePopup() {
    this.leafletElement.closePopup();
  }

  generateRotateStyle(angle) {
    return (
      '-webkit-transform: rotate(' +
      angle +
      'rad); ' +
      '-moz-transform: rotate(' +
      angle +
      'rad); ' +
      '-o-transform: rotate(' +
      angle +
      'rad); ' +
      '-ms-transform: rotate(' +
      angle +
      'rad); ' +
      'transform: rotate(' +
      angle +
      'rad); '
    );
  }

  render() {
    const { children } = this.props;
    return children == null || this.contextValue == null ? null : (
      <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
    );
  }
}

ObjectMarker.propTypes = {
  data: PropTypes.object.isRequired,
  coordinates: PropTypes.array.isRequired,
  polyline: PropTypes.object,
  open: PropTypes.bool,
  text: PropTypes.string,
};

export default withLeaflet(ObjectMarker);
