const statusOk = 'ok';
const statusLost = 'lost';

const initState = {
  status: statusOk,
};

export const connectionReducer = (state = initState, action) => {
  let type = action.type;
  switch (type) {
    case 'connection.lost':
      //state.lossCount += 1;
      state.status = statusLost;
      break;
    case 'connection.ok':
      state.status = statusOk;
      break;
    default:
      return state;
  }
  return state;
};
