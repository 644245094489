import React from 'react';
//import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { EngineOnIcon, EngineOffIcon, DotIcon } from './../CustomIcons.js';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import configuration from '../../configuration';

const dateLocale = configuration().locale.date;

const useStyles = makeStyles(() => ({
  rootSelected: {
    borderRightColor: '#63a6c3',
    borderRightWidth: 7,
    borderRightStyle: 'solid',
  },
  primary: {
    fontSize: '0.95em',
  },
  secondary: {
    fontSize: '0.9em',
  },
}));

const stopClickPropagation = ev => ev.stopPropagation();

const ObjectListItem = ({
  index,
  data,
  onClickMenu,
  onClickItem,
  onClickCheckbox,
  selected,
  checked,
  ...other
}) => {
  const classes = useStyles();
  const twoHours = 2 * 3600 * 1000;
  const satelitesInfo = data.satellites ? ' (' + data.satellites + ')' : '';
  const updated = new Date(data.updated);
  const updatedInfo = updated.toLocaleString(dateLocale) + satelitesInfo;
  const speedText = data.speed != null ? '(' + data.speed + 'км/ч)' : '';
  const name = data.name + ' ' + speedText;
  const color = data.speed ? 'green' : '#8080803b';
  const outdated = updated < new Date() - twoHours;
  const listItemClass = selected ? classes.rootSelected : '';
  return (
    <ListItem
      {...other}
      className={listItemClass}
      button
      onClick={onClickItem}
      selected={selected}
      data-item-key={index}
    >
      <ListItemIcon>
        <Checkbox
          inputProps={{ 'data-item-key': index }}
          edge='start'
          checked={checked}
          tabIndex={-1}
          onChange={onClickCheckbox}
          onClick={stopClickPropagation}
        />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.primary, secondary: classes.secondary }}
        primary={name}
        secondary={updatedInfo}
      />
      <ListItemSecondaryAction>
        {data.switchOn === true && <EngineOnIcon />}
        {data.switchOn === false && <EngineOffIcon />}
        {!outdated && <DotIcon style={{ color: color, fontSize: '25px' }} />}
        <IconButton
          style={{ verticalAlign: 'unset', padding: 0 }}
          data-item-key={index}
          onClick={onClickMenu}
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ObjectListItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    updated: PropTypes.number,
    speed: PropTypes.number,
    satellites: PropTypes.number,
    switchOn: PropTypes.bool,
  }),
  index: PropTypes.number.isRequired,
  checked: PropTypes.bool,
  selected: PropTypes.bool,
  onClickMenu: PropTypes.func,
  onClickItem: PropTypes.func,
  onClickCheckbox: PropTypes.func,
};

export default React.memo(ObjectListItem);
