const initState = {
  list: [],
  ready: false,
  object: {},
};

export const alarmReducer = (state = initState, action) => {
  let type = action.type;
  switch (type) {
    case 'alarm.init':
      state.ready = true;
      state.list = action.data.list;
      state.object = action.data.object;
      break;
    case 'alarm.update':
      //state.list.push(action.data);
      //state.list = action.data.slice();
      state.list = action.data;
      break;
    case 'alarm.deinit':
      state.ready = false;
      state.list = [];
      state.object = {};
      break;
    default:
      return state;
  }
  return state;
};
