import React from 'react';
import PropTypes from 'prop-types';
import NotificationList from './Notification/NotificationList';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& ul': {
      width: '100%',
    },
  },
  button: {
    width: '100%',
    minHeight: '50px',
    overflow: 'hidden',
    clear: 'both',
    '& button': {
      margin: theme.spacing(1),
      float: 'right',
    },
  },
  list: {
    flexGrow: 1,
    'overflow-y': 'scroll',
  },
}));

const UserViewNotifications = ({ list, onDelete, onMarkAsRead }) => {
  const classes = useStyles();

  const handleOnMarkAsRead = React.useCallback(() => {
    const newItems = list.filter(item => item.status === 'unseen');
    const ids = newItems.map(item => item.id);
    onMarkAsRead(ids);
  }, [list, onMarkAsRead]);
  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <NotificationList list={list} onClose={onDelete} />
      </div>
      {list.length > 0 && (
        <div className={classes.button}>
          <Button variant='outlined' onClick={handleOnMarkAsRead}>
            Прочетох всички
          </Button>
        </div>
      )}
    </div>
  );
};

UserViewNotifications.propTypes = {
  list: PropTypes.array,
  onDelete: PropTypes.func,
  onMarkAsRead: PropTypes.func,
};

export default UserViewNotifications;
