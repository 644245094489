import React from 'react';
import { useLeaflet } from 'react-leaflet';
import { GeoJSON } from 'react-leaflet';

const Track = ({geojson, coordinates, style, onClick}) => {
    const { map } = useLeaflet();
    const geoJsonRef = React.useRef();
    const markersPadding = 25;
  
    React.useEffect(() => {
      const layer = geoJsonRef.current;
      if (layer) {
        map.fitBounds(layer.leafletElement.getBounds(), {
          padding: [markersPadding, markersPadding],
        });
      }
    }, [map]);

    const onEachFeature = React.useCallback((feature, layer) => {
      if(onClick){
          layer.on({
            click: onClick
        });
      }
    }, [onClick]);

    return <GeoJSON ref={geoJsonRef} data={geojson} style={style} onEachFeature={onEachFeature}/>
  }

  export default Track;