import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { DateInput, TimeInput } from '@trendmicro/react-datepicker';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
require('moment/locale/bg');

let generatedId = 0;

const DateTimePicker = ({
  locale = 'en',
  inputRef,
  InputProps,
  label,
  onChange,
  value,
  maxDate,
  minDate,
  disabled,
  maxDateMessage,
  minDateMessage,
  onError,
}) => {
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState();
  const [time, setTime] = React.useState();
  const [error, setError] = React.useState('');
  const [id, setId] = React.useState();

  moment.locale(locale);

  React.useEffect(() => {
    setId('datetime-picker-input-' + generatedId);
    generatedId++;
  }, []);

  React.useEffect(() => {
    if (open) {
      return;
    }

    const momentTime = value ? moment(value) : moment();
    setDate(() => {
      return moment(momentTime).format('YYYY-MM-DD');
    });
    setTime(() => {
      return moment(momentTime).format('HH:mm');
    });
    if (maxDate && momentTime.isAfter(maxDate)) {
      setError(() => {
        return maxDateMessage;
      });
      onError && onError(maxDateMessage);
    } else if (minDate && momentTime.isBefore(minDate)) {
      setError(() => {
        return minDateMessage;
      });
      onError && onError(minDateMessage);
    } else {
      setError(() => '');
      onError && onError('');
    }
  }, [maxDate, maxDateMessage, minDate, minDateMessage, onError, open, value]);

  const handleClick = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleApply = () => {
    setOpen(false);
    onChange(moment(date + ' ' + time));
  };

  const handleChangeDate = date => {
    setDate(date);
  };

  const handleChangeTime = time => {
    setTime(time);
  };

  return (
    <React.Fragment>
      <FormControl error={error !== ''}>
        <TextField
          value={value ? date + ' ' + time : ''}
          label={label}
          disabled={disabled}
          inputRef={inputRef}
          aria-describedby={id + '-error-text'}
          onClick={handleClick}
          InputProps={InputProps}
        />
        <FormHelperText id={id + '-error-text'}>{error}</FormHelperText>
      </FormControl>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <div>
                <DateInput
                  value={date}
                  onChange={handleChangeDate}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
              <div className='pull-left' style={{ marginLeft: 8 }}>
                <TimeInput value={time} onChange={handleChangeTime} />
              </div>
            </div>
            <div style={{ marginTop: 8 }}>
              <DatePicker
                date={date}
                onSelect={handleChangeDate}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant='outlined'>
            Откажи
          </Button>
          <Button
            onClick={handleApply}
            variant='contained'
            color='primary'
            disabled={false}
          >
            Заяви
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

DateTimePicker.propTypes = {
  locale: PropTypes.oneOf(['en', 'bg']),
  inputRef: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  disabled: PropTypes.bool,
  maxDateMessage: PropTypes.string,
  minDateMessage: PropTypes.string,
  onError: PropTypes.func,
};

export default DateTimePicker;
