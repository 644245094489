import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  name: {
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  property: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  propsContainer: {
    paddingBottom: theme.spacing(1),
  },
}));

const SkeletonTraceViewInfo = ({ orientation = 'horizontal' }) => {
  const classes = useStyles();
  const gridXs = orientation === 'vertical' ? 12 : 6;

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.name}>
          <Skeleton variant='text' />
        </Grid>
      </Grid>
      <Divider variant='middle' />
      <Grid container className={classes.propsContainer}>
        <Grid item xs={gridXs} className={classes.property}>
          <Skeleton variant='text' width='80%' />
          <Skeleton variant='text' width='60%' />
        </Grid>
        <Grid item xs={gridXs} className={classes.property}>
          <Skeleton variant='text' width='80%' />
          <Skeleton variant='text' width='60%' />
        </Grid>
        <Hidden xsDown>
          <Grid item sm={gridXs} className={classes.property}>
            <Skeleton variant='text' width='80%' />
            <Skeleton variant='text' width='60%' />
          </Grid>
          <Grid item sm={gridXs} className={classes.property}>
            <Skeleton variant='text' width='80%' />
            <Skeleton variant='text' width='60%' />
          </Grid>
        </Hidden>
      </Grid>
    </React.Fragment>
  );
};

SkeletonTraceViewInfo.propTypes = {
  orientation: PropTypes.string.isRequired,
};

export default SkeletonTraceViewInfo;
