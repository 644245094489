import React from 'react';
import PropTypes from 'prop-types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
// import TableRow from '@material-ui/core/TableRow';
// import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  button: {
    margin: 'auto',
    display: 'block',
  },
});

const AlarmFooter = ({ onAddItem }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <IconButton className={classes.button} onClick={onAddItem}>
        <AddCircleIcon />
      </IconButton>
    </div>
  );
};

AlarmFooter.propTypes = {
  onAddItem: PropTypes.func,
  colspan: PropTypes.number,
};

export default AlarmFooter;
