import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Select from '@material-ui/core/Select';
//import { DateTimePicker } from '@material-ui/pickers';
import DateTimePicker from '../DateTimePicker';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Switch from '@material-ui/core/Switch';
import { useTranslator } from '../../Translator';
import AlarmDescription from './AlarmDescription.js';
import { alarmTypes } from './AlarmItem';
import { alarmStatus } from './AlarmItem';

const AlarmTime = ({
  classes,
  editMode,
  data,
  onChange,
  onDelete,
  onError,
}) => {
  const translator = useTranslator();
  const [open, setOpen] = React.useState(false);
  const inputValue = React.useRef('');
  const errorMessage =
    'Моля попълнете параметър "Дата", преди да активирате алармата';
  const handleError = React.useCallback(
    msg => {
      onError && onError(msg);
    },
    [onError],
  );

  const handleTypeChange = React.useCallback(
    ev => {
      const type = ev.currentTarget.getAttribute('data-alarm-type');
      onChange(data, Object.assign({}, data, { type: type, value: null }));
    },
    [data, onChange],
  );

  const handleNameChange = React.useCallback(
    ev => {
      onChange(data, Object.assign({}, data, { name: ev.target.value }));
    },
    [data, onChange],
  );

  const handleDateChange = React.useCallback(
    time => {
      onChange(data, Object.assign({}, data, { value: time.unix() * 1000 }));
    },
    [data, onChange],
  );

  const handleTextChange = React.useCallback(
    ev => {
      onChange(data, Object.assign({}, data, { text: ev.target.value }));
    },
    [data, onChange],
  );

  const handleDelete = React.useCallback(() => {
    onDelete(data.id);
  }, [data.id, onDelete]);

  const handleStateChange = React.useCallback(
    ev => {
      if (ev.target.checked && !inputValue.current.value.length) {
        handleError(errorMessage);
        return;
      }

      const status = ev.target.checked
        ? alarmStatus.waiting
        : alarmStatus.finished;

      onChange(data, Object.assign({}, data, { status: status }));
    },
    [data, handleError, onChange],
  );

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Input
            classes={{ disabled: classes.inputDisabled }}
            defaultValue={data.name}
            disabled={editMode !== true}
            label={'Име на аларма'}
            variant={'filled'}
            name={'alarm-name'}
            disableUnderline={editMode !== true}
            inputProps={{ minLength: 2 }}
            onChange={handleNameChange}
          />
        </TableCell>
        <TableCell>
          <Select
            classes={{ disabled: classes.selectDisabled }}
            label='Вид'
            disabled={editMode !== true}
            disableUnderline={editMode !== true}
            value={alarmTypes[0].value}
            onChange={handleTypeChange}
            name={'alarm-type'}
          >
            {alarmTypes.map(item => (
              <MenuItem
                key={item.id}
                data-alarm-type={item.id}
                value={item.value}
              >
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell>
          <DateTimePicker
            locale={translator.locale}
            label={'Дата'}
            value={data.value}
            minDate={editMode === true ? new Date() : null}
            minDateMessage='Изберете по-късна дата!'
            onChange={handleDateChange}
            disabled={editMode !== true}
            name={'alarm-trigger-value'}
            inputRef={inputValue}
            InputProps={{
              disableUnderline: editMode !== true,
              classes: { disabled: classes.inputDisabled },
            }}
          />
        </TableCell>
        <TableCell>
          <Switch
            classes={{ switchBase: classes.switchDisabled }}
            checked={data.status === 'waiting' || data.status === 'soon'}
            onChange={handleStateChange}
            name='alarm-state'
            disabled={editMode !== true}
          />
        </TableCell>
        {editMode && (
          <TableCell>
            <IconButton aria-label='delete' onClick={handleDelete}>
              <DeleteForeverIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={editMode ? 6 : 5}
          className={classes.descriptionCell}
          style={{ padding: 0 }}
        >
          <Collapse in={open} timeout='auto' unmountOnExit>
            <AlarmDescription
              disabled={editMode !== true}
              placeholder={'Въведете описание на алармата'}
              onChange={handleTextChange}
              defaultValue={data.text}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

AlarmTime.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
};

export default AlarmTime;
