import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
//import { getTabIdsMap } from '../reducers/viewStack';
import NotificationLabeled from './NotificationLabeled';

const ViewStackTabs = ({ items, selected, onChange }) => {
  const countNew = useSelector(state => state.notification.countNew);
  const tabs = React.useMemo(
    () =>
      items.map(item => {
        return (
          <Tab
            key={item.id}
            data-view-index={item.id}
            label={
              item.id === 'user' ? (
                <NotificationLabeled
                  label={item.label}
                  notificationsCount={countNew}
                />
              ) : (
                item.label
              )
            }
            id={item.tabId}
            aria-controls={item.ariaControls}
            // disabled={item.disabled}
          />
        );
      }),
    [countNew, items],
  );

  return (
    <Tabs
      value={selected}
      onChange={onChange}
      aria-label={'View selection tabs'}
      variant='scrollable'
      scrollButtons='auto'
    >
      {tabs}
    </Tabs>
  );
};

ViewStackTabs.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.number,
  onChange: PropTypes.func,
};

export default ViewStackTabs;
